/*
 * File: src/constants/strings.ts
 * Notes:
 *   > ...
 */

export const GENERIC_ERROR = "An error occured processing your request.";
export const CUSTOMER_SERVICE_NUMBER = "(866) 883-4113";
export const GENERIC_DONATE_FUNDS_ERROR =
  "An error occured processing your donation request.";
export const GENERIC_UPDATE_ADDRESS_ERROR =
  "An error occured updating your address information.";
export const GENERIC_UPDATE_PERSONAL_ERROR =
  "An error occured updating your personal information.";
export const GENERIC_CHANGE_PASSWORD_ERROR =
  "An error occured changing your password.";
export const GENERIC_UPDATE_CONTACT_ERROR =
  "An error occured updating your contact information.";
export const GENERIC_CHANGE_PIN_ERROR = "An error occured changing your pin.";
export const GENERIC_REQUEST_PIN_CHANGE_ERROR =
  "An error occured processing your pin change request.";
