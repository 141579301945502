/*
 * File: src/components/signup/groups/FormGroupCreatePIN.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useField } from "formik";
import PinInput from "../../inputs/PinInput";
import { createFormGroup, FormGroupProps } from "../group/FormGroup";
import { PinSchema } from "../../../validation/schema";
import FormGroupContent from "../group/FormGroupContent";
import TermsOfServiceAgreement from "./TermsOfServiceAgreement";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
}));

const FormGroupCreatePIN: VoidFunctionComponent<FormGroupProps> = ({
  actions,
  isFirstStep,
  isLastStep,
}) => {
  const [field] = useField<string>("pin");
  const classes = useStyles();

  return (
    <FormGroupContent
      actions={actions}
      isFirstStep={isFirstStep}
      isLastStep={isLastStep}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography gutterBottom className={classes.raleway}>
            Please choose a 4-digit Kiosk PIN for your account. This PIN will be
            used to login at a CLYNK kiosk.
          </Typography>
        </Grid>

        <Grid item>
          <Grid container direction="row" justifyContent="flex-start">
            <PinInput {...field} autoFocus />
          </Grid>
        </Grid>
      </Grid>

      {isLastStep && <TermsOfServiceAgreement />}
    </FormGroupContent>
  );
};

export default createFormGroup(FormGroupCreatePIN, {
  pin: PinSchema,
});
