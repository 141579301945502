import { IconButton, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import { makeStyles } from "@material-ui/styles";
import { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.main,
    fontSize: "2.375rem",
    margin: theme.spacing(4, 0, 4, 0),
  },
}));

interface BackArrowButtonProps {
  to?: string;
  useHref?: boolean;
}

function BackArrowButton(props: BackArrowButtonProps): ReactElement {
  const classes = useStyles();
  const location = window.location.origin;

  const LinkComponent = props.useHref ? "a" : Link;

  return (
    <IconButton
      component={LinkComponent}
      to={!props.useHref ? props.to || "/login" : undefined} // Only set 'to' when using Link
      href={props.useHref ? location : undefined} // Set 'href' if 'useHref' is true
      className={classes.arrow}
    >
      <ArrowBackRoundedIcon style={{ fontSize: 40 }} />
    </IconButton>
  );
}

export default BackArrowButton;
