/*
 * File: src/routers/dashboard/Router.tsx
 * Notes:
 *   > ...
 */

import React, {
  lazy,
  useState,
  Suspense,
  useEffect,
  useMemo,
  useCallback,
  useReducer,
  Dispatch,
  MouseEventHandler,
  VoidFunctionComponent,
} from "react";
import HomeIcon from "@material-ui/icons/Home";
import CardIcon from "@material-ui/icons/CreditCard";
import DonateIcon from "@material-ui/icons/CardGiftcard";
import EditProfileIcon from "@material-ui/icons/Settings";
import LogoutIcon from "@material-ui/icons/ExitToApp";
// import { FeedbackRounded } from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";
import { Theme, LinearProgress, CssBaseline } from "@material-ui/core";
import MobileNav from "../../components/dashboard/nav/MobileNav";
import Frame from "./Frame";
import Splash from "./Splash";
import { dashboardReducer } from "./state/DashboardReducer";
import {
  DashboardState,
  CLOSE_C2G_DRAWER,
  CLOSE_CANCEL_BAG_DONATION,
  CLOSE_JOIN_BAG_DONATION_EVENT,
  CLOSE_DONATE,
  DashboardAction,
} from "./state/DashboardState";
import ClynkToGiveDrawer from "../../components/dashboard/drawers/ClynkToGiveDrawer";
import { DashboardContext } from "./state/DashboardContext";
import ThemeProvider from "../../theme/ThemeProviderPreferences";
import SnackbarProvider from "../../components/snackbar/SnackbarProvider";
import light from "../../theme/dashboard/light";
import {
  CancelBagDonation,
  JoinBagDonationEvent,
} from "../../components/dashboard/dialogs";
import DonateFunds from "../../components/dashboard/dialogs/DonateFunds/DonateFunds";
import {
  Sidebar,
  SidebarProfile,
  SidebarNodeIcon,
  SidebarNode,
  SidebarNodeLabel,
} from "../../components/dashboard/sidebar";
import { Preferences } from "../../preferences/Preferences";
import PreferencesProvider from "../../preferences/PreferencesProvider";
import { SidebarNodeIconProps } from "../../components/dashboard/sidebar/SidebarNodeIcon";
import { useFeatureFlags } from "../../feature-flags";
import AppFeatureFlags from "../../AppFeatureFlags";
import { SessionManager } from "../../gql/session";
import Banner from "../../components/banner/Banner";

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    marginLeft: 280,
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 16,
    },
  },
}));

const FrameOverview = lazy(() => import("./routes/Overview"));
const FrameKiosk = lazy(() => import("./routes/CardAndPin"));
const FrameDonate = lazy(() => import("./routes/Donate"));
const FrameEditProfile = lazy(() => import("./routes/EditProfile"));
// const FrameFeedback = lazy(() => import("./routes/Feedback"));
const Login = lazy(() => import("./routes/MobileLogin"));
const Welcome = lazy(() => import("./routes/WelcomePage"));
const Register = lazy(() => import("./routes/RegisterPage"));
const RegisterForm = lazy(() => import("./routes/RegisterForm"));
const RegisterVerify = lazy(() => import("./routes/RegisterVerify"));
const RegisterPassword = lazy(() => import("./routes/RegisterPassword"));
const RegisterFinish = lazy(() => import("./routes/RegisterFinish"));
const ScanIssues = lazy(() => import("./routes/ScanIssues"));
const ScanIssuesFromSplash = lazy(
  () => import("./routes/ScanIssuesFromSplash")
);

const Fallback: VoidFunctionComponent = () => {
  const [state, setState] = useState({
    showSpinner: false,
  });

  useEffect(() => {
    const id = setTimeout(() => {
      setState((s) => ({ ...s, showSpinner: true }));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  if (!state.showSpinner) {
    return <></>;
  }

  return (
    <Frame>
      <div
        style={{
          width: "60%",
          maxWidth: 400,
          position: "absolute",
          left: "calc(50% + 140px)",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LinearProgress color="primary" />
      </div>
    </Frame>
  );
};

const bannerProps = {
  isBannerActive:
    process.env.REACT_APP_PRIVACY_POLICY_ON?.toLowerCase() === "true",
};

const initialState: DashboardState = {
  c2gDrawerTarget: null,
  cancelBagDonationId: null,
  joinBagDonationEventId: null,
  donate: {
    openId: 0,
    clynkToGiveMemberId: null,
  },
};

const themes = {
  light,
};

const initialPreferences: Preferences = {};

const ClynkToGiveDrawerMemo = React.memo(ClynkToGiveDrawer);
const CancelBagDonationMemo = React.memo(CancelBagDonation);
const JoinBagDonationEventMemo = React.memo(JoinBagDonationEvent);
const ConfirmDonationMemo = React.memo(DonateFunds);

interface RoutedSidebarNodeProps {
  Icon: SidebarNodeIconProps["Icon"];
  to: string;
  label: string;
  selected?: boolean;
  match: RegExp;
  show?: boolean;
  onClick: MouseEventHandler;
}

const RoutedSidebarNode: VoidFunctionComponent<RoutedSidebarNodeProps> = (
  props
) => {
  const { to, label, Icon, match, selected, ...rest } = props;

  return (
    <SidebarNode to={to} selected={selected} component={Link} {...rest}>
      <SidebarNodeIcon selected={selected} Icon={Icon} />
      <SidebarNodeLabel selected={selected}>{label}</SidebarNodeLabel>
    </SidebarNode>
  );
};

const RoutedSidebarNodes = [
  {
    to: "/dashboard",
    match: /^\/dashboard\/?$/,
    label: "Account Summary",
    Icon: HomeIcon,
  },
  {
    to: "/dashboard/card-and-pin",
    match: /^\/dashboard\/card-and-pin?/,
    label: "Card & PIN",
    Icon: CardIcon,
  },
  {
    to: "/dashboard/edit-profile",
    match: /^\/dashboard\/edit-profile?/,
    label: "Edit Profile / Change Password",
    Icon: EditProfileIcon,
  },
  //******COMMENT THIS (DONATIONS) OUT ONLY FOR MOBILE
  /*{
    to: "/dashboard/donations",
    match: /^\/dashboard\/donations?/,
    label: "Donations",
    Icon: DonateIcon,
  },*/
  // LEAVE DISABLED UNTIL FURTHER NOTICE
  // {
  //   to: "/dashboard/feedback",
  //   match: /^\/dashboard\/feedback?/,
  //   label: "We need your help!",
  //   Icon: FeedbackRounded,
  // },
];

interface DashboardProps {
  state: DashboardState;
  dispatch: Dispatch<DashboardAction>;
}

const Dashboard: VoidFunctionComponent<DashboardProps> = (props) => {
  const { state, dispatch } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();

  const selected = useMemo(() => {
    return RoutedSidebarNodes.findIndex((n) => {
      return pathname.match(n.match) != null;
    });
  }, [pathname]);

  const [isPrefetchComplete, setPrefetchComplete] = useState(false);

  const onPrefetchComplete = useCallback(() => {
    setPrefetchComplete(true);
  }, [setPrefetchComplete]);

  const handleC2GDrawerClose = useCallback(() => {
    dispatch({ type: CLOSE_C2G_DRAWER });
  }, [dispatch]);

  const handleCancelBagDonationClose = useCallback(() => {
    dispatch({ type: CLOSE_CANCEL_BAG_DONATION });
  }, [dispatch]);

  const handleJoinBagDonationEventClose = useCallback(() => {
    dispatch({ type: CLOSE_JOIN_BAG_DONATION_EVENT });
  }, [dispatch]);

  const handleDonateClose = useCallback(() => {
    dispatch({ type: CLOSE_DONATE });
  }, [dispatch]);

  const openSidebar = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleSidebarClick = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const { has: isFeatureFlagEnabled } = useFeatureFlags<AppFeatureFlags>();

  const logout = () => {
    SessionManager.instance.logout();

    const loginInfo = JSON.parse(localStorage.getItem("loginInfo") || "{}");
    if (loginInfo.origin === "/dashboard/login") {
      localStorage.removeItem("loginInfo");
      localStorage.removeItem("CARD_NUMBER");
      localStorage.removeItem("INSTANT_BALANCE_TOKEN");

      window.location.replace("/dashboard/login");
    } else {
      localStorage.removeItem("loginInfo");

      window.location.replace("/login");
    }
  };

  if (!isPrefetchComplete) {
    return <Splash onPrefetchComplete={onPrefetchComplete} />;
  }

  const donateNode = RoutedSidebarNode({
    to: "/dashboard/donations",
    match: /^\/dashboard\/donations?/,
    label: "Donations",
    Icon: DonateIcon,
    onClick: handleSidebarClick,
  });

  const showDonate = isFeatureFlagEnabled("donateFunds");

  return (
    <>
      <MobileNav openSidebar={openSidebar} />

      <Sidebar open={open} onClose={() => setOpen(false)} onOpen={openSidebar}>
        <SidebarProfile />

        {RoutedSidebarNodes.map((n, i) => (
          <RoutedSidebarNode
            {...n}
            key={n.label}
            selected={i === selected}
            onClick={handleSidebarClick}
          />
        ))}
        {showDonate && donateNode}

        <SidebarNode onClick={logout}>
          <SidebarNodeIcon Icon={LogoutIcon} />
          <SidebarNodeLabel>Logout</SidebarNodeLabel>
        </SidebarNode>
      </Sidebar>

      <div className={classes.grid}>
        <Suspense fallback={<Fallback />}>
          <Switch>
            <Route path="/dashboard/login">
              <Login />
            </Route>

            <Route path="/dashboard/close"></Route>

            <Route path="/dashboard/welcome">
              <Welcome />
            </Route>

            <Route path="/dashboard/scanningtrouble">
              <ScanIssuesFromSplash />
            </Route>

            <Route exact path="/dashboard">
              <FrameOverview />
            </Route>

            <Route path="/dashboard/card-and-pin">
              <FrameKiosk />
            </Route>

            <Route path="/dashboard/donations">
              <FrameDonate />
            </Route>

            <Route path="/dashboard/edit-profile">
              <FrameEditProfile />
            </Route>

            <Route path="/dashboard/scan-issues">
              <ScanIssues />
            </Route>

            {/* LEAVE DISABLED UNTIL FURTHER NOTICE */}
            {/* <Route path='/dashboard/feedback'>
                <FrameFeedback />
            </Route> */}

            <Route>
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </Suspense>
      </div>

      <ClynkToGiveDrawerMemo
        onClose={handleC2GDrawerClose}
        accountId={state.c2gDrawerTarget}
      />

      <CancelBagDonationMemo
        onClose={handleCancelBagDonationClose}
        bagDonationId={state.cancelBagDonationId}
      />

      <JoinBagDonationEventMemo
        onClose={handleJoinBagDonationEventClose}
        bagDonationEventId={state.joinBagDonationEventId}
      />

      <ConfirmDonationMemo
        onClose={handleDonateClose}
        close={handleDonateClose}
        {...state.donate}
      />
    </>
  );
};

const Router: VoidFunctionComponent = () => {
  const context = useReducer(dashboardReducer, initialState);

  const [state, dispatch] = context;

  return (
    <PreferencesProvider initial={initialPreferences}>
      <ThemeProvider themes={themes}>
        <CssBaseline />

        <SnackbarProvider>
          <Suspense fallback={<Fallback />}>
            <Switch>
              <Route path="/dashboard/login">
                <Login />
              </Route>

              <Route path="/dashboard/welcome">
                <Welcome />
              </Route>

              <Route path="/dashboard/register">
                <Register />
              </Route>

              <Route path="/dashboard/registerform">
                <RegisterForm />
              </Route>

              <Route path="/dashboard/registerverify">
                <RegisterVerify />
              </Route>

              <Route path="/dashboard/registerpassword">
                <RegisterPassword />
              </Route>

              <Route path="/dashboard/registerfinish">
                <RegisterFinish />
              </Route>

              <Route path="/dashboard/scanningtrouble">
                <Banner {...bannerProps} />
                <ScanIssuesFromSplash />
              </Route>

              <Route path="/dashboard/close">
                <Banner {...bannerProps} />
              </Route>

              <Route
                path="/"
                render={() => {
                  const { active } = SessionManager.instance;

                  return active === null ? (
                    <Redirect to="/login" />
                  ) : (
                    <DashboardContext.Provider value={context}>
                      <Banner {...bannerProps} />
                      <Dashboard state={state} dispatch={dispatch} />
                    </DashboardContext.Provider>
                  );
                }}
              />
            </Switch>
          </Suspense>
        </SnackbarProvider>
      </ThemeProvider>
    </PreferencesProvider>
  );
};

export default Router;
