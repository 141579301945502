import React, { VoidFunctionComponent, useEffect } from "react";
import Dialog from "./Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { useFormik, FormikContext, FormikHelpers, FormikState } from "formik";
import ProgressButton from "../button/ProgressButton";
import { DialogContent, Collapse, Typography, Button } from "@material-ui/core";
import { gql, useApolloClient } from "@apollo/client";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
  CUSTOMER_SERVICE_NUMBER,
  GENERIC_ERROR,
} from "../../constants/strings";
import FormikField from "../inputs/FormikField";
import FormikTextField from "../inputs/FormikTextField";
import { EmailRegex, PinSchema } from "../../validation/schema";
import getApolloErrorMessage from "../../gql/getApolloErrorMessage";

const ForgotUsernameMutation = gql`
  mutation ($email: String!, $pin: String!) {
    forgotUsername(email: $email, pin: $pin) {
      success
      message
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

const EnterEmail: VoidFunctionComponent = () => {
  return (
    <>
      <Typography style={{ marginBottom: 16 }}>
        Please enter the email associated with your account.
      </Typography>

      <FormikField name="email" label="Email" component={FormikTextField} />

      <input
        type="submit"
        style={{ position: "absolute", left: -99999, width: 1, height: 1 }}
        tabIndex={-1}
      />
    </>
  );
};

const EnterPin: VoidFunctionComponent = () => {
  return (
    <>
      <Typography style={{ marginBottom: 16 }}>
        Please enter the PIN associated with your account.
      </Typography>

      <FormikField
        name="pin"
        label="PIN"
        type="password"
        component={FormikTextField}
      />

      <input
        type="submit"
        style={{ position: "absolute", left: -99999, width: 1, height: 1 }}
        tabIndex={-1}
      />
    </>
  );
};

const ConfirmationEmail: VoidFunctionComponent = () => {
  return (
    <div>
      <Typography style={{ marginBottom: 16 }}>
        If an account exists with the email address you provided, an email with
        your username will be sent shortly. Once you receive this email, you may
        use your username to login. If you do not see this email, check your
        spam folder to see if it may have gone there.
      </Typography>

      <Typography variant="body2">
        * Still can't find it? Contact customer service at{" "}
        {CUSTOMER_SERVICE_NUMBER}.
      </Typography>
    </div>
  );
};

const validationSchema = Yup.object({
  step: Yup.number().default(0),
  email: Yup.string()
    .matches(EmailRegex, "Please enter a valid email address")
    .max(320)
    .default("")
    .required("Please enter the email associated with your account."),
  pin: PinSchema,
});

type Values = Yup.Asserts<typeof validationSchema>;

export interface ForgotUsernameProps {
  open: boolean;
  onClose: () => void;
}

const ForgotUsername: VoidFunctionComponent<ForgotUsernameProps> = ({
  open,
  onClose,
}) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    const { step, email, pin } = values;
    if (step === 1) {
      try {
        const { data } = await client.mutate({
          mutation: ForgotUsernameMutation,
          variables: { email, pin },
        });

        if (data) {
          const { success, message } = data.forgotUsername;

          if (success) {
            actions.setFormikState(
              (s) =>
                ({
                  ...s,
                  touched: {
                    ...s.touched,
                    email: undefined,
                  },
                  values: {
                    ...s.values,
                    step: s.values.step + 1,
                  },
                } as FormikState<Values>)
            );
          } else {
            if (message) {
              enqueueSnackbar(message, { variant: "error" });
            }
          }
        } else {
          enqueueSnackbar(GENERIC_ERROR, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(getApolloErrorMessage(error), {
          variant: "error",
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: validationSchema.getDefault(),
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleNext = () => {
    if (formik.values.step === 0) {
      formik.setFormikState((f) => ({
        ...f,
        values: { ...f.values, step: Math.max(0, f.values.step + 1) },
      }));
    }
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  const handleBack = () => {
    formik.setFormikState((f) => ({
      ...f,
      values: { ...f.values, step: Math.max(0, f.values.step - 1) },
    }));
  };

  const { resetForm } = formik;

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [resetForm, open]);

  return (
    <Dialog
      showCloseButton
      open={open}
      fullScreen={false}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Forgot Username</DialogTitle>
      <div style={{ flexGrow: 1 }} />
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <FormikContext.Provider value={formik}>
          <Collapse in={formik.values.step === 0} unmountOnExit>
            <EnterEmail />
          </Collapse>
          <Collapse in={formik.values.step === 1} unmountOnExit>
            <EnterPin />
          </Collapse>
          <Collapse in={formik.values.step === 2} unmountOnExit>
            <ConfirmationEmail />
          </Collapse>
        </FormikContext.Provider>
      </DialogContent>

      <DialogActions>
        {formik.values.step === 0 && (
          <ProgressButton
            onClick={handleNext}
            color="primary"
            variant="contained"
            LinearProgressProps={{ color: "secondary" }}
          >
            Continue
          </ProgressButton>
        )}

        {formik.values.step === 1 && <Button onClick={handleBack}>Back</Button>}
        {formik.values.step === 1 && (
          <ProgressButton
            onClick={handleNext}
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            LinearProgressProps={{ color: "secondary" }}
          >
            Continue
          </ProgressButton>
        )}

        {formik.values.step === 2 && (
          <Button onClick={onClose} color="primary" variant="contained">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ForgotUsername;
