interface TokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
}

export class Session {
  public constructor(private tokenResponse: TokenResponse) {}

  public get accessToken(): string {
    return this.tokenResponse.access_token;
  }

  public get refreshToken(): string {
    return this.tokenResponse.refresh_token;
  }

  public on(
    event: "refreshed",
    callback: (event: CustomEvent<TokenResponse>) => void
  ): this {
    this.emitter.addEventListener(event, callback as (event: Event) => void);
    return this;
  }

  public off(
    event: "refreshed",
    callback: (event: CustomEvent<TokenResponse>) => void
  ): this {
    this.emitter.removeEventListener(event, callback as (event: Event) => void);
    return this;
  }

  public async refresh(): Promise<boolean> {
    let { refreshRequestPromise } = this;

    if (refreshRequestPromise === null) {
      refreshRequestPromise = this.refreshAccessToken();

      this.refreshRequestPromise = refreshRequestPromise;
    }

    return refreshRequestPromise;
  }

  private async refreshAccessToken(): Promise<boolean> {
    const response = await fetch(
      process.env.REACT_APP_CONNECT_TOKEN_URL ?? "",
      {
        method: "post",
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID ?? "",
          client_secret: process.env.REACT_APP_CLIENT_SECRET ?? "",
          grant_type: "refresh_token",
          refresh_token: this.tokenResponse.refresh_token,
        }),
      }
    );

    if (response.status === 200) {
      const data: TokenResponse = await response.json();

      this.tokenResponse = data;

      this.emitter.dispatchEvent(
        new CustomEvent<TokenResponse>("refreshed", { detail: data })
      );

      return true;
    }

    return false;
  }

  private readonly emitter = new EventTarget();

  private refreshRequestPromise: Promise<boolean> | null = null;
}

export enum LoginResult {
  SUCCESS = 0,
  INCORRECT_USERNAME_OR_PASSWORD,
  USER_NOT_ACTIVATED,
  STALE_LOGIN_ATTEMPT,
  UNKNOWN,
}

export class SessionManager {
  private constructor(active: Session | null) {
    this._active = active;
  }

  public get active(): Session | null {
    return this._active;
  }

  public on(event: "login" | "logout", callback: () => void): this {
    this.emitter.addEventListener(event, callback);
    return this;
  }

  public off(event: "login" | "logout", callback: () => void): this {
    this.emitter.removeEventListener(event, callback);
    return this;
  }

  public async login(username: string, password: string): Promise<LoginResult> {
    const sessionId = ++this.sessionId;

    this.logout();

    const response = await fetch(
      process.env.REACT_APP_CONNECT_TOKEN_URL ?? "",
      {
        method: "post",
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID ?? "",
          client_secret: process.env.REACT_APP_CLIENT_SECRET ?? "",
          grant_type: "password",
          username,
          password,
        }),
      }
    );

    const data = await response.json();

    if (sessionId !== this.sessionId) {
      return LoginResult.STALE_LOGIN_ATTEMPT;
    }

    if (response.status === 200) {
      this.setActiveSession(new Session(data));

      localStorage.setItem("session", JSON.stringify(data));

      this.emitter.dispatchEvent(new Event("login"));

      return LoginResult.SUCCESS;
    } else if (response.status === 400) {
      switch (data.error_description) {
        case "user not active":
          return LoginResult.USER_NOT_ACTIVATED;
        default:
          return LoginResult.INCORRECT_USERNAME_OR_PASSWORD;
      }
    }

    return LoginResult.UNKNOWN;
  }

  public logout(): void {
    const { _active: session } = this;

    if (session === null) {
      return;
    }

    this._active = null;

    localStorage.removeItem("session");

    this.emitter.dispatchEvent(new Event("logout"));

    // fire and forget
    fetch(process.env.REACT_APP_CONNECT_REVOCATION_URL ?? "", {
      method: "post",
      body: new URLSearchParams({
        client_id: process.env.REACT_APP_CLIENT_ID ?? "",
        client_secret: process.env.REACT_APP_CLIENT_SECRET ?? "",
        token: session.refreshToken,
        token_type_hint: "refresh_token",
      }),
    });
  }

  private setActiveSession(session: Session | null): void {
    if (this._active !== null) {
      this._active.off("refreshed", this.onSessionRefreshed);
    }

    this._active = session;

    if (session) {
      session.on("refreshed", this.onSessionRefreshed);
    }
  }

  private readonly onSessionRefreshed = (event: CustomEvent<TokenResponse>) => {
    localStorage.setItem("session", JSON.stringify(event.detail));
  };

  public static get instance(): SessionManager {
    if (this._instance === null) {
      throw new Error("SessionManager not initialized");
    }

    return this._instance;
  }

  public static initialize(): void {
    if (this._instance !== null) {
      throw new Error("SessionManager already initialized");
    }

    let session: Session | null = null;

    try {
      const data: string | null = localStorage.getItem("session");

      session = data !== null ? new Session(JSON.parse(data)) : null;
    } catch (error) {
      console.error(error);
    }

    this._instance = new SessionManager(session);
  }

  private sessionId = 0;

  private _active: Session | null;

  private readonly emitter = new EventTarget();

  private static _instance: SessionManager | null = null;
}
