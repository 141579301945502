import { flatMap, map, keys, isPlainObject, concat } from "lodash";

const paths = (obj: unknown, root?: string): string[] => {
  let result: string[];

  if (Array.isArray(obj)) {
    let index = 0;
    result = flatMap(obj, (child: unknown) => {
      return paths(child, `${root ? root + "." : ""}.${index++}`);
    });
  } else if (isPlainObject(obj)) {
    const typed = obj as Record<string, unknown>;

    result = flatMap(keys(typed), (key) => {
      return map(paths(typed[key], key), (subkey) => {
        return (root ? root + "." : "") + subkey;
      });
    });
  } else {
    result = [];
  }

  return concat(result, root || []);
};

export default paths;
