/*
 * File: src/components/dashboard/dialogs/DonateFunds/DonateFundsReceipt.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { DonateFundsStepProps } from "./DonateFundsStepProps";
import formatCurrency from "../../../../common/formatCurrency";

const DonateFundsReceipt: VoidFunctionComponent<DonateFundsStepProps> = ({
  close,
  formik,
  queryData,
}) => {
  const member = queryData?.clynkToGiveMember;

  // TODO - Check this
  const amount = formatCurrency(
    Number(formik.values.amount.replace(",", "")) * 100
  );

  const organization = member?.organization || "this organization.";

  return (
    <>
      <DialogTitle>Donation Receipt</DialogTitle>

      <DialogContent>
        Thank you for your ${amount} donation to {organization}!
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </>
  );
};

export default DonateFundsReceipt;
