/*
 * File: src/components/dashboard/sidebar/Sidebar.tsx
 * Notes:
 *   > ...
 */

import React, { ComponentType, ElementType, FunctionComponent } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ButtonBase, ButtonBaseProps, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme, { selected?: boolean }>((theme) => ({
  root: {
    display: "flex",
    cursor: "pointer",
    height: 64,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  icon: {
    transition: "fill .2s ease",
    opacity: ({ selected }) => (selected ? 1 : 0.5),
    fill: ({ selected }) =>
      selected ? theme.palette.primary.light : "currentColor",
  },
}));

// component is missing from ButtonBaseProps?
interface ButtonBasePropsFixed<C extends ElementType = ElementType>
  extends ButtonBaseProps {
  component?: C;
}

const ButtonBaseFixed = ButtonBase as ComponentType<ButtonBasePropsFixed>;

export interface SidebarNodeProps<C extends ElementType = ElementType>
  extends ButtonBasePropsFixed<C> {
  selected?: boolean;
  to?: string;
}

const SidebarNode: FunctionComponent<SidebarNodeProps> = ({
  selected,
  component,
  children,
  ...rest
}) => {
  const classes = useStyles({ selected });

  return (
    <ButtonBaseFixed component={component} className={classes.root} {...rest}>
      {children}
    </ButtonBaseFixed>
  );
};

export default SidebarNode;
