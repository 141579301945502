/*
 * File: src/theme/ThemeProviderPreferences.tsx
 * Notes:
 *   > ...
 */

import React, { PropsWithChildren } from "react";
import { ThemeProvider, ThemeProviderProps } from "@material-ui/core";

type ThemeProviderPreferencesProps<Theme> = {
  themes: {
    light: Partial<Theme>;
  };
} & Omit<ThemeProviderProps, "theme">;

export default function ThemeProviderPreferences<Theme>({
  themes,
  children,
  ...rest
}: PropsWithChildren<
  ThemeProviderPreferencesProps<Theme>
>): React.ReactElement {
  // const { preferences } = useContext(PreferencesContext)

  return (
    <ThemeProvider theme={themes.light} {...rest}>
      {children}
    </ThemeProvider>
  );
}
