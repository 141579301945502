import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
  VoidFunctionComponent,
} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import TermsOfService from "../../dialogs/TermsOfService";
import { useField } from "formik";
import { boolean } from "yup";
import FormGroup, { FormGroupProps, FormGroupSchema } from "../group/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
}));

const TermsOfServiceAgreementComponent: VoidFunctionComponent = () => {
  const classes = useStyles();
  const [field, meta, { setValue }] = useField({
    name: "terms",
    type: "checkbox",
  });

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(
    (agree: boolean) => {
      if (agree) {
        setValue(true);
      }

      setOpen(false);
    },
    [setOpen, setValue]
  );

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <div style={{ marginLeft: -11, display: "flex", alignItems: "center" }}>
          <Checkbox {...field} color="primary" />

          <Typography variant="body1" className={classes.raleway}>
            I agree to the{" "}
            <Link
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                event.stopPropagation();
                setOpen(true);
              }}
            >
              Terms of Service
            </Link>
          </Typography>
        </div>

        {meta.touched && meta.error && (
          <FormHelperText error>{meta.error}</FormHelperText>
        )}
      </div>

      <TermsOfService open={open} onClose={handleClose} />
    </>
  );
};

const TermsOfServiceAgreement = Object.assign(
  TermsOfServiceAgreementComponent,
  {
    schema: {
      terms: boolean()
        .isTrue("You must agree to our Terms of Service to continue")
        .default(false),
    },
  }
);

export function WithTermsOfServiceAgreement<
  TProps extends FormGroupProps = FormGroupProps,
  TSchema extends FormGroupSchema = FormGroupSchema
>(
  Component: FormGroup<TProps, TSchema>
): FormGroup<TProps, TSchema & typeof TermsOfServiceAgreement.schema> {
  // TODO - Switch to createFormGroup()
  return Object.assign(
    (props: PropsWithChildren<TProps>): ReactElement => (
      <>
        <Component {...props} />
        <TermsOfServiceAgreementComponent />
      </>
    ),
    { schema: { ...Component.schema, ...TermsOfServiceAgreement.schema } }
  );
}

export function WithTermsOfServiceSchema<
  TProps extends FormGroupProps = FormGroupProps,
  TSchema extends FormGroupSchema = FormGroupSchema
>(
  Component: FormGroup<TProps, TSchema>
): FormGroup<TProps, TSchema & typeof TermsOfServiceAgreement.schema> {
  // TODO - Switch to createFormGroup()
  return Object.assign(
    (props: PropsWithChildren<TProps>): ReactElement => (
      <>
        <Component {...props} />
      </>
    ),
    { schema: { ...Component.schema, ...TermsOfServiceAgreement.schema } }
  );
}

export default TermsOfServiceAgreement;
