/*
 * File: src/gql/schema/mutations/DonateFunds.ts
 * Notes:
 *   > ...
 */

import { gql, useMutation, ApolloCache, MutationTuple } from "@apollo/client";
import { IResult, Account, ClynkToGiveMember, DonationTotal } from "../types";

export const DonateFundsMutation = gql`
  mutation ($input: DonateFundsInput!) {
    donateFunds(input: $input) {
      balance
      to
      amount
      result {
        success
        message
        ... on ResultFailed {
          fields {
            field
            errors
          }
        }
      }
    }
  }
`;

export interface DonateFundsInput {
  to: string;
  amount: number;
  anonymous: boolean;
}

export interface DonateFundsVariables {
  input: DonateFundsInput;
}

export interface DonateFundsResult {
  donateFunds: {
    balance: number | null;
    amount: number;
    to: string;
    result: IResult;
  };
}

const updateBalance = (
  cache: ApolloCache<DonateFundsResult>,
  accountId: string,
  balance: number
) => {
  const AccountBalanceFragment = gql`
    fragment AccountBalanceFragment on Account {
      balance
    }
  `;

  cache.writeFragment({
    id: `Account:${accountId}`,
    fragment: AccountBalanceFragment,
    data: {
      balance,
    },
  });
};

const updateDonationTotal = (
  cache: ApolloCache<DonateFundsResult>,
  accountId: string,
  to: string,
  amount: number
) => {
  const AccountDonationTotalsFragment = gql`
    fragment AccountDonationTotalsFragment on Account {
      donationTotals {
        to {
          id
        }
        total
      }
    }
  `;

  try {
    const test = cache.readFragment({
      id: `Account:${accountId}`,
      fragment: AccountDonationTotalsFragment,
    }) as Account;

    const index = test.donationTotals.findIndex((dt) => dt.to.id === to);

    const newDonationTotals = test.donationTotals.slice();

    if (index !== -1) {
      newDonationTotals[index] = {
        ...test.donationTotals[index],
        total: test.donationTotals[index].total + amount,
      };
    } else {
      const ugh = cache.readFragment({
        fragment: gql`
          fragment ClynkToGiveMember_ on ClynkToGiveMember {
            id
            organization
          }
        `,
        id: `ClynkToGiveMember:${to}`,
      });

      newDonationTotals.push({
        __typename: "DonationTotal",
        to: ugh,
        total: amount,
      } as DonationTotal);
    }

    cache.writeFragment({
      id: `Account:${accountId}`,
      fragment: AccountDonationTotalsFragment,
      data: {
        donationTotals: newDonationTotals,
      },
    });
  } catch (error) {}
};

function updateClynkToGiveMemberTotalRaised(
  cache: ApolloCache<DonateFundsResult>,
  id: string,
  amount: number
) {
  const member = cache.readFragment<ClynkToGiveMember>({
    id: `ClynkToGiveMember:${id}`,
    fragment: gql`
      fragment ClynkToGiveMemberTotalRaised on ClynkToGiveMember {
        totals {
          raised
        }
      }
    `,
  });

  if (member) {
    cache.writeFragment({
      id: `ClynkToGiveMember:${id}`,
      fragment: gql`
        fragment ClynkToGiveMemberTotalRaised on ClynkToGiveMember {
          totals {
            raised
          }
        }
      `,
      data: {
        totals: {
          raised: member.totals.raised + amount,
        },
      },
    });
  } else {
  }
}

export const useDonateFundsMutation = (): MutationTuple<
  DonateFundsResult,
  DonateFundsVariables
> => {
  return useMutation<DonateFundsResult, DonateFundsVariables>(
    DonateFundsMutation,
    {
      update(cache, { data }) {
        if (!data) {
          return;
        }

        const { balance, to, amount, result } = data.donateFunds;

        const accountData = cache.readQuery<{ account: Account }>({
          query: gql`
            query {
              account {
                id
              }
            }
          `,
        });

        if (accountData === null) {
          return;
        }

        const accountId = accountData.account.id;

        if (balance != null) {
          updateBalance(cache, accountId, balance);
        }

        if (result.success) {
          updateDonationTotal(cache, accountId, to, amount);
          updateClynkToGiveMemberTotalRaised(cache, to, amount);
        }
      },
    }
  );
};
