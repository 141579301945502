/*
 * File: src/components/dialogs/ForgotPassword.tsx
 * Notes:
 *   > ...
 */

import React, {
  FormEventHandler,
  VoidFunctionComponent,
  useEffect,
} from "react";
import Dialog, { DialogProps } from "./Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { useFormik, FormikContext, FormikHelpers } from "formik";
import ProgressButton from "../button/ProgressButton";
import { DialogContent, Collapse, Typography, Button } from "@material-ui/core";
import { gql, useApolloClient } from "@apollo/client";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
  CUSTOMER_SERVICE_NUMBER,
  GENERIC_ERROR,
} from "../../constants/strings";
import { Result, ResultFailed, ResultSuccess } from "../../gql/schema/types";
import FormikTextField from "../inputs/FormikTextField";
import FormikField from "../inputs/FormikField";
import getApolloErrorMessage from "../../gql/getApolloErrorMessage";

const SecurityQuestionForUsernameQuery = gql`
  query ($username: String!) {
    securityQuestionForUsername(username: $username) {
      success
      message
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
      ... on SecurityQuestionForUsernameSuccess {
        question
      }
    }
  }
`;

interface QueryData {
  securityQuestionForUsername:
    | (ResultSuccess & { question: string })
    | ResultFailed;
}

const ForgotPasswordMutation = gql`
  mutation ($username: String!, $response: String!) {
    forgotPassword(username: $username, response: $response) {
      success
      message
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

const Step1: VoidFunctionComponent = () => {
  return (
    <>
      <FormikField
        name="username"
        label="Username"
        component={FormikTextField}
      />

      <input
        type="submit"
        style={{ position: "absolute", left: -99999, width: 1, height: 1 }}
        tabIndex={-1}
      />
    </>
  );
};

const Step2: VoidFunctionComponent<{ question: string }> = ({ question }) => {
  return (
    <>
      <Typography gutterBottom>{question}</Typography>

      <FormikField
        name="response"
        label="Security Response"
        component={FormikTextField}
      />
    </>
  );
};

const Step3: VoidFunctionComponent = () => {
  return (
    <div>
      <Typography style={{ marginBottom: 16 }}>
        We just sent an email with your new password to your email address on
        record. Once you receive this email, you may use your new password to
        login. If you do not see this email, check your spam folder to see if it
        may have gone there.
      </Typography>

      <Typography variant="body2">
        * Still can't find it? Contact customer service at{" "}
        {CUSTOMER_SERVICE_NUMBER}.
      </Typography>
    </div>
  );
};

const schema = Yup.object({
  username: Yup.string().required("Please enter your username.").default(""),
  response: Yup.string()
    .test("required", "Please enter your security response.", function (value) {
      return this.parent.step !== 1 || (value != null && value.length > 0);
    })
    .default(""),
});

type Values = Yup.TypeOf<typeof schema> & { step: number; question: string };

const ForgotPassword: VoidFunctionComponent<DialogProps> = ({
  open,
  onClose,
}) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    const { step, username, response } = values;

    if (step === 0) {
      try {
        const { data } = await client.query<QueryData>({
          query: SecurityQuestionForUsernameQuery,
          variables: { username },
        });

        if (data) {
          const { success, message } = data.securityQuestionForUsername;

          if (success) {
            const { question } = data.securityQuestionForUsername;

            actions.setFormikState((f) => ({
              ...f,
              touched: {
                ...f.touched,
                response: undefined,
              },
              values: {
                ...f.values,
                question: question,
                step: f.values.step + 1,
              },
            }));
          } else {
            const { fields } = data.securityQuestionForUsername;

            if (fields) {
              const status = Object.fromEntries(
                fields.map((f) => [f.field, f.errors])
              );
              actions.setStatus(status);
            }

            if (message) {
              enqueueSnackbar(message, { variant: "error" });
            }
          }
        } else {
          enqueueSnackbar(GENERIC_ERROR, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(getApolloErrorMessage(error), {
          variant: "error",
        });
      }
    } else if (step === 1) {
      try {
        const { data } = await client.mutate<Result<"forgotPassword">>({
          mutation: ForgotPasswordMutation,
          variables: {
            username,
            response,
          },
        });

        if (data) {
          const { success, message } = data.forgotPassword;

          if (success) {
            actions.setFormikState((f) => ({
              ...f,
              values: {
                ...f.values,
                step: f.values.step + 1,
              },
            }));
          } else {
            const { fields } = data.forgotPassword;

            if (fields) {
              const status = Object.fromEntries(
                fields.map((f) => [f.field, f.errors])
              );
              actions.setStatus(status);
            }

            if (message) {
              enqueueSnackbar(message, { variant: "error" });
            }
          }
        } else {
          enqueueSnackbar(GENERIC_ERROR, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(getApolloErrorMessage(error), {
          variant: "error",
        });
      }
    }
  };

  const formik = useFormik<Values>({
    initialValues: {
      step: 0,
      question: "",
      ...schema.getDefault(),
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please enter your username."),
      response: Yup.string().test(
        "required",
        "Please enter your security response.",
        function (value) {
          return this.parent.step !== 1 || (value != null && value.length > 0);
        }
      ),
    }),
    onSubmit: handleSubmit,
  });

  const { resetForm } = formik;

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open, resetForm]);

  const handleBack = () => {
    formik.setFormikState((f) => ({
      ...f,
      values: { ...f.values, step: Math.max(0, f.values.step - 1) },
    }));
  };

  const handleNext = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  return (
    <Dialog
      showCloseButton
      disableEscapeKeyDown={formik.isSubmitting}
      open={open}
      fullScreen={false}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Forgot Password</DialogTitle>

      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: 1 }} />

        <form onSubmit={onSubmit}>
          <FormikContext.Provider value={formik}>
            <Collapse in={formik.values.step === 0} unmountOnExit>
              <Step1 />
            </Collapse>

            <Collapse in={formik.values.step === 1} unmountOnExit>
              <Step2 question={formik.values.question} />
            </Collapse>

            <Collapse in={formik.values.step === 2} unmountOnExit>
              <Step3 />
            </Collapse>
          </FormikContext.Provider>
        </form>
      </DialogContent>

      <DialogActions>
        {formik.values.step === 1 && <Button onClick={handleBack}>Back</Button>}
        {formik.values.step <= 1 && (
          <ProgressButton
            onClick={handleNext}
            color="primary"
            variant="contained"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            LinearProgressProps={{ color: "secondary" }}
          >
            Continue
          </ProgressButton>
        )}
        {formik.values.step === 2 && (
          <Button onClick={onClose} color="primary" variant="contained">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ForgotPassword;
