/*
 * File: src/preferences/PreferencesProvider.tsx
 * Notes:
 *   > ...
 */

import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  FunctionComponent,
} from "react";
import { Preferences } from "./Preferences";
import PreferencesContext from "./PreferencesContext";

interface PreferencesProviderProps {
  initial: Preferences;
}

const PreferencesProvider: FunctionComponent<PreferencesProviderProps> = ({
  initial,
  children,
}) => {
  const [preferences, setPreferences] = useState(initial);

  const toggleTheme = useCallback(() => {
    setPreferences((value) => {
      localStorage.setItem("preferences", JSON.stringify(value));

      return value;
    });
  }, [setPreferences]);

  const context = useMemo(
    () => ({
      preferences,
      toggleTheme,
    }),
    [preferences, toggleTheme]
  );

  useEffect(() => {
    const callback = () => {
      const stored = localStorage.getItem("preferences");

      if (!stored) {
        return;
      }

      try {
        const obj = JSON.parse(stored);

        setPreferences(obj);
      } catch (error) {}
    };

    window.addEventListener("storage", callback);

    return () => {
      window.removeEventListener("storage", callback);
    };
  }, []);

  return (
    <PreferencesContext.Provider value={context}>
      {children}
    </PreferencesContext.Provider>
  );
};

export default PreferencesProvider;
