/*
 * File: src/components/dashboard/dialogs/JoinBagDonationEvent.tsx
 * Notes:
 *   > ...
 */

import React, {
  useCallback,
  useEffect,
  useState,
  FormEvent,
  VoidFunctionComponent,
  Dispatch,
  SetStateAction,
} from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useFormik, FormikProps } from "formik";
import { BagDonationEvent, CardHolder } from "../../../gql/schema/types";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from "@material-ui/core";
import ProgressButton from "../../button/ProgressButton";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useJoinBagDonationEventMutation } from "../../../gql/schema/mutations/JoinBagDonationEvent";
import FormikTextField from "../../inputs/FormikTextField";

const Query = gql`
  query ($id: BagDonationEventId!) {
    bagDonationEvent(id: $id) {
      id
      name
      owner {
        id
        organization
      }
      startsOn
      endsOn
    }
    cardHolder {
      id
      info {
        name {
          first
          last
        }
      }
    }
  }
`;

interface QueryData {
  bagDonationEvent: BagDonationEvent;
  cardHolder: CardHolder;
}

interface QueryVariables {
  id: string;
}

type OnClose = (
  event?: Parameters<NonNullable<DialogProps["onClose"]>>[0],
  reason?: Parameters<NonNullable<DialogProps["onClose"]>>[1]
) => void;

export interface JoinBagDonationEventProps
  extends Omit<DialogProps, "children" | "onClose" | "open"> {
  onClose: OnClose;
  bagDonationEventId: string | null;
}

interface State {
  step: number;
  preventClose: boolean;
}

interface Form {
  displayName: string;
}

interface StepProps {
  data: QueryData | undefined;
  onClose: OnClose;
  formik: FormikProps<Form>;
  setState: Dispatch<SetStateAction<State>>;
}

const Step1: VoidFunctionComponent<StepProps> = ({
  onClose,
  data,
  setState,
}) => {
  let title = "Bag Donation Event";
  let endsOn = "when the event ends";

  if (data) {
    title = data.bagDonationEvent.name;
    endsOn = moment(data.bagDonationEvent.endsOn).format("MMMM DD, yyyy");
  }

  const handleContinue = useCallback(() => {
    setState((s) => ({
      ...s,
      step: 1,
    }));
  }, [setState]);

  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Typography gutterBottom>
          By enrolling in this bag donation event, you are agreeing to donate
          the proceeds of all bags processed between now and {endsOn}.
        </Typography>

        <Typography variant="subtitle2">
          * Should you change your mind, you can unenroll at any time.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <Button variant="contained" color="primary" onClick={handleContinue}>
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

const Step2: VoidFunctionComponent<StepProps> = ({
  formik,
  data,
  setState,
}) => {
  let name = "Bag Donation Event";

  if (data) {
    name = data.bagDonationEvent.name;
  }

  const handleSubmit = () => {
    formik.submitForm();
  };

  const handleSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit();
  };

  const handleBack = () => {
    setState((s) => ({ ...s, step: 0 }));
  };

  return (
    <>
      <DialogTitle>{name}</DialogTitle>

      <DialogContent>
        <Typography>
          The display name below will be provided to the organization holding
          the event. If you would like to remain anonymous, leave the field
          blank. If you are supporting a friend who is participating in this
          event, enter their name so that they will get credit with the
          organization toward their fundraising goal for the event.
        </Typography>

        <form onSubmit={handleSubmitForm}>
          <FormikTextField
            style={{ marginTop: 16 }}
            label="Display Name"
            field={formik.getFieldProps("displayName")}
            form={formik}
          />
          <input type="submit" style={{ display: "none" }} />
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleBack} disabled={formik.isSubmitting}>
          Back
        </Button>

        <ProgressButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          LinearProgressProps={{ color: "secondary" }}
        >
          Enroll
        </ProgressButton>
      </DialogActions>
    </>
  );
};

const JoinBagDonationEvent: VoidFunctionComponent<
  JoinBagDonationEventProps
> = ({ onClose, bagDonationEventId, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<State>({
    step: 0,
    preventClose: false,
  });

  const [join] = useJoinBagDonationEventMutation();
  const [query, { data }] = useLazyQuery<QueryData, QueryVariables>(Query);

  const handleSubmit = useCallback(
    async (values: Form) => {
      if (!data || bagDonationEventId === null) {
        return;
      }

      const name = data.bagDonationEvent.name;

      try {
        const { data: joinData } = await join({
          variables: {
            input: {
              eventId: bagDonationEventId,
              displayName:
                values.displayName.length > 0 ? values.displayName : null,
            },
          },
        });

        const result = joinData?.joinBagDonationEvent;

        if (!result) {
          enqueueSnackbar("Bag donation event enrollment failed.", {
            variant: "error",
          });
          return;
        }

        const { success, message } = result;

        if (success) {
          enqueueSnackbar(message || `Thank you for enrolling in ${name}.`, {
            variant: "success",
          });
          onClose();
        } else {
          enqueueSnackbar(message || "Bag donation event enrollment failed.", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Bag donation event enrollment failed.", {
          variant: "error",
        });
      }
    },
    [join, bagDonationEventId, data, enqueueSnackbar, onClose]
  );

  const formik = useFormik<Form>({
    initialValues: {
      displayName: "",
    },
    onSubmit: handleSubmit,
  });

  const { resetForm } = formik;

  useEffect(() => {
    if (bagDonationEventId != null) {
      setState((s) => ({ ...s, step: 0 }));

      resetForm();

      query({
        variables: {
          id: bagDonationEventId,
        },
      });
    }
  }, [bagDonationEventId, resetForm, query]);

  const Step = state.step === 0 ? Step1 : Step2;

  const handleClose: DialogProps["onClose"] = useCallback(
    (event, reason) => {
      if (reason !== "backdropClick") {
        return formik.isSubmitting;
      }
      return onClose();
    },
    [onClose, formik.isSubmitting]
  );

  return (
    <Dialog
      {...rest}
      open={bagDonationEventId !== null}
      onClose={handleClose}
      disableEscapeKeyDown={formik.isSubmitting}
      fullWidth
      maxWidth="sm"
    >
      <Step onClose={onClose} setState={setState} data={data} formik={formik} />
    </Dialog>
  );
};

export default JoinBagDonationEvent;
