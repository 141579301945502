/*
 * File: src/components/common/PinMaskedInput.tsx
 * Notes:
 *   > ...
 */

import React, {
  ForwardedRef,
  InputHTMLAttributes,
  VoidFunctionComponent,
} from "react";
import MaskedInput from "@clynkdotcom/react-text-mask";

export interface PinMaskedInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: ForwardedRef<HTMLInputElement>;
}

const mask = [/\d/, /\d/, /\d/, /\d/];

const PinMaskedInput: VoidFunctionComponent<PinMaskedInputProps> = ({
  inputRef,
  ...rest
}) => {
  return (
    <MaskedInput
      {...rest}
      ref={(value) => {
        const element = value ? (value.inputElement as HTMLInputElement) : null;

        if (inputRef) {
          if (typeof inputRef === "function") {
            inputRef(element);
          } else {
            inputRef.current = element;
          }
        }
      }}
      mask={mask}
      guide={false}
    />
  );
};

export default PinMaskedInput;
