/*
 * File: src/signup/form/TermsOfServiceAgreement.tsx
 * Notes:
 *   > ...
 */

import { ComponentType } from "react";
import { AnySchema } from "yup";
import FormActions from "../form/FormActions";

export type FormGroupSchema = Record<string, AnySchema>;

export interface FormGroupProps {
  actions: FormActions;
  isFirstStep: boolean;
  isLastStep: boolean;
}

type FormGroup<
  TProps extends FormGroupProps = FormGroupProps,
  TSchema extends Record<string, AnySchema> = Record<string, AnySchema>
> = ComponentType<TProps> & {
  schema: TSchema;
};

export const createFormGroup = <
  TProps extends FormGroupProps = FormGroupProps,
  TSchema extends FormGroupSchema = FormGroupSchema
>(
  component: ComponentType<TProps>,
  schema: TSchema
): FormGroup<TProps, TSchema> => {
  return Object.assign(component, { schema });
};

export default FormGroup;
