/*
 * File: src/components/common/PageContent.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import Container, { ContainerProps } from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "2.5rem",
      paddingBottom: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "3.75rem",
      paddingBottom: "3.75rem",
    },
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
}));

const PageContent: FunctionComponent<ContainerProps> = ({
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.container} {...rest}>
      {children}
    </Container>
  );
};

export default PageContent;
