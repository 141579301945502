/*
 * File: src/components/dashboard/sidebar/SidebarNodeIcon.tsx
 * Notes:
 *   > ...
 */

import React, { ComponentType, VoidFunctionComponent } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme, Omit<SidebarNodeIconProps, "Icon">>(
  (theme) => ({
    root: {
      display: "flex",
      marginLeft: 32,
      marginRight: 12,
      alignItems: "center",
    },
    icon: {
      transition: "fill .15s ease",
      opacity: ({ selected }) => (selected ? 1 : 0.5),
      fill: ({ selected }) =>
        selected ? theme.palette.primary.light : "currentColor",
    },
  })
);

export interface SidebarNodeIconProps {
  selected?: boolean;
  Icon: ComponentType<{ className?: string }>;
}

const SidebarNodeIcon: VoidFunctionComponent<SidebarNodeIconProps> = ({
  Icon,
  ...rest
}) => {
  const classes = useStyles(rest);

  return (
    <div className={classes.root} {...rest}>
      <Icon className={classes.icon} />
    </div>
  );
};

export default SidebarNodeIcon;
