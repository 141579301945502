/*
 * File: src/components/buttons/ProgressButton.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button, { ButtonProps } from "@material-ui/core/Button";
import LinearProgress, {
  LinearProgressProps as MuiLinearProgressProps,
} from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(() => ({
  progress: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

export interface ProgressButtonProps extends ButtonProps {
  loading?: boolean;
  LinearProgressProps?: MuiLinearProgressProps;
}

const ProgressButton: FunctionComponent<ProgressButtonProps> = ({
  children,
  loading,
  LinearProgressProps,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Button {...rest}>
      {children}

      <div
        className={classes.progress}
        style={{ visibility: loading ? "visible" : "hidden" }}
      >
        <LinearProgress
          style={{ borderBottomLeftRadius: 3, borderBottomRightRadius: 3 }}
          {...LinearProgressProps}
        />
      </div>
    </Button>
  );
};

export default ProgressButton;
