/*
 * File: src/components/signup/groups/FormGroupAddress.tsx
 * Notes:
 *   > ...
 */

import React, {
  ChangeEventHandler,
  VoidFunctionComponent,
  KeyboardEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { useField, useFormikContext } from "formik";
import { createFormGroup, FormGroupProps } from "../group/FormGroup";
import {
  AccountUsageSchema,
  OrganizationSchema,
  StreetSchema,
  Street2Schema,
  CountrySchema,
  CitySchema,
  StateSchema,
  ZipCodeSchema,
  ValidatedSchema,
} from "../../../validation/schema";
import Divider from "@material-ui/core/Divider";
import ConfirmAddress from "../../../components/dialogs/ConfirmAddress";
import BadAddress from "../../dialogs/BadAddress";
import FormGroupContent from "../group/FormGroupContent";
import { useSnackbar } from "notistack";
import AddressValidator, {
  ValidatedAddress,
} from "../../../services/AddressValidator";
import { object, TypeOf } from "yup";
import FormikField from "../../inputs/FormikField";
import FormikTextField from "../../inputs/FormikTextField";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
}));

const AccountUsageSelect: VoidFunctionComponent<TextFieldProps> = (props) => {
  const [{ onChange, ...rest }] = useField("usage");

  const handleChange: ChangeEventHandler = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange]
  );

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label="Account Use"
      select
      margin="dense"
      {...rest}
      onChange={handleChange}
      {...props}
    >
      {["Personal", "Business"].map((value) => (
        <MenuItem key={value} value={value.toUpperCase()}>
          {value}
        </MenuItem>
      ))}
    </TextField>
  );
};

const CountrySelect: VoidFunctionComponent<TextFieldProps> = (props) => {
  const formik = useFormikContext();

  const field = formik.getFieldProps("country");

  const handleChange: ChangeEventHandler = (event) => {
    formik.setFieldValue("state", "");
    formik.setFieldValue("zipcode", "");
    formik.setFieldValue("validated", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("street", "");
    formik.setFieldValue("street2", "");

    field.onChange(event);
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label="Country"
      select
      margin="dense"
      value={field.value}
      name={field.name}
      onBlur={field.onBlur}
      onChange={handleChange}
      {...props}
    >
      {["United States", "Canada"].map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </TextField>
  );
};

const Organization: VoidFunctionComponent = () => {
  const [field] = useField("usage");

  if (field.value === "PERSONAL") {
    return <></>;
  }

  return (
    <Grid item xs={12} sm={8}>
      <FormikField
        name="organization"
        component={FormikTextField}
        label="Business Name"
      />
    </Grid>
  );
};

interface FormGroupAddressProps extends FormGroupProps {
  message: string;
}

const schema = {
  usage: AccountUsageSchema,
  organization: OrganizationSchema,
  street: StreetSchema,
  street2: Street2Schema,
  country: CountrySchema,
  city: CitySchema,
  state: StateSchema,
  zipcode: ZipCodeSchema,
  validated: ValidatedSchema,
};

const shape = object(schema);

type Form = TypeOf<typeof shape>;

const FormGroupAddress: VoidFunctionComponent<FormGroupAddressProps> = ({
  actions,
  isFirstStep,
  isLastStep,
  message,
}) => {
  const formik = useFormikContext<Form>();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const [badAddrMessage, setBadAddrMessage] = useState<string>("");
  const [openBad, setOpenBad] = useState(false);

  const [addresses, setAddresses] = useState<ValidatedAddress[]>([]);
  const [loading, setLoading] = useState(false);

  const notValidatedMsg = `We could not validate the address you entered. Please double check your input.`;

  const handleClose = useCallback(() => {
    setOpen(false);
    setOpenBad(false);
  }, [setOpen, setOpenBad]);

  const handleClick = useCallback(
    async (event: SyntheticEvent) => {
      const errors = formik.errors;

      const formErrors =
        errors.street ||
        errors.street2 ||
        errors.city ||
        errors.state ||
        errors.zipcode ||
        errors.country;

      if (formErrors) {
        setOpenBad(true);

        if (formErrors === "This field is required.") {
          setBadAddrMessage(notValidatedMsg);
        }

        // TODO - Handle possible other types
        setBadAddrMessage(formErrors as string);
      }

      if (!formErrors && formik.values.validated !== "Y") {
        event.stopPropagation();
        event.preventDefault();

        setLoading(true);

        try {
          const response = await AddressValidator.validate({
            country: formik.values.country,
            street: formik.values.street,
            street2: formik.values.street2,
            city: formik.values.city,
            state: formik.values.state,
            zipcode: formik.values.zipcode,
          });

          setAddresses(response);

          if (response.length === 0) {
            setOpenBad(true);
            setBadAddrMessage(notValidatedMsg);
            formik.setFieldValue("validated", "N");
          } else if (response.length === 1) {
            const addr = response[0];

            if (addr.status === "Y") {
              setOpen(true);
            } else if (addr.status === "N") {
              setOpenBad(true);
            } else {
              formik.setFieldValue("validated", addr.status);

              // After setting the validated field value, manually trigger revalidation.
              // This should make the street fields revalidate (observing the new value
              // for "validated"). I found that I had to use the returned errors from
              // validateForm() here, instead of checking formik.errors. It seems like
              // formik.errors doesn't get updated right away.
              const errors2 = await formik.validateForm();

              const error =
                errors2.street ||
                errors2.street2 ||
                errors2.city ||
                errors2.state ||
                errors2.zipcode ||
                errors2.country;

              setOpenBad(true);

              // TODO - Handle different types
              setBadAddrMessage(error as string);
            }
          } else {
            setOpen(true);
          }
        } catch (error) {
          let alert = "An error occured validating your address.";

          if (error.response?.status === 429) {
            alert =
              "You have made too many attempts to validate your address. Please wait before trying again.";
          }

          enqueueSnackbar(alert, { variant: "error" });
        } finally {
          setLoading(false);
        }
      }
    },
    [enqueueSnackbar, formik, notValidatedMsg]
  );

  const resetValidation = useCallback(() => {
    formik.setFieldValue("validated", "");
  }, [formik]);

  const submitOnEnter: KeyboardEventHandler = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleClick(event);
      }
    },
    [handleClick]
  );

  const handleConfirm = (address: ValidatedAddress) => {
    formik.setFieldValue("street", address.street);
    formik.setFieldValue("street2", address.street2 ?? "");
    formik.setFieldValue("city", address.locality);
    formik.setFieldValue("state", address.administrativeArea);
    formik.setFieldValue("zipcode", address.postalCode);
    formik.setFieldValue("validated", address.status);

    // close the dialog
    setOpen(false);

    // if success, then go to next step
    if (address.status === "Y") {
      setImmediate(() => {
        actions.next();
      });
    }
  };

  return (
    <>
      <FormGroupContent
        actions={actions}
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        NextButtonProps={{ onClick: handleClick, loading, disabled: loading }}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography gutterBottom className={classes.raleway}>
              {message}
            </Typography>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xs={12} sm={4}>
                <AccountUsageSelect />
              </Grid>
              <Organization />
            </Grid>
          </Grid>

          <Divider style={{ marginTop: 16, marginBottom: 16 }} />

          <Grid item xs={12} sm={4}>
            <CountrySelect />
          </Grid>

          <Grid item xs={12}>
            <FormikField
              name="street"
              component={FormikTextField}
              label="Street"
              onInput={resetValidation}
              onKeyPress={submitOnEnter}
              autoFocus={true}
            />
            <FormikField
              name="street2"
              component={FormikTextField}
              label="Street 2"
              onInput={resetValidation}
              onKeyPress={submitOnEnter}
            />
            <FormikField
              name="city"
              component={FormikTextField}
              label="City"
              onInput={resetValidation}
              onKeyPress={submitOnEnter}
            />
            <FormikField
              name="state"
              component={FormikTextField}
              label="State/Province"
              onInput={resetValidation}
              onKeyPress={submitOnEnter}
            />
            <FormikField
              name="zipcode"
              component={FormikTextField}
              label="Zipcode/Postal Code"
              onInput={resetValidation}
              onKeyPress={submitOnEnter}
            />
          </Grid>
        </Grid>
      </FormGroupContent>

      <ConfirmAddress
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        country={formik.values.country}
        addresses={addresses}
      />

      <BadAddress
        open={openBad}
        onClose={handleClose}
        message={badAddrMessage}
      />
    </>
  );
};

export default createFormGroup(FormGroupAddress, schema);
