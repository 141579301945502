/*
 * File: src/gql/schema/mutations/CancelBagDonation.ts
 * Notes:
 *   > ...
 */

import { gql, MutationTuple, useMutation } from "@apollo/client";
import { IResult } from "../types";
import { Account } from "../types/Account";
import { CancelBagDonationSuccess } from "../types/CancelBagDonationSuccess";

export const CancelBagDonationMutation = gql`
  mutation ($id: BagDonationId!) {
    cancelBagDonation(id: $id) {
      success
      message
      ... on CancelBagDonationSuccess {
        id
      }
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

export interface CancelBagDonationVariables {
  id: string;
}

interface Response {
  cancelBagDonation: IResult;
}

export const useCancelBagDonationMutation = (): MutationTuple<
  Response,
  CancelBagDonationVariables
> => {
  return useMutation<Response, CancelBagDonationVariables>(
    CancelBagDonationMutation,
    {
      update(cache, response) {
        if (!response?.data?.cancelBagDonation) {
          return;
        }

        const { cancelBagDonation: result } = response.data;

        if (!result.success) {
          return;
        }

        const success = result as CancelBagDonationSuccess;

        try {
          const accountData = cache.readQuery<{ account: Account }>({
            query: gql`
              query {
                account {
                  id
                }
              }
            `,
          });

          if (accountData === null) {
            return;
          }

          const { account } = accountData;

          const fragment = gql`
            fragment AccountBagDonations on Account {
              activeBagDonations {
                id
              }
            }
          `;

          const account2 = cache.readFragment<Account>({
            id: `Account:${account.id}`,
            fragment,
          });

          if (account2 === null) {
            return;
          }

          const filtered = account2.activeBagDonations.filter(
            (d) => d.id !== success.id
          );

          cache.writeFragment({
            id: `Account:${account.id}`,
            fragment,
            data: {
              activeBagDonations: filtered,
            },
          });
        } catch (error) {
          console.error(error);
        }
      },
    }
  );
};
