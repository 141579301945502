/*
 * File: src/state/dashboard/DashboardState.ts
 * Notes:
 *   > ...
 */

export interface IDialogState {
  openId: number;
  // step: number;
}

export interface DonateDialogState extends IDialogState {
  clynkToGiveMemberId: string | null;
}

export interface DashboardState {
  c2gDrawerTarget: string | null;
  cancelBagDonationId: string | null;
  joinBagDonationEventId: string | null;
  donate: DonateDialogState;
}

export const OPEN_C2G_DRAWER = "OPEN_C2G_DRAWER";
export const CLOSE_C2G_DRAWER = "CLOSE_C2G_DRAWER";

export const OPEN_CANCEL_BAG_DONATION = "OPEN_CANCEL_BAG_DONATION";
export const CLOSE_CANCEL_BAG_DONATION = "CLOSE_CANCEL_BAG_DONATION";

export const OPEN_JOIN_BAG_DONATION_EVENT = "OPEN_JOIN_BAG_DONATION_EVENT";
export const CLOSE_JOIN_BAG_DONATION_EVENT = "CLOSE_BAG_DONATION_EVENT";

export const OPEN_DONATE = "OPEN_DONATE";
export const CLOSE_DONATE = "CLOSE_DONATE";

export interface OpenC2GDrawer {
  type: typeof OPEN_C2G_DRAWER;
  accountId: string;
}

export interface CloseC2GDrawer {
  type: typeof CLOSE_C2G_DRAWER;
}

export interface OpenCancelBagDonation {
  type: typeof OPEN_CANCEL_BAG_DONATION;
  bagDonationId: string;
}

export interface CloseCancelBagDonation {
  type: typeof CLOSE_CANCEL_BAG_DONATION;
}

export interface OpenJoinBagDonationEvent {
  type: typeof OPEN_JOIN_BAG_DONATION_EVENT;
  bagDonationEventId: string;
}

export interface CloseJoinBagDonationEvent {
  type: typeof CLOSE_JOIN_BAG_DONATION_EVENT;
}

export interface OpenDonate {
  type: typeof OPEN_DONATE;
  clynkToGiveMemberId: string;
}

export interface CloseDonate {
  type: typeof CLOSE_DONATE;
}

export type DashboardAction =
  | OpenC2GDrawer
  | CloseC2GDrawer
  | OpenCancelBagDonation
  | CloseCancelBagDonation
  | OpenJoinBagDonationEvent
  | CloseJoinBagDonationEvent
  | OpenDonate
  | CloseDonate;
