/*
 * File: src/common/formatCurrency.ts
 * Notes:
 *   > ...
 */

const formatCurrency = (value: number): string => {
  // TODO: change to, toString().insert(".", -2)
  // But this will need to handle 0, 10, 15 ...
  return (value / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export default formatCurrency;
