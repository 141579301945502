/*
 * File: src/components/signup/groups/FormGroupEnterPIN.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Pin from "../../inputs/PinInput";
import { FormGroupProps, createFormGroup } from "../group/FormGroup";
import { PinSchema } from "../../../validation/schema";
import { FormHelperText } from "@material-ui/core";
import { CUSTOMER_SERVICE_NUMBER } from "../../../constants/strings";
import FormGroupContent from "../group/FormGroupContent";
import { useField } from "formik";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
}));

const FormGroupEnterPIN: VoidFunctionComponent<FormGroupProps> = ({
  actions,
  isFirstStep,
  isLastStep,
}) => {
  const [field, meta] = useField<string>("pin");
  const classes = useStyles();

  return (
    <FormGroupContent
      actions={actions}
      isFirstStep={isFirstStep}
      isLastStep={isLastStep}
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography gutterBottom className={classes.raleway}>
            Please enter your 4-digit Kiosk PIN.
          </Typography>
        </Grid>

        <Grid item>
          <Pin {...field} autoFocus />

          {meta.touched && meta.error && (
            <FormHelperText error>{meta.error}</FormHelperText>
          )}
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            className={classes.raleway}
            style={{ marginTop: 16 }}
          >
            Forgot your pin? Call CLYNK Customer Service at{" "}
            {CUSTOMER_SERVICE_NUMBER}.
          </Typography>
        </Grid>
      </Grid>
    </FormGroupContent>
  );
};

export default createFormGroup(FormGroupEnterPIN, {
  pin: PinSchema,
});
