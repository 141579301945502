/*
 * File: src/routes/dashboard/drawers/ClynkToGiveDrawer.tsx
 * Notes:
 *   > ...
 */

import React, {
  useEffect,
  useCallback,
  useContext,
  VoidFunctionComponent,
} from "react";
import {
  makeStyles,
  Theme,
  IconButton,
  Typography,
  Button,
  Fade,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { gql, useLazyQuery } from "@apollo/client";
import formatNumber from "../../../common/formatNumber";
import formatCurrency from "../../../common/formatCurrency";
import CloseIcon from "@material-ui/icons/Close";
import { ClynkToGiveMember } from "../../../gql/schema/types/ClynkToGiveMember";
import {
  DashboardContext,
  OPEN_DONATE,
} from "../../../routers/dashboard/state";
import { Account, Impact } from "../../../gql/schema/types";
import { ImpactType } from "../../../gql/schema/enums";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 450,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.background.paper
        : "#334150",
    color: theme.palette.type === "dark" ? "inherit" : "white",
  },
  info: {
    paddingTop: 16,
    paddingBottom: 16,
    "& > *:not(:last-child)": {
      marginBottom: 16,
    },
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
  },
  text: {
    fontFamily: "Raleway-Regular !important",
  },
  buttonText: {
    fontSize: 16,
    textTransform: "capitalize",
    color: "#133C55 !important",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
  },
}));

const Query = gql`
  query ($id: ClynkToGiveMemberId!) {
    clynkToGiveMember(id: $id) {
      id
      organization
      totals {
        containers
        raised
      }
      impacts {
        type
        units
      }
    }
    account {
      id
      balance
    }
  }
`;

interface QueryData {
  clynkToGiveMember: ClynkToGiveMember;
  account: Account;
}

interface ImpactUnitProps {
  type: ImpactType;
  units: number;
}

const ImpactUnit: VoidFunctionComponent<ImpactUnitProps> = ({
  type,
  units,
}) => {
  const formatted = formatNumber(units, 1, true);
  const classes = useStyles();

  let text = "";

  if (type === "VOLUME") {
    text = `Diverted enough containers to fill ${formatted} lobster traps.`;
  } else if (type === "ENERGY") {
    text = `Saved enough energy to light ${formatted} lightbulbs—24 hours a day—for a year.`;
  } else if (type === "EMISSIONS") {
    text = `Saved the emissions equivalent of driving a car ${formatted} miles.`;
  }

  if (!text) {
    return null;
  }

  return (
    <Typography variant="body1" className={classes.text}>
      {text}
    </Typography>
  );
};

interface ImpactsProps {
  data?: QueryData;
}

const Impacts: VoidFunctionComponent<ImpactsProps> = ({ data }) => {
  const impacts = data?.clynkToGiveMember.impacts ?? [];

  return (
    <>
      {impacts.map((impact: Impact) => (
        <ImpactUnit key={impact.type} type={impact.type} units={impact.units} />
      ))}
    </>
  );
};

export interface ClynkToGiveDrawerProps {
  onClose: () => void;
  accountId: string | null;
}

const ClynkToGiveDrawer: VoidFunctionComponent<ClynkToGiveDrawerProps> = ({
  onClose,
  accountId,
}) => {
  const [, dispatch] = useContext(DashboardContext);

  // Could explore returnPartialData here...since we should always have the org in cache...
  const [query, { data }] = useLazyQuery<QueryData>(Query, {
    returnPartialData: true,
  });

  const classes = useStyles();

  const handleDonateClick = useCallback(() => {
    if (accountId !== null) {
      dispatch({
        type: OPEN_DONATE,
        clynkToGiveMemberId: accountId,
      });
    }
  }, [accountId, dispatch]);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    query({ variables: { id: accountId } });
  }, [accountId, query]);

  return (
    <Drawer
      open={!!accountId}
      onClose={onClose}
      anchor="right"
      variant="temporary"
      PaperProps={{ className: classes.container }}
    >
      <div style={{ position: "absolute", top: 0, left: 0 }}>
        <IconButton onClick={onClose} style={{ color: "inherit" }}>
          <CloseIcon />
        </IconButton>
      </div>

      <Typography
        variant="h5"
        align="center"
        style={{
          fontWeight: "bold",
          paddingLeft: 32,
          paddingRight: 32,
          marginTop: 32,
          marginBottom: 32,
        }}
        className={classes.title}
      >
        {data?.clynkToGiveMember.organization || "..."}
      </Typography>

      {data?.clynkToGiveMember.totals !== undefined && (
        <Fade in>
          <div style={{ paddingLeft: 32, paddingRight: 32, minHeight: 0 }}>
            <div style={{ textAlign: "center", marginBottom: 32 }}>
              <Typography className={classes.text}>Total Raised</Typography>
              <Typography variant="h4" className={classes.text}>
                $
                {data
                  ? formatCurrency(data.clynkToGiveMember.totals.raised)
                  : "..."}
              </Typography>

              <Typography style={{ marginTop: 16 }} className={classes.text}>
                Containers CLYNKed
              </Typography>
              <Typography variant="h4" className={classes.text}>
                {data
                  ? formatNumber(data.clynkToGiveMember.totals.containers, 0)
                  : "..."}
              </Typography>
            </div>

            <div className={classes.info}>
              <Impacts data={data} />
            </div>
          </div>
        </Fade>
      )}

      <div className={classes.grow} />

      <div style={{ paddingLeft: 16, paddingRight: 16, width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ height: 60 }}
          onClick={handleDonateClick}
          className={classes.buttonText}
        >
          Choose Amount
        </Button>
      </div>
    </Drawer>
  );
};

export default ClynkToGiveDrawer;
