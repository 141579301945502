/*
 * File: src/components/signup/forms/UpgradeAccount.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import { gql, useMutation } from "@apollo/client";
import FormGroupEnterPIN from "../groups/FormGroupEnterPIN";
import FormGroupOnlineAccess from "../groups/FormGroupOnlineAccess";
import FormGroupAddress from "../groups/FormGroupAddress";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import { Result } from "../../../gql/schema/types";
import FormActions from "../form/FormActions";
import { Asserts, object } from "yup";
import { FormikHelpers } from "formik";
import GroupedForm from "../form/GroupedForm";
import { WithTermsOfServiceSchema } from "../groups/TermsOfServiceAgreement";
import getApolloErrorMessage from "../../../gql/getApolloErrorMessage";
import { GENERIC_ERROR } from "../../../constants/strings";
import { makeStyles } from "@material-ui/styles";
import BackArrowButton from "../../button/BackArrowButton";

const groups = [
  {
    key: "kiosk-pin",
    label: "Kiosk PIN",
    Component: FormGroupEnterPIN,
  },
  {
    key: "address",
    label: "Address",
    Component: FormGroupAddress,
    ComponentProps: {
      message: `Please provide your address information below. This is where
                    we will mail replacement or lost cards that have been returned to the
                    company.`,
    },
  },
  {
    key: "online-access",
    label: "Online Access",
    Component: WithTermsOfServiceSchema(FormGroupOnlineAccess),
  },
];

interface Address {
  street: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
}

interface RegisterForOnlineAccessInput {
  cardNumber: string;
  pin: string;
  username: string;
  password: string;
  address?: Address;
}

const Mutation = gql`
  mutation ($input: RegisterForOnlineAccessInput!) {
    registerForOnlineAccess(input: $input) {
      success
      message
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
    marginBottom: 12,
  },
}));

interface RegisterForOnlineAccessVariables {
  input: RegisterForOnlineAccessInput;
}

const schema = object({
  ...FormGroupAddress.schema,
  ...FormGroupOnlineAccess.schema,
  ...FormGroupEnterPIN.schema,
});

type Form = Asserts<typeof schema>;

interface RegisterForOnlineAccessProps {
  card: string;
  onCompletion: (values: Form) => void;
}

const RegisterForOnlineAccess: VoidFunctionComponent<
  RegisterForOnlineAccessProps
> = ({ card, onCompletion }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [submit] = useMutation<
    Result<"registerForOnlineAccess">,
    RegisterForOnlineAccessVariables
  >(Mutation);

  const handleSubmit = async (
    values: Form,
    formikActions: FormikHelpers<Form>,
    formActions: FormActions
  ) => {
    const input: RegisterForOnlineAccessInput = {
      pin: values.pin,
      cardNumber: card,
      address: {
        street: values.street,
        street2: values.street2,
        city: values.city,
        state: values.state,
        zipCode: values.zipcode,
      },
      username: values.username.trim(),
      password: values.password.trim(),
    };

    try {
      const { data } = await submit({
        variables: {
          input,
        },
      });

      if (data) {
        const { success, message } = data.registerForOnlineAccess;

        if (success) {
          onCompletion(values);
          return;
        }

        if (message) {
          enqueueSnackbar(message, { variant: "error" });
        }

        const { fields } = data.registerForOnlineAccess;

        if (fields) {
          const status = Object.fromEntries(
            fields.map((f) => [f.field, f.errors])
          );

          if (!formActions.setStatus(status)) {
            enqueueSnackbar(GENERIC_ERROR, {
              variant: "error",
            });
          }
        }
      } else {
        enqueueSnackbar(GENERIC_ERROR, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(getApolloErrorMessage(error), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <BackArrowButton to="/login" />

      <div style={{ marginLeft: 32 }}>
        <Typography variant="h4" className={classes.title}>
          Register for Online Access
        </Typography>

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", marginBottom: 20 }}
          className={classes.raleway}
        >
          Please remember your credential entries to gain access to your account
          later.
        </Typography>

        <GroupedForm groups={groups} onSubmit={handleSubmit} />
      </div>
    </>
  );
};

export default RegisterForOnlineAccess;
