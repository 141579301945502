import React, { VoidFunctionComponent } from "react";
import { FieldProps } from "formik";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import getPropertyAt from "../../common/getPropertyAt";

const FormikTextField: VoidFunctionComponent<
  Omit<FieldProps<unknown>, "meta"> & Omit<TextFieldProps, "value" | "error">
> = ({ field, form, InputProps, helperText, ...rest }) => {
  const { touched, error } = form.getFieldMeta(field.name);

  const status = getPropertyAt<string>(form.status, field.name);

  const message = touched && (status || error) ? status ?? error : helperText;

  return (
    <TextField
      variant="outlined"
      margin="dense"
      fullWidth
      InputProps={{
        ...InputProps,
        endAdornment: touched && error !== undefined && (
          <InputAdornment position="end">
            <Icon color="error">
              <ErrorOutlineIcon />
            </Icon>
          </InputAdornment>
        ),
        ...field,
      }}
      error={touched && (!!error || !!status)}
      helperText={message}
      {...rest}
    />
  );
};

export default FormikTextField;
