/*
 * File: src/components/snackbar/SnackbarProvider.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  SnackbarProvider as SnackbarProviderInternal,
  SnackbarProviderProps,
} from "notistack";

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main, // Consider dark?
  },
}));

const SnackbarProvider: FunctionComponent<SnackbarProviderProps> = ({
  children,
  ...rest
}: SnackbarProviderProps) => {
  const classes = useStyles();

  return (
    <SnackbarProviderInternal
      maxSnack={3}
      classes={{ variantSuccess: classes.success }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      {...rest}
    >
      {children}
    </SnackbarProviderInternal>
  );
};

export default SnackbarProvider;
