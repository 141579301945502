/*
 * File: src/validation/schema.ts
 * Notes:
 *   > ...
 */

import { string, StringSchema } from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject, Maybe } from "yup/lib/types";
import { CUSTOMER_SERVICE_NUMBER } from "../constants/strings";

const REQUIRED = "This field is required.";

export const FirstNameSchema = string().required(REQUIRED).max(100).default("");

export const LastNameSchema = string().required(REQUIRED).max(100).default("");

// NOTE: See https://huddle.zendesk.com/hc/en-us/articles/360001092034-Special-characters-not-supported-in-email-addresses- for reference->
// list of supported/unsupported characters used in email and username schemas.

export const EmailRegex =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const EmailSchema = string()
  .required(REQUIRED)
  .matches(EmailRegex, "Please enter a valid email.")
  .max(320)
  .default("");

export const ConfirmEmailSchema = string()
  .required(REQUIRED)
  .test("match", "Email doesn't match", function (value) {
    return value === this.parent.email;
  })
  .default("");

export const PhoneNumberSchema = string()
  .required(REQUIRED)
  .matches(/^\([1-9]\d{2}\) \d{3}-\d{4}$/, "Please enter a valid phone number.")
  .default("");

export const PinSchema = string()
  .required(REQUIRED)
  .matches(/^\d{4}$/, "Please enter a valid 4-digit PIN.")
  .default("");

export const UsernameSchema = string()
  .required(REQUIRED)
  .min(6, "Must be 6 to 20 characters")
  .max(20, "Must be 6 to 20 characters")
  .matches(/^[a-zA-Z0-9.~!$%^&*_=+}{'?-]*$/, "Please enter a valid username")
  .default("");

export const PasswordSchema = string()
  .required(REQUIRED)
  .min(
    6,
    "Must be 6 to 20 characters and contain at least one number and letter"
  )
  .max(
    40,
    "Must be 6 to 40 characters and contain at least one number and letter"
  )
  .matches(
    /^(?=.*[a-zA-Z])(?=.*[0-9])/,
    "Must be 6 to 40 characters and contain at least one number and letter"
  )
  .test("test", "Cannot be the same as your username.", function (value) {
    return value !== this.parent.username;
  })
  .default("");

export const ConfirmPasswordSchema = string()
  .required(REQUIRED)
  .test("test", "Password doesn't match", function (value) {
    return value === this.parent.password;
  })
  .default("");

export const SecurityResponseSchema = string().required(REQUIRED).default("");

export const AccountUsageSchema = string()
  .required(REQUIRED)
  .default("PERSONAL");

export const OrganizationSchema = string()
  .test("test", "This field is required.", function (value) {
    return this.parent.usage === "PERSONAL"
      ? true
      : value != null && value.length > 0;
    // : value === undefined || value.length > 0;
  })
  .default("");

export const AddressSchema = string()
  .test(
    "D",
    "The address you entered is missing an apartment or suite number. Please double check your input.",
    function () {
      return this.parent.validated !== "D";
    }
  )
  .test(
    "S",
    "The address you entered has an invalid apartment or suite number. Please double check your input.",
    function () {
      return this.parent.validated !== "S";
    }
  )
  .test(
    "N",
    `We could not validate the address you entered. Please double check your input. If you are unable to validate your address, please call CLYNK's customer service line at ${CUSTOMER_SERVICE_NUMBER}.`,
    function () {
      return this.parent.validated !== "N";
    }
  )
  .required(REQUIRED)
  .default("");

export const StreetSchema = string()
  .test(
    "D",
    "The address you entered is missing an apartment or suite number. Please double check your input.",
    function () {
      return this.parent.validated !== "D";
    }
  )
  .test(
    "S",
    "The address you entered has an invalid apartment or suite number. Please double check your input.",
    function () {
      return this.parent.validated !== "S";
    }
  )
  .required(REQUIRED)
  .default("");

export const Street2Schema = string().max(64).default("");

// .oneOf(["United States", "Canada"] as const) is broken, need to cast :(
export const CountrySchema = string()
  .oneOf(["United States", "Canada"])
  .default("United States") as StringSchema<
  string,
  AnyObject,
  "United States" | "Canada"
>;

export const CitySchema = string().required(REQUIRED).max(28).default("");

export const ValidatedSchema = string().required(REQUIRED).equals(["Y"]);

export const StateSchema = string().required(REQUIRED).max(50).default("");

export const RegionSchema = string().required(REQUIRED).max(50).default("");

export const ZipCodeSchema = string()
  .required(REQUIRED)
  .test("test", "Please enter a valid postal code.", function (value) {
    if (value == null) {
      return false;
    }

    if (this.parent.country === "United States") {
      return value.match(/^\d{5}$/) !== undefined;
    } else {
      return value.match(/^[a-zA-Z]\d[a-zA-Z]-\d[a-zA-Z]\d/) !== undefined;
    }
  })
  .default("");

/*
 * TODO: Remove required from these (this should be an aspect
 * of the form, not the field)
 */
export const CardSchema = string().test(
  "hmm",
  "Please enter a valid card number.",
  function (value) {
    if (this.parent.hasCard === "no") {
      return true;
    }

    if (value == null || value.length === 0) {
      return true;
    }

    return value.match(/\d{9}/) !== null;
  }
);

export function Require<
  TType extends Maybe<string>,
  TContext,
  TOut extends TType
>(
  schema: StringSchema<TType, TContext, TOut>
): RequiredStringSchema<TType, TContext> {
  return schema.required(REQUIRED);
}
