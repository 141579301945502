import React, {
  ComponentType,
  FormEventHandler,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import FormGroupSpec from "../group/FormGroupSpec";
import useGroupedForm from "./useGroupedForm";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme, withStyles, StepIcon } from "@material-ui/core";
import { object, reach } from "yup";
import paths from "../../../common/paths";
import FormActions from "./FormActions";
import { FormikContext, FormikHelpers, FormikProps } from "formik";
import { FormGroupSchema } from "../group/FormGroup";
import getPropertyAt from "../../../common/getPropertyAt";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepIconText: {
    color: "white",
  },
  stepper: {
    backgroundColor: "inherit",
    padding: 0,
  },
  stepperText: {
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
    color: "#78BE43 !important",
  },
}));

const CustomStepIcon = withStyles({
  text: { fill: "white" },
})(StepIcon);

export interface GroupedFormProps<
  TForm = unknown,
  TGroups extends FormGroupSpec[] = FormGroupSpec[]
> {
  onSubmit: (
    values: TForm,
    formikActions: FormikHelpers<TForm>,
    formActions: FormActions
  ) => void;
  groups: TGroups;
  LastStepComponent?: ComponentType;
}

// interface GroupedFormStepProps {
//   index: number;
//   error: boolean;
//   label: string;
//   meta: UseGroupedForm["meta"];
//   isLastStep: boolean;
//   actions: FormActions;
//   ComponentProps?: unknown;
//   Component: ComponentType<FormGroupProps>;
// }

// const GroupedFormStep: VoidFunctionComponent<GroupedFormStepProps> = ({
//   index,
//   error,
//   label,
//   actions,
//   ComponentProps,
//   meta,
//   isLastStep,
//   Component,
// }) => {
//   const { goto } = actions;

//   const goToSelf = useCallback(() => {
//     goto(index);
//   }, [index, goto]);

//   return (
//     <Step completed={index < meta.step}>
//       <StepLabel
//         error={error}
//         style={{ cursor: "pointer" }}
//         StepIconComponent={CustomStepIcon}
//         onClick={goToSelf}
//       >
//         {label}
//       </StepLabel>

//       <StepContent>
//         <Component
//           {...ComponentProps}
//           actions={actions}
//           isFirstStep={meta.step === 0}
//           isLastStep={isLastStep}
//         />
//       </StepContent>
//     </Step>
//   );
// };

function GroupedForm<
  TForm = unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TGroups extends FormGroupSpec<FormGroupSchema, any, any>[] = FormGroupSpec[]
>({ groups, onSubmit }: GroupedFormProps<TForm, TGroups>): ReactElement {
  const { formik, actions, meta } = useGroupedForm({
    groups,
    onSubmit,
  });

  const classes = useStyles();

  const {
    errors: formikErrors,
    touched: formikTouched,
    status: formikStatus,
  } = formik;

  const schemas = useMemo(() => {
    return groups.map((group, index) => ({
      index,
      schema: object(group.Component.schema),
    }));
  }, [groups]);

  const groupCount = groups.length;

  const errors = useMemo(() => {
    const remaining = [...schemas];

    const groupHasError = Array<boolean>(groupCount).fill(false);

    for (const path of paths(formikTouched)) {
      const hasError = getPropertyAt(formikErrors, path);

      if (!hasError && (!formikStatus || !formikStatus[path])) {
        continue;
      }

      for (let i = remaining.length - 1; i >= 0; --i) {
        const { schema, index } = remaining[i];

        try {
          reach(schema, path);
        } catch {
          continue;
        }

        groupHasError[index] = true;

        remaining.splice(i, 1);

        break;
      }

      if (remaining.length === 0) {
        break;
      }
    }

    return groupHasError;
  }, [groupCount, schemas, formikErrors, formikStatus, formikTouched]);

  const { submit } = actions;

  const handleSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      submit();
    },
    [submit]
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormikContext.Provider value={formik as FormikProps<TForm>}>
        <Stepper
          className={classes.stepper}
          activeStep={meta.step}
          nonLinear
          orientation="vertical"
        >
          {groups.map(({ key, label, Component, ComponentProps }, index) => {
            return (
              <Step completed={index < meta.step} key={key}>
                <StepLabel
                  error={errors[index]}
                  style={{ cursor: "pointer" }}
                  StepIconComponent={CustomStepIcon}
                  onClick={() => {
                    actions.goto(index);
                  }}
                  classes={{
                    label: classes.stepperText,
                    active: classes.stepperText,
                  }}
                >
                  {label}
                </StepLabel>

                <StepContent>
                  <Component
                    {...ComponentProps}
                    actions={actions}
                    isFirstStep={meta.step === 0}
                    isLastStep={meta.step === groups.length - 1}
                  />
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </FormikContext.Provider>
    </form>
  );
}

export default GroupedForm;
