import React, { VoidFunctionComponent } from "react";
import {
  Dialog,
  // DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  // useMediaQuery,
  // useTheme,
  Typography,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { CUSTOMER_SERVICE_NUMBER } from "../../constants/strings";
import { DialogProps } from "./Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface BadAddressProps extends DialogProps {
  message: string;
}

const useStyles = makeStyles(() => ({
  title: {
    background: "#133C55",
    color: "white",
    fontFamily: "Doughy-Regular !important",
    fontSize: "1.6em",
    padding: "16px 24px",
  },
  content: {
    background: "#133C55",
    color: "white",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-start",
    background: "#133C55",
    color: "white",
  },
  text: {
    color: "white",
  },
}));

const checkMessage = (message: string): string => {
  if (message === "This field is required.")
    return "We could not validate the address you entered. Please double check your input.";

  return message;
};

const BadAddress: VoidFunctionComponent<BadAddressProps> = ({
  message,
  open,
  onClose,
  ...rest
}) => {
  // const theme = useTheme();
  const classes = useStyles();

  // const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={open}
      // fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      {...rest}
    >
      {/* <DialogTitle className={classes.title}>Invalid Address</DialogTitle> */}
      <Typography className={classes.title}>Invalid Address</Typography>

      <DialogContent className={classes.content}>
        <Typography>{checkMessage(message)}</Typography>
        <br></br>
        <FormHelperText className={classes.text}>
          Need help? Call CLYNK's customer service line at{" "}
          <Box component="span" fontWeight="fontWeightBold">
            {CUSTOMER_SERVICE_NUMBER}
          </Box>
          .
        </FormHelperText>
      </DialogContent>

      <DialogActions className={classes.footer}>
        <Button onClick={onClose} className={classes.text}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BadAddress;
