import React, { FunctionComponent, VoidFunctionComponent } from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core";
import { useFormikContext } from "formik";
import ProgressButton, {
  ProgressButtonProps,
} from "../../button/ProgressButton";
import FormActions from "../form/FormActions";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontFamily: "Raleway-Regular !important",
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "#133C55 !important",
    "&.$Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26) !important",
    },
    borderRadius: 10,
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepIconText: {
    color: "white",
  },
  stepper: {
    backgroundColor: "inherit",
    padding: 0,
    fontFamily: "Raleway-Regular !important",
  },
}));

interface BackButtonProps extends ButtonProps {
  isFirstStep: boolean;
}

const BackButton: VoidFunctionComponent<BackButtonProps> = ({
  isFirstStep,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} disabled={isFirstStep} onClick={onClick}>
      Back
    </Button>
  );
};

interface NextButtonProps extends ProgressButtonProps {
  isLastStep: boolean;
}

const NextButton: VoidFunctionComponent<NextButtonProps> = ({
  isLastStep,
  loading,
  disabled,
  ...rest
}) => {
  const classes = useStyles();

  const formik = useFormikContext();

  const text = isLastStep ? "Submit" : "Continue";

  return (
    <ProgressButton
      color="primary"
      variant="contained"
      type="submit"
      className={classes.button}
      loading={loading || formik.isSubmitting}
      disabled={disabled || formik.isSubmitting}
      LinearProgressProps={{ color: "secondary" }}
      {...rest}
    >
      {text}
    </ProgressButton>
  );
};

export interface FormGroupContentProps {
  actions: FormActions;
  isFirstStep: boolean;
  isLastStep: boolean;
  NextButtonProps?: ProgressButtonProps;
  BackButtonProps?: ButtonProps;
}

const FormGroupContent: FunctionComponent<FormGroupContentProps> = ({
  children,
  actions,
  isFirstStep,
  isLastStep,
  NextButtonProps,
}) => {
  const classes = useStyles();

  return (
    <>
      {children}

      <div className={classes.actionsContainer}>
        <BackButton isFirstStep={isFirstStep} onClick={actions.back} />
        <NextButton isLastStep={isLastStep} {...NextButtonProps} />
      </div>
    </>
  );
};

export default FormGroupContent;
