/*
 * File: src/components/dialogs/TermsOfService.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

export interface TermsOfServiceProps extends Pick<DialogProps, "open"> {
  onClose: (value: boolean) => void;
}

const TermsOfService: VoidFunctionComponent<TermsOfServiceProps> = ({
  open,
  onClose,
}) => {
  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Terms of Service"}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">CLYNK agrees to:</Typography>

        <ul>
          <Typography component="li">Make available recyclable bags</Typography>
          <Typography component="li">
            Provide identification tags for bags
          </Typography>
          <Typography component="li">Provide account cards</Typography>
          <Typography component="li">Process containers for credit</Typography>
          <Typography component="li">
            Post value of containers to account within 2 business days
          </Typography>
          <Typography component="li">
            Provide customer service/account maintenance hotline staffed
            Mon-Fri, 9am to 5pm (except holidays)
          </Typography>
        </ul>

        <Typography variant="subtitle1">Customer agrees to:</Typography>

        <ul>
          <Typography component="li">
            Use only CLYNK bags for returning their redeemable beverage
            containers
          </Typography>
          <Typography component="li">
            Not crush cans or take labels off containers; CLYNK scanners must be
            able to read barcodes
          </Typography>
          <Typography component="li">
            Place CLYNK Bag Tags associated with your account on every bag that
            you drop off at a CLYNK location
          </Typography>
          <Typography component="li">
            Drop any tagged bags at CLYNK locations thru the appropriate CLYNK
            self-serve drop door and scan each bag
          </Typography>
          <Typography component="li">
            Drop only in-state purchased redeemable beverage containers for
            credit to your CLYNK account
          </Typography>
          <Typography component="li">
            Drop CLYNK bags weighing no more than 20 pounds or less
          </Typography>
          <Typography component="li">
            Drop bags that are intact and tied securely at top with no
            containers visible
          </Typography>
          <Typography component="li">
            Print payment slips from the CLYNK kiosk
          </Typography>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose(false);
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            onClose(true);
          }}
          color="primary"
          variant="contained"
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsOfService;
