import toPath from "lodash/toPath";

export interface Nested<T> {
  [key: string]: Nested<T> | T | undefined;
}

const getPropertyAt = <T>(
  object: Nested<T>,
  key: string,
  offset = 0
): T | undefined => {
  const path = toPath(key);

  let it: Nested<T> | T | undefined = object;

  while (it && offset < path.length) {
    it = (it as Nested<T>)[path[offset++]];
  }

  return it as T | undefined;
};

export default getPropertyAt;
