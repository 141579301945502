/*
 * File: src/common/formatNumber.ts
 * Notes:
 *   > ...
 */

const formatNumber = (
  value: number,
  fractionalDigits: number,
  removeTrailingZeros = false
): string => {
  const str = value
    .toFixed(fractionalDigits)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  return removeTrailingZeros ? str.replace(/\.0+$/, "") : str;
};

export default formatNumber;
