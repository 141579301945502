/*
 * File: src/routes/create-account/CreateAccount.tsx
 * Notes:
 *   > ...
 */

import React, {
  useState,
  useEffect,
  FunctionComponent,
  VoidFunctionComponent,
} from "react";
import {
  Route,
  Prompt,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import PageContent from "../../../components/page/PageContent";
import CreateAccount from "../../../components/signup/forms/CreateAccount";
import Welcome from "./Welcome";
import EnterCard from "./EnterCard";
import { ContainerProps } from "@material-ui/core/Container";
import { PageHeaderProps } from "../../../components/page/PageHeader";

const Internal: FunctionComponent = () => {
  const history = useHistory();

  const [completed, setCompleted] = useState(false);

  const state = history.location.state;

  if (!state || state.card === undefined) {
    return <Redirect to="/create-account/enter-card" />;
  }

  const onCompletion = () => {
    setCompleted(true);

    history.replace("/create-account/welcome", {
      card: history.location.state?.card,
    });
  };

  return (
    <>
      <Prompt
        message="Are you sure you want to leave this form?"
        when={!completed}
      />
      <CreateAccount
        onCompletion={onCompletion}
        card={history.location.state?.card}
      />
    </>
  );
};

const Wrapper: FunctionComponent<ContainerProps> = ({ children }) => {
  return <PageContent maxWidth="lg">{children}</PageContent>;
};

export interface RouterProps {
  setState: (value: {
    hero: { title: string; color: PageHeaderProps["color"] };
  }) => void;
}

const Router: VoidFunctionComponent<RouterProps> = ({ setState }) => {
  const match = useRouteMatch();

  useEffect(() => {
    document.title = "Create an Account - Clynk";
    setState({ hero: { title: "Create an Account", color: "primary" } });
  }, [setState]);

  if (!match) {
    // Consider just <></>
    return <div></div>;
  }

  return (
    <>
      <Route exact path="/create-account">
        <Wrapper>
          <Internal />
        </Wrapper>
      </Route>

      <Route exact path="/create-account/enter-card">
        <Wrapper>
          <EnterCard />
        </Wrapper>
      </Route>

      <Route exact path="/create-account/welcome">
        <Wrapper>
          <Welcome />
        </Wrapper>
      </Route>
    </>
  );
};

export default Router;
