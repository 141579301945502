/*
 * File: src/components/signup/groups/FormGroupOnlineAccess.tsx
 * Notes:
 *    > ...
 */

import React, { VoidFunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Theme, useMediaQuery } from "@material-ui/core";
import { createFormGroup, FormGroupProps } from "../group/FormGroup";
import {
  UsernameSchema,
  PasswordSchema,
  ConfirmPasswordSchema,
} from "../../../validation/schema";
import FormGroupContent from "../group/FormGroupContent";
import FormikField from "../../inputs/FormikField";
import FormikTextField from "../../inputs/FormikTextField";
import TermsOfServiceAgreement from "./TermsOfServiceAgreement";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
}));

const FormGroupOnlineAccess: VoidFunctionComponent<FormGroupProps> = ({
  actions,
  isFirstStep,
  isLastStep,
}) => {
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <FormGroupContent
      actions={actions}
      isFirstStep={isFirstStep}
      isLastStep={isLastStep}
    >
      <Grid container direction={matches ? "row" : "row-reverse"}>
        <Grid
          container
          wrap="nowrap"
          direction="column"
          justifyContent="flex-start"
          spacing={1}
          xs={12}
          sm={6}
        >
          <Grid item>
            <Typography gutterBottom className={classes.raleway}>
              Your username and password will be used for logging into the
              website.
            </Typography>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <FormikField
                  component={FormikTextField}
                  name="username"
                  label="Username"
                  InputProps={{
                    autoFocus: true,
                  }}
                  helperText="Must be between 6 and 20 characters."
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              spacing={1}
            >
              <Grid item xs={12}>
                <FormikField
                  component={FormikTextField}
                  name="password"
                  label="Password"
                  InputProps={{
                    type: "password",
                  }}
                  helperText="Must be 6 to 40 characters and contain at least one number and letter."
                />
              </Grid>

              <Grid item xs={12}>
                <FormikField
                  component={FormikTextField}
                  name="confirmPassword"
                  label="Confirm Password"
                  InputProps={{
                    name: "confirmPassword",
                    type: "password",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {matches && (
          <Grid
            style={{ paddingLeft: 16 }}
            item
            xs={12}
            sm={6}
            direction="column"
          >
            <Paper variant="outlined" style={{ padding: 20 }}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", marginBottom: 16 }}
                className={classes.raleway}
              >
                Benefits of Online Access
              </Typography>
              <Typography
                component="li"
                variant="subtitle1"
                className={classes.raleway}
              >
                View your balance from anywhere
              </Typography>
              <Typography
                component="li"
                variant="subtitle1"
                className={classes.raleway}
              >
                Set donation preferences
              </Typography>
              <Typography
                component="li"
                variant="subtitle1"
                className={classes.raleway}
              >
                Edit account details
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>

      {isLastStep && <TermsOfServiceAgreement />}
    </FormGroupContent>
  );
};

export default createFormGroup(FormGroupOnlineAccess, {
  username: UsernameSchema,
  password: PasswordSchema,
  confirmPassword: ConfirmPasswordSchema,
});
