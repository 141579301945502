/*
 * File: src/components/dashboard/dialogs/DonateFunds/ConfirmDonation.tsx
 * Notes:
 *   > ...
 */

import React, { useCallback, VoidFunctionComponent } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { DonateFundsStepProps } from "./DonateFundsStepProps";
import formatCurrency from "../../../../common/formatCurrency";
import ProgressButton from "../../../button/ProgressButton";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
    fontSize: "1.6em",
  },
  text: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
  cancelButton: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  continueButton: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
    fontWeight: 600,
    textTransform: "capitalize",
  },
}));

const ConfirmDonation: VoidFunctionComponent<DonateFundsStepProps> = ({
  setStep,
  queryData,
  formik: { submitForm, values, isSubmitting },
}) => {
  const classes = useStyles();
  const handleSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const handleBack = () => {
    setStep(0);
  };

  const member = queryData?.clynkToGiveMember;

  const amount = formatCurrency(Number(values.amount.replace(",", "")) * 100);

  return (
    <>
      <DialogTitle disableTypography className={classes.title}>
        Confirm Donation
      </DialogTitle>

      <DialogContent className={classes.text}>
        Please confirm your donation of ${amount} to{" "}
        {member?.organization || "this organization."}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleBack} className={classes.cancelButton}>
          Back
        </Button>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isSubmitting}
          loading={isSubmitting}
          LinearProgressProps={{ color: "secondary" }}
          className={classes.continueButton}
        >
          Confirm
        </ProgressButton>
      </DialogActions>
    </>
  );
};

export default ConfirmDonation;
