import React, { ComponentProps, ComponentType, ReactElement } from "react";
import { FieldProps } from "formik";
import { Field as FormikFieldInternal } from "formik";

export interface FieldAttributes<
  TType,
  TProps extends FieldProps<TType>,
  TComponent extends ComponentType<TProps>
> {
  name: string;
  component: TComponent;
}

const FormikField = <T, P extends FieldProps<T>, C extends ComponentType<P>>(
  props: Omit<ComponentProps<C>, keyof FieldProps<T>> & FieldAttributes<T, P, C>
): ReactElement<T> => {
  return <FormikFieldInternal {...props} />;
};

export default FormikField;
