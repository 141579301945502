/*
 * File: src/components/dashboard/dialogs/CancelBagDonation/CancelBagDonation.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent, useCallback, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { BagDonation } from "../../../gql/schema/types";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { useCancelBagDonationMutation } from "../../../gql/schema/mutations/CancelBagDonation";
import {
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ProgressButton from "../../button/ProgressButton";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  leaveButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const Query = gql`
  query ($id: BagDonationId!) {
    bagDonation(id: $id) {
      id
      event {
        id
        name
        owner {
          id
          organization
        }
        startsOn
        endsOn
      }
    }
  }
`;

interface QueryData {
  bagDonation: BagDonation;
}

interface QueryVariables {
  id: string;
}

export interface CancelBagDonationProps
  extends Omit<DialogProps, "children" | "onClose" | "open"> {
  onClose: (
    event?: Parameters<NonNullable<DialogProps["onClose"]>>[0],
    reason?: Parameters<NonNullable<DialogProps["onClose"]>>[1]
  ) => void;
  bagDonationId: string | null;
}

const CancelBagDonation: VoidFunctionComponent<CancelBagDonationProps> = ({
  onClose,
  bagDonationId,
  ...rest
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [cancel, { loading }] = useCancelBagDonationMutation();
  const [query, { data }] = useLazyQuery<QueryData, QueryVariables>(Query);

  useEffect(() => {
    if (bagDonationId != null) {
      query({
        variables: {
          id: bagDonationId,
        },
      });
    }
  }, [bagDonationId, query]);

  const handleSubmit = useCallback(async () => {
    if (data === undefined || bagDonationId === null) {
      return;
    }

    try {
      const { data: cancelData } = await cancel({
        variables: {
          id: bagDonationId,
        },
      });

      const result = cancelData?.cancelBagDonation;

      if (!result) {
        enqueueSnackbar("Bag donation event unenrollment failed.", {
          variant: "error",
        });
        return;
      }

      const { success, message } = result;

      if (success) {
        onClose();

        enqueueSnackbar(
          message || `You are no longer enrolled in this bag donation event.`,
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(message || "Bag donation event unenrollment failed.", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Bag donation event unenrollment failed.", {
        variant: "error",
      });
    }
  }, [data, cancel, bagDonationId, enqueueSnackbar, onClose]);

  return (
    <Dialog
      {...rest}
      open={bagDonationId !== null}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown={loading}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return loading;
        }

        return onClose(event, reason);
      }}
    >
      <DialogTitle>Are you sure you want to unenroll?</DialogTitle>

      <DialogContent>
        <Typography gutterBottom>
          By unenrolling, proceeds from your bags will no longer go towards this
          bag donation event.
        </Typography>

        <Typography variant="subtitle2">
          * Should you change your mind, you can always enroll again at a later
          date.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>

        <ProgressButton
          variant="contained"
          className={classes.leaveButton}
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
          LinearProgressProps={{ color: "secondary" }}
        >
          Unenroll
        </ProgressButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelBagDonation;
