/*
 * File: src/routers/page/Router.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import SnackbarProvider from "../../components/snackbar/SnackbarProvider";
import PageHeader, { PageHeaderProps } from "../../components/page/PageHeader";
import light from "../../theme/page/light";
import { ThemeProvider } from "@material-ui/core";
import Login from "./login/Login";
import CreateAccountRouter from "./create-account/Router";
import RegisterForOnlineAccessRouter from "./register/Router";
import { SessionManager } from "../../gql/session";

// TODO - Consider lazy loading routes with Suspense
const Router: VoidFunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useState({
    hero: {
      title: "",
      color: "secondary" as PageHeaderProps["color"],
    },
  });

  useEffect(() => {
    if (
      /^\/login\/?$/.test(location.pathname) &&
      SessionManager.instance.active !== null
    ) {
      console.log(SessionManager.instance);
      history.push("/dashboard");
    }
  }, [history, location.pathname]);

  return (
    <ThemeProvider theme={light}>
      <CssBaseline />

      <SnackbarProvider>
        {/* <PageHeader title={state.hero.title} color={state.hero.color} /> */}

        <Switch>
          <Route exact path="/:path(login|activate|remove-contact-details)">
            <Login setState={setState} />
          </Route>

          <Route path="/create-account">
            <CreateAccountRouter setState={setState} />
          </Route>

          <Route path="/register">
            <RegisterForOnlineAccessRouter setState={setState} />
          </Route>
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Router;
