import { ApolloError } from "@apollo/client";
import { GENERIC_ERROR } from "../constants/strings";

const getApolloErrorMessage = (error: unknown): string => {
  if (error instanceof ApolloError) {
    const { networkError } = error;

    if (networkError) {
      if ("statusCode" in networkError) {
        if (networkError.statusCode !== 500) {
          if ("result" in networkError) {
            const errors = networkError.result?.errors;

            if (errors && errors.length > 0) {
              if (errors[0].message) {
                return errors[0].message;
              }
            }
          }
        }
      }
    }
  }

  return GENERIC_ERROR;
};

export default getApolloErrorMessage;
