import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";

import { ReactElement, useState } from "react";
import { useQuery } from "@apollo/client";
import MOST_RECENT_LOGIN from "../../gql/schema/queries/MostRecentLogin";

interface IBannerProps {
  isBannerActive: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    marginLeft: 280,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 64,
    },
  },
}));

export default function Banner(bannerProps: IBannerProps): ReactElement {
  const [hasBannerBeenDismissed, setHasBannerBeenDismissed] = useState(false);

  interface QueryData {
    mostRecentLogin: Date;
  }

  const { data } = useQuery<QueryData>(MOST_RECENT_LOGIN);

  const MostRecentLogin = new Date(data?.mostRecentLogin ?? "");

  const UpdatedDate = new Date(
    process.env.REACT_APP_PRIVACY_POLICY_UPDATE_DATE ?? "2024-11-20" // Use placeholder date if date not given
  );

  const hasUserSeenUpdate = MostRecentLogin
    ? MostRecentLogin > UpdatedDate
    : false;

  const classes = useStyles();

  function handleClick() {
    setHasBannerBeenDismissed(true);
  }

  const bannerString = process.env.REACT_APP_PRIVACY_POLICY_STRING;

  return (
    <div className={classes.banner}>
      {bannerProps.isBannerActive &&
        !hasBannerBeenDismissed &&
        !hasUserSeenUpdate && (
          <Box
            clone
            pt={2}
            pr={1}
            pb={1}
            pl={2}
            border={2}
            borderColor={"black"}
          >
            <Paper elevation={0}>
              <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item>
                  <Typography>{bannerString}</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button color="primary" onClick={handleClick}>
                    Accept
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )}
    </div>
  );
}
