/*
 * File: src/routes/register/Register.tsx
 * Notes:
 *   > ...
 */

import React, { useState, useEffect, VoidFunctionComponent } from "react";
import {
  Route,
  Prompt,
  useHistory,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import Welcome from "./Welcome";
import UpgradeAccount from "../../../components/signup/forms/RegisterForOnlineAccess";
import PageContent from "../../../components/page/PageContent";
import EnterCard from "./EnterCard";
import { PageHeaderProps } from "../../../components/page/PageHeader";

const Internal: VoidFunctionComponent = () => {
  const history = useHistory();

  const [completed, setCompleted] = useState(false);

  const onCompletion = () => {
    setCompleted(true);
    history.replace("/register/welcome");
  };

  const card = history.location.state?.card;

  if (!card) {
    return <Redirect to="/register/enter-card" />;
  }

  return (
    <>
      <Prompt
        message="Are you sure you want to leave this form?"
        when={!completed}
      />
      <UpgradeAccount onCompletion={onCompletion} card={card} />
    </>
  );
};

const Page: VoidFunctionComponent<{ Component: React.ComponentType }> = ({
  Component,
}) => {
  return (
    <PageContent maxWidth="lg">
      <Component />
    </PageContent>
  );
};

export interface RouterProps {
  setState: (value: {
    hero: { title: string; color: PageHeaderProps["color"] };
  }) => void;
}

const Router: VoidFunctionComponent<RouterProps> = ({ setState }) => {
  const match = useRouteMatch();

  useEffect(() => {
    document.title = "Register - Clynk";

    setState({
      hero: { title: "Set up CLYNK Online Access", color: "secondary" },
    });
  }, [setState]);

  if (!match) {
    return <div></div>;
  }

  return (
    <>
      <Route exact path="/register">
        <Page Component={Internal} />
      </Route>
      <Route exact path="/register/enter-card">
        <Page Component={EnterCard} />
      </Route>
      <Route exact path="/register/welcome">
        <Page Component={Welcome} />
      </Route>
    </>
  );
};

export default Router;
