import { FieldInputProps, FormikConfig, FormikProps, useFormik } from "formik";
import { ChangeEventHandler, useCallback } from "react";

const useForm = <TForm = unknown>(
  config: Omit<FormikConfig<TForm>, "children" | "render" | "component">
): FormikProps<TForm> => {
  const {
    handleChange: oldHandleChange,
    getFieldProps: oldGetFieldProps,
    setFormikState,
    ...rest
  } = useFormik<TForm>(config);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const { name } = event.target;

      setFormikState((s) => {
        if (s.status !== undefined) {
          const { [name]: value, ...status } = s.status;

          return { ...s, status };
        }
        return s;
      });

      oldHandleChange(event);
    },
    [oldHandleChange, setFormikState]
  );

  const getFieldProps = useCallback(
    <T = unknown>(name: unknown): FieldInputProps<T> => {
      const props = oldGetFieldProps(name);

      return { ...props, onChange: handleChange };
    },
    [handleChange, oldGetFieldProps]
  );

  return { ...rest, getFieldProps, handleChange, setFormikState };
};

export default useForm;
