/*
 * File: src/state/dashboard/DashboardContext.ts
 * Notes:
 *   > ...
 */

import {
  DashboardAction,
  OpenC2GDrawer,
  OpenCancelBagDonation,
  OPEN_C2G_DRAWER,
  CLOSE_C2G_DRAWER,
  CLOSE_CANCEL_BAG_DONATION,
  OPEN_CANCEL_BAG_DONATION,
  DashboardState,
  OPEN_JOIN_BAG_DONATION_EVENT,
  OpenJoinBagDonationEvent,
  CLOSE_JOIN_BAG_DONATION_EVENT,
  OPEN_DONATE,
  OpenDonate,
  CLOSE_DONATE,
} from "./DashboardState";

export function dashboardReducer(
  state: DashboardState,
  action: DashboardAction
): DashboardState {
  switch (action.type) {
    case OPEN_C2G_DRAWER:
      return { ...state, c2gDrawerTarget: (action as OpenC2GDrawer).accountId };
    case CLOSE_C2G_DRAWER:
      return { ...state, c2gDrawerTarget: null };
    case OPEN_CANCEL_BAG_DONATION:
      return {
        ...state,
        cancelBagDonationId: (action as OpenCancelBagDonation).bagDonationId,
      };
    case CLOSE_CANCEL_BAG_DONATION:
      return { ...state, cancelBagDonationId: null };
    case OPEN_JOIN_BAG_DONATION_EVENT:
      return {
        ...state,
        joinBagDonationEventId: (action as OpenJoinBagDonationEvent)
          .bagDonationEventId,
      };
    case CLOSE_JOIN_BAG_DONATION_EVENT:
      return { ...state, joinBagDonationEventId: null };
    case OPEN_DONATE:
      return {
        ...state,
        donate: {
          ...state.donate,
          openId: state.donate.openId + 1,
          clynkToGiveMemberId: (action as OpenDonate).clynkToGiveMemberId,
        },
      };
    case CLOSE_DONATE:
      return {
        ...state,
        donate: {
          ...state.donate,
          clynkToGiveMemberId: null,
        },
      };
    default:
      return state;
  }
}
