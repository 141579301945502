/*
 * File: src/components/signup/groups/FormGroupSecurityQuestion.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { SecurityResponseSchema } from "../../../validation/schema";
import { createFormGroup, FormGroupProps } from "../group/FormGroup";
import FormGroupContent from "../group/FormGroupContent";
import FormikField from "../../inputs/FormikField";
import FormikTextField from "../../inputs/FormikTextField";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
}));

const FormGroupSecurityQuestion: VoidFunctionComponent<FormGroupProps> = ({
  actions,
  isFirstStep,
  isLastStep,
}) => {
  const question = "What street did you live on in the 3rd grade?";
  const classes = useStyles();

  return (
    <FormGroupContent
      actions={actions}
      isFirstStep={isFirstStep}
      isLastStep={isLastStep}
    >
      <Grid container direction="column" spacing={1} wrap="nowrap">
        <Grid item>
          <Typography gutterBottom className={classes.raleway}>
            {question}
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <FormikField
                name="securityResponse"
                component={FormikTextField}
                label="Answer"
                InputProps={{
                  autoFocus: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormGroupContent>
  );
};

export default createFormGroup(FormGroupSecurityQuestion, {
  securityResponse: SecurityResponseSchema,
});
