/*
 * File: src/component/gql/ApolloProvider.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import { ApolloProvider as ApolloProviderInternal } from "@apollo/client";
import useApolloClientRoot from "./client";

const ApolloProvider: FunctionComponent = ({ children, ...rest }) => {
  const client = useApolloClientRoot();

  return (
    <ApolloProviderInternal client={client} {...rest}>
      {children}
    </ApolloProviderInternal>
  );
};

export default ApolloProvider;
