/*
 * File: src/components/dashboard/sidebar/Sidebar.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from "@material-ui/core/SwipeableDrawer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useMediaQuery, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.sidebar.width || 300,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.background.paper
        : "#334150",
    color: theme.palette.type === "dark" ? "inherit" : "white",
  },
}));

export type SidebarProps = SwipeableDrawerProps;

const Sidebar: FunctionComponent<SidebarProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      PaperProps={{ className: classes.root }}
      variant={matches ? "permanent" : "temporary"}
      anchor="left"
      {...rest}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default Sidebar;
