/*
 * File: src/gql/schema/mutations/JoinBagDonationEvent.ts
 * Notes:
 *   > ...
 */

import { gql, MutationTuple, useMutation } from "@apollo/client";
import { IResult } from "../types";
import { Account, JoinBagDonationEventSuccess } from "../types";

export const JoinBagDonationEventMutation = gql`
  mutation ($input: JoinBagDonationEventInput!) {
    joinBagDonationEvent(input: $input) {
      success
      message
      ... on JoinBagDonationEventSuccess {
        bagDonation {
          id
          event {
            id
          }
        }
      }
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

export interface JoinBagDonationEventInput {
  eventId: string;
  displayName: string | null;
}

export interface JoinBagDonationEventVariables {
  input: JoinBagDonationEventInput;
}

interface Response {
  joinBagDonationEvent: IResult;
}

export const useJoinBagDonationEventMutation = (): MutationTuple<
  Response,
  JoinBagDonationEventVariables
> => {
  return useMutation<Response, JoinBagDonationEventVariables>(
    JoinBagDonationEventMutation,
    {
      update(cache, response) {
        if (!response?.data?.joinBagDonationEvent) {
          return;
        }

        const { joinBagDonationEvent: result } = response.data;

        if (!result.success) {
          return;
        }

        const success = result as JoinBagDonationEventSuccess;

        try {
          const accountData = cache.readQuery<{ account: Account }>({
            query: gql`
              query {
                account {
                  id
                }
              }
            `,
          });

          if (accountData === null) {
            return;
          }

          const accountId = accountData.account.id;

          const fragment = gql`
            fragment AccountBagDonations on Account {
              activeBagDonations {
                id
              }
            }
          `;

          const account = cache.readFragment({
            id: `Account:${accountId}`,
            fragment,
          }) as Account;

          cache.writeFragment({
            id: `Account:${accountId}`,
            fragment,
            data: {
              activeBagDonations: [
                ...account.activeBagDonations,
                success.bagDonation,
              ],
            },
          });
        } catch (error) {
          console.error(error);
        }
      },
    }
  );
};
