/*
 * File: src/components/dialogs/ConfirmAddress.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent, useState } from "react";
import {
  Dialog,
  // DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Button,
  // useMediaQuery,
  // useTheme,
  FormHelperText,
  Box,
  Typography,
} from "@material-ui/core";
import { ValidatedAddress } from "../../services/AddressValidator";
import { CUSTOMER_SERVICE_NUMBER } from "../../constants/strings";
import { DialogProps } from "./Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface ConfirmAddressProps extends DialogProps {
  country: string;
  addresses: ValidatedAddress[];
  onConfirm: (address: ValidatedAddress) => void;
}

const useStyles = makeStyles((theme) => ({
  title: {
    background: "#133C55",
    color: "white",
    fontFamily: "Doughy-Regular !important",
    fontSize: "1.6em",
    padding: "16px 24px 8px 24px",
  },
  content: {
    background: "#133C55",
    color: "white",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-start",
    background: "#133C55",
    color: "white",
  },
  text: {
    color: "white",
  },
  secondaryText: {
    color: theme.palette.primary.main,
  },
}));

const ConfirmAddress: VoidFunctionComponent<ConfirmAddressProps> = ({
  open,
  country,
  addresses,
  onConfirm,
  onClose,
}) => {
  // const theme = useTheme();
  const classes = useStyles();

  // const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [selected, setSelected] = useState(0);

  const isUS = country === "United States";

  const confirm = () => {
    onConfirm(addresses[selected]);

    setSelected(0);
  };

  return (
    <Dialog
      open={open}
      // fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          confirm();
        }
      }}
    >
      {/* <DialogTitle>Confirm Address</DialogTitle> */}
      <Typography className={classes.title}>Confirm Address</Typography>

      <DialogContent className={classes.content}>
        <Typography className={classes.secondaryText}>
          Suggested Addresses
        </Typography>
        <List>
          {addresses.map((address, index) => (
            <ListItem
              key={index}
              button
              selected={index === selected}
              onClick={() => {
                setSelected(index);
              }}
            >
              <ListItemText
                primary={
                  address.street +
                  " " +
                  (address.street2 ? address.street2 + " " : "") +
                  address.locality +
                  " " +
                  address.administrativeArea +
                  " " +
                  address.postalCode
                }
                secondary={
                  isUS
                    ? address.status === "D"
                      ? "* Requires apartment or suite number"
                      : address.status === "S"
                      ? "* Invalid apartment or suite number"
                      : undefined
                    : undefined
                }
              />
            </ListItem>
          ))}
        </List>

        <FormHelperText className={classes.text}>
          Don't see your address? Please double check your input. If you are
          unable to validate your address, please call CLYNK's customer service
          line at{" "}
          <Box component="span" fontWeight="fontWeightBold">
            {CUSTOMER_SERVICE_NUMBER}
          </Box>
          .
        </FormHelperText>
      </DialogContent>

      <DialogActions className={classes.footer}>
        <Button onClick={onClose} className={classes.text}>
          Cancel
        </Button>
        <Button onClick={confirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAddress;
