/*
 * File: src/routes/register/EnterCard.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import { gql, useApolloClient } from "@apollo/client";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CardMaskedInput from "../../../components/inputs/masks/CardMaskedInput";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CardSchema, Require } from "../../../validation/schema";
import { useSnackbar } from "notistack";
import ProgressButton from "../../../components/button/ProgressButton";
import FormikTextField from "../../../components/inputs/FormikTextField";
import { GENERIC_ERROR } from "../../../constants/strings";
import getApolloErrorMessage from "../../../gql/getApolloErrorMessage";
import BackArrowButton from "../../../components/button/BackArrowButton";

const useStyles = makeStyles((theme) => ({
  green: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  blue: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  container: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  progressButton: {
    height: 40,
    borderRadius: 12,
    fontSize: 16,
    textTransform: "capitalize",
    color: "#133C55 !important",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
  },
  text: {
    fontFamily: "Raleway-Regular !important",
    fontWeight: 500,
    color: "#133C55 !important",
  },
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
  },
  listText: {
    fontFamily: "Raleway-Regular !important",
    fontWeight: 500,
    color: "#133C55 !important",
  },
  listItem: {
    paddingBottom: 0,
  },
}));

const Query = gql`
  query ($card: String!) {
    cardRegistrationStatus(card: $card)
  }
`;

const schema = Yup.object({
  card: Require(CardSchema.default("")),
});

const EnterCard: VoidFunctionComponent = () => {
  const classes = useStyles();

  const history = useHistory();

  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: { card: "", hasCard: "yes" },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const { data } = await client.query({
          query: Query,
          variables: {
            card: values.card,
          },
          fetchPolicy: "no-cache",
        });

        if (data) {
          const { cardRegistrationStatus: status } = data;

          if (status === "EXISTING") {
            history.push({ pathname: "/register" }, { card: values.card });
          } else if (status === "INVALID") {
            formik.setFieldError("card", "Card not recognized.");
          } else if (status === "NEW") {
            history.push(
              { pathname: "/create-account" },
              { card: values.card }
            );
          } else {
            formik.setFieldError(
              "card",
              "This card has already been registered for online access."
            );
          }
        } else {
          enqueueSnackbar(GENERIC_ERROR, {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar(getApolloErrorMessage(error), {
          variant: "error",
        });
      }
    },
  });

  return (
    <>
      <BackArrowButton to="/login" />

      <form
        className={classes.container}
        onSubmit={(event) => {
          event.preventDefault();
          formik.submitForm();
        }}
        style={{ marginLeft: 32 }}
      >
        <Typography variant="h4" className={classes.title}>
          Register for Online Access
        </Typography>

        <Typography className={classes.text}>
          CLYNK online access enables you to:
        </Typography>

        <ul style={{ marginBottom: 24 }}>
          <li className={classes.listItem}>
            <span className={classes.listText}>Change your kiosk PIN</span>
          </li>
          <li className={classes.listItem}>
            <span className={classes.listText}>Check your balance</span>
          </li>
          <li className={classes.listItem}>
            <span className={classes.listText}>
              Update your account information
            </span>
          </li>
          <li className={classes.listItem}>
            <span className={classes.listText}>
              See your personal environmental impact stats
            </span>
          </li>
          <li className={classes.listItem}>
            <span className={classes.listText}>
              Donate your CLYNK funds to your favorite charities or
              organizations
            </span>
          </li>
        </ul>

        <Typography className={classes.text}>
          Enter the nine-digit card number on the back of your CLYNK card.
        </Typography>

        <div style={{ display: "flex", alignItems: "flex-start", gap: 12 }}>
          <FormikTextField
            form={formik}
            label="Card Number"
            field={formik.getFieldProps("card")}
            fullWidth={false}
            InputProps={{
              inputComponent: CardMaskedInput,
              inputProps: { inputMode: "numeric" },
            }}
            style={{ maxWidth: "100%", width: "200px", display: "block" }}
          />

          <ProgressButton
            type="submit"
            variant="contained"
            color="primary"
            className={classes.progressButton}
            LinearProgressProps={{ color: "secondary" }}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            style={{ marginTop: 7 }}
          >
            Continue
          </ProgressButton>
        </div>

        <input type="submit" style={{ display: "none" }} />
      </form>
    </>
  );
};

export default EnterCard;
