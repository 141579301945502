/*
 * File: src/components/common/CurrencyMaskedInput.tsx
 * Notes:
 *   > ...
 */

import React, {
  ForwardedRef,
  InputHTMLAttributes,
  useCallback,
  useMemo,
  VoidFunctionComponent,
} from "react";
import MaskedInput from "@clynkdotcom/react-text-mask";
import { createNumberMask } from "text-mask-addons";

const defaultMaskOptions = {
  prefix: "",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: true, // TODO: was false
  allowLeadingZeroes: false,
};

export interface CurrencyMaskedInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: ForwardedRef<HTMLInputElement>;
  mask?: Record<string, unknown>; // text-mask-addons is poorly typed :(
}

const CurrencyMaskedInput: VoidFunctionComponent<CurrencyMaskedInputProps> = ({
  inputRef,
  mask,
  ...rest
}) => {
  const currencyMask = useMemo(() => {
    return createNumberMask({
      ...defaultMaskOptions,
      ...mask,
    });
  }, [mask]);

  const ref = useCallback(
    (value: MaskedInput | null) => {
      const element = value ? (value.inputElement as HTMLInputElement) : null;

      if (inputRef) {
        if (typeof inputRef === "function") {
          inputRef(element);
        } else {
          inputRef.current = element;
        }
      }
    },
    [inputRef]
  );

  return <MaskedInput {...rest} guide={false} mask={currencyMask} ref={ref} />;
};

export default CurrencyMaskedInput;
