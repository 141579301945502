import { gql } from "@apollo/client";

const GET_ACCOUNT = gql`
  query {
    account {
      id
      cards(
        status: ACTIVE
        sort: { field: CREATED_ON, order: DESC }
        limit: 1
      ) {
        id
        number
        status
      }
      balance
      containers
      info {
        isMasterAccount
      }
      donationTotals {
        to {
          id
          organization
        }
        total
      }
      activeBagDonations {
        id
      }
    }
  }
`;

export default GET_ACCOUNT;
