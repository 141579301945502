/*
 * File: src/common/PinInput.tsx
 * Notes:
 *   > ...
 */

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  VoidFunctionComponent,
  FocusEventHandler,
  ComponentProps,
  ForwardedRef,
  forwardRef,
} from "react";
import Paper from "@material-ui/core/Paper";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PinMaskedInput from "./masks/PinMaskedInput";
import mergeRefs from "../../common/mergeRefs";

const Asterisk: VoidFunctionComponent<Omit<SvgIconProps, "children">> = (
  props
) => {
  return (
    <SvgIcon {...props}>
      <path d="M10 2h4l-.79 7.91l6.45-4.64l2 3.46L14.42 12l7.24 3.27l-2 3.46l-6.45-4.64L14 22h-4l.79-7.91l-6.45 4.64l-2-3.46L9.58 12L2.34 8.73l2-3.46l6.45 4.64L10 2z" />
    </SvgIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "auto",
    justifyContent: "space-between",
    maxWidth: "fit-content",
    "& > *:not(:first-child)": {
      marginLeft: 5,
    },
  },
  digit: {
    color: theme.palette.type === "dark" ? "currentColor" : "#58585d",
    cursor: "text",
    position: "relative",
    width: 30,
    height: 40,
    border: `1px solid ${theme.palette.divider}`,
  },
  asterisk: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  cursor: {
    position: "absolute",
    left: 3,
    right: 3,
    bottom: 3,
    height: 1,
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.text.primary : "#58585d",
  },
}));

const Digit: VoidFunctionComponent<{
  value: string | null;
  focus: boolean;
}> = ({ value, focus }) => {
  const classes = useStyles();

  const [state, setState] = useState(true);

  useEffect(() => {
    let id: number;

    if (focus) {
      id = window.setInterval(() => {
        setState((s) => !s);
      }, 500);
    }

    return () => {
      if (id) {
        window.clearInterval(id);
        setState(true);
      }
    };
  }, [focus]);

  return (
    <Paper className={classes.digit}>
      {value && <Asterisk className={classes.asterisk} />}
      {focus && state && <div className={classes.cursor} />}
    </Paper>
  );
};

export type PinInputProps = Omit<
  ComponentProps<"input">,
  "value" | "ref" | "children"
> & {
  value: string;
  ref: ForwardedRef<HTMLInputElement>;
};

const PinInput: VoidFunctionComponent<PinInputProps> = ({
  ref,
  onBlur,
  ...rest
}) => {
  const classes = useStyles();

  const innerRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    if (innerRef.current) {
      innerRef.current.focus();
    }
  }, []);

  const [focus, setFocus] = useState(false);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, [setFocus]);

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      setFocus(false);

      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur, setFocus]
  );

  return (
    <div>
      <div className={classes.container} onClick={handleClick}>
        {[0, 1, 2, 3].map((i) => (
          <Digit
            key={i}
            focus={focus && i === rest.value.length}
            value={i < rest.value.length ? rest.value[i] : null}
          />
        ))}
      </div>

      <PinMaskedInput
        type="password"
        inputRef={mergeRefs(innerRef, ref)}
        inputMode="numeric"
        style={{
          position: "fixed",
          width: 1,
          height: 1,
          right: "-1px",
          border: 0,
          margin: 0,
          padding: 0,
          opacity: 0,
        }}
        {...rest}
        onFocus={onFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default forwardRef<HTMLInputElement, Omit<PinInputProps, "ref">>(
  (props, ref) => {
    return <PinInput ref={ref} {...props} />;
  }
);
