/*
 * File: src/theme/theme.ts
 * Notes:
 *   > ...
 */

import { createTheme, responsiveFontSizes } from "@material-ui/core";
import { green, cyan } from "@material-ui/core/colors";
import createBreakpoints, {
  Breakpoint,
} from "@material-ui/core/styles/createBreakpoints";

declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    appbar: {
      height: React.CSSProperties["height"];
      backgroundColor: React.CSSProperties["backgroundColor"];
    };
    sidebar: {
      width: React.CSSProperties["width"];
      breakpoint: Breakpoint;
    };
  }
  // allow configuration using `createThemeTheme`
  interface ThemeOptions {
    appbar?: {
      height?: React.CSSProperties["height"];
      backgroundColor?: React.CSSProperties["backgroundColor"];
    };
    sidebar?: {
      width?: React.CSSProperties["width"];
      breakpoint?: Breakpoint;
    };
  }
}

const defaultBreakpoints = createBreakpoints({});

const breakpoints = createBreakpoints({
  values: {
    ...defaultBreakpoints.values,
    lg: 1140,
  },
});

let theme = createTheme({
  sidebar: {
    width: 280,
    [breakpoints.up("md")]: {
      width: 240,
    },
    [breakpoints.down("sm")]: {
      width: 300,
    },
  },
  breakpoints,
  appbar: {
    height: 48,
    backgroundColor: "#549122",
  },
  palette: {
    text: {
      primary: "#56575d",
    },
    background: {
      default: "#f3f6fc", //'"#f5f5f7",
      paper: "#fcfcfc", //"#f7f7f7",
    },
    primary: {
      ...green,
      contrastText: "#fff",
      dark: "#549122",
      main: "#79bd42",
      light: "#92d050",
    },
    secondary: {
      ...cyan,
      dark: "#4ec8d9",
      main: "#4ec8d9",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "inherit",
      },
      colorPrimary: {
        color: "#79bd42 !important",
      },
      colorSecondary: {
        color: "#4ec8d9 !important",
      },
    },
    MuiButtonBase: {
      root: {
        "-webkit-tap-highlight-color": "transparent",
        "&:focus": {
          outline: "none",
        },
        "&:visited:not(:hover).MuiButtonContainedPrimary.MuiButtonContainedSecondary !important":
          {
            color: "#fff",
          },
        "&:hover.MuiButtonContainedPrimary.MuiButtonContainedSecondary !important":
          {
            color: "#fff",
          },
      },
    },
    MuiLink: {
      root: {
        /* just to bypass FishNet... */
        "&:visited.MuiTypography-colorPrimary !important": {
          color: "#79bd42",
        },
        "&:visited.MuiTypography-colorSecondary !important": {
          color: "#4ec8d9",
        },
        "&:hover.MuiTypography-colorPrimary !important": {
          color: "#79bd42",
        },
        "&:hover.MuiTypography-colorSecondary !important": {
          color: "#4ec8d9",
        },
      },
    },
    MuiButton: {
      // This shouldn't be necessary with contrast color as long as we are using primary
      containedPrimary: {
        color: "#fff !important",
        "&:hover": {
          // color: 'inherit',
          backgroundColor: "#92d050",
          "@media (hover: none)": {
            backgroundColor: "#92d050",
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
