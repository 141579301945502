/*
 * File: src/components/dialogs/ActivateAccount.tsx
 * Notes:
 *   > ...
 */

import React, {
  useEffect,
  useState,
  useRef,
  VoidFunctionComponent,
} from "react";
import Dialog, { DialogProps } from "./Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DialogContent,
  Collapse,
  Typography,
  Button,
  LinearProgress,
  Link,
} from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { Result } from "../../gql/schema/types";
import { GENERIC_ERROR } from "../../constants/strings";
import getApolloErrorMessage from "../../gql/getApolloErrorMessage";
import { makeStyles } from "@material-ui/styles";

const ActivateOnlineAccessMutation = gql`
  mutation ($token: String!) {
    activateOnlineAccess(token: $token) {
      success
      message
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
    fontSize: "1.6em",
  },
  text: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
  textGreen: {
    fontFamily: "Raleway-Regular !important",
    color: "#78BE43 !important",
    fontWeight: 600,
  },
  continueButton: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
    fontWeight: 600,
    textTransform: "capitalize",
  },
}));

const Step1: VoidFunctionComponent<{ show: boolean }> = ({ show }) => {
  return <LinearProgress color="primary" style={{ opacity: show ? 1 : 0 }} />;
};

const Step2: VoidFunctionComponent = () => {
  const textClasses = useStyles();

  return (
    <div>
      <Typography className={textClasses.text}>
        With <span className={textClasses.textGreen}>CLYNK Online Access</span>{" "}
        you can login to your CLYNK account from your mobile phone or computer
        at <Link href="https://clynk.com">clynk.com</Link> to:
      </Typography>

      <ul>
        <Typography component="li" className={textClasses.text}>
          Check your balance on your computer or smart phone
        </Typography>
        <Typography component="li" className={textClasses.text}>
          See how many containers you’ve CLYNKed
        </Typography>
        <Typography component="li" className={textClasses.text}>
          Understand how your recycling through CLYNK has impacted the
          environment
        </Typography>
        <Typography component="li" className={textClasses.text}>
          Update your account information
        </Typography>
        <Typography component="li" className={textClasses.text}>
          Change your PIN without having to call Customer Support
        </Typography>
        <Typography component="li" className={textClasses.text}>
          Donate money from your account to local charities
        </Typography>
      </ul>
    </div>
  );
};

interface ActivateOnlineAccessVariables {
  token: string;
}

const ActivateOnlineAccess: VoidFunctionComponent<
  Omit<DialogProps, "children">
> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [activate, { loading }] = useMutation<
    Result<"activateOnlineAccess">,
    ActivateOnlineAccessVariables
  >(ActivateOnlineAccessMutation);

  const [step, setStep] = useState(0);

  const loc = useLocation();

  const params = new URLSearchParams(loc.search);

  const token = params.get("token");

  const id = useRef(0);

  const classes = useStyles();

  useEffect(() => {
    if (open && token) {
      let mounted = true;
      const requestId = ++id.current;

      (async () => {
        // Artificial wait
        await new Promise((res) => setTimeout(res, 2500));

        try {
          const { data } = await activate({ variables: { token } });

          if (mounted && id.current === requestId && data) {
            const { activateOnlineAccess } = data;

            if (activateOnlineAccess) {
              const { success, message } = activateOnlineAccess;

              if (success) {
                setStep(1);

                return;
              } else {
                enqueueSnackbar(message || GENERIC_ERROR, { variant: "error" });
              }
            } else {
              enqueueSnackbar(GENERIC_ERROR, {
                variant: "error",
              });
            }

            close();
          }
        } catch (error) {
          if (mounted && id.current === requestId) {
            enqueueSnackbar(getApolloErrorMessage(error), {
              variant: "error",
            });

            close();
          }
        }
      })();

      return () => {
        mounted = false;
      };
    }
  }, [open, token, activate, enqueueSnackbar, onClose]);

  if (!token) {
    // close()
  }

  return (
    <Dialog
      disableEscapeKeyDown={loading}
      open={open}
      fullScreen={false}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return loading;
        }

        if (onClose) {
          onClose(event, reason);
        }
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle disableTypography className={classes.title}>
        {step === 0 ? "Activating Online Access" : "Online Access Activated"}
      </DialogTitle>

      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: 1 }} />
        <Collapse in={step === 0}>
          <Step1 show={step === 0} />
        </Collapse>
        <Collapse in={step === 1}>
          <Step2 />
        </Collapse>
      </DialogContent>

      <DialogActions>
        {step > 0 && (
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            className={classes.continueButton}
          >
            Continue
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ActivateOnlineAccess;
