/*
 * File: src/components/dialogs/RemoveContactDetails.tsx
 * Notes:
 *   > ...
 */

import React, {
  useEffect,
  useState,
  useRef,
  VoidFunctionComponent,
} from "react";
import Dialog, { DialogProps } from "./Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DialogContent,
  Collapse,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { Result } from "../../gql/schema/types";
import { GENERIC_ERROR } from "../../constants/strings";
import getApolloErrorMessage from "../../gql/getApolloErrorMessage";

const RemoveContactDetailsMutation = gql`
  mutation ($token: String!) {
    removeContactDetails(token: $token) {
      success
      message
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

const Step1: VoidFunctionComponent<{ show: boolean }> = ({ show }) => {
  return <LinearProgress color="primary" style={{ opacity: show ? 1 : 0 }} />;
};

const Step2: VoidFunctionComponent = () => {
  return (
    <div>
      <Typography>Your contact preference is noted.</Typography>
    </div>
  );
};

interface RemoveContactDetailsVariables {
  token: string;
}

const RemoveContactDetails: VoidFunctionComponent<DialogProps> = ({
  open,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [removeContactDetails, { loading }] = useMutation<
    Result<"removeContactDetails">,
    RemoveContactDetailsVariables
  >(RemoveContactDetailsMutation);

  const [step] = useState(0);

  const loc = useLocation();

  const params = new URLSearchParams(loc.search);

  const token = params.get("token");

  const id = useRef(0);

  useEffect(() => {
    let mounted = true;
    const requestId = ++id.current;

    if (open && token) {
      const tryRemoveContactDetails = async () => {
        try {
          const { data } = await removeContactDetails({ variables: { token } });

          if (mounted && id.current === requestId && data) {
            const { removeContactDetails: result } = data;

            if (result) {
              const { success, message } = result;

              if (success) {
                enqueueSnackbar(
                  message || "Your contact preferences are noted.",
                  { variant: "success" }
                );
              } else {
                enqueueSnackbar(message || GENERIC_ERROR, { variant: "error" });
              }
            } else {
              enqueueSnackbar(GENERIC_ERROR, {
                variant: "error",
              });
            }

            onClose();
          }
        } catch (error) {
          if (mounted && id.current === requestId) {
            enqueueSnackbar(getApolloErrorMessage(error), {
              variant: "error",
            });

            onClose();
          }
        }
      };

      // fire and forget...
      tryRemoveContactDetails();

      return () => {
        mounted = false;
      };
    }
  }, [open, token, enqueueSnackbar, onClose, removeContactDetails]);

  if (!token) {
    // onClose()
  }

  return (
    <Dialog
      disableEscapeKeyDown={loading}
      open={open}
      fullScreen={false}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return loading;
        }

        onClose(event, reason);
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Updating Contact Preferences</DialogTitle>

      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: 1 }} />
        <Collapse in={step === 0}>
          <Step1 show={step === 0} />
        </Collapse>
        <Collapse in={step === 1}>
          <Step2 />
        </Collapse>
      </DialogContent>

      <DialogActions>
        {step > 0 && (
          <Button onClick={onClose} color="primary" variant="contained">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RemoveContactDetails;
