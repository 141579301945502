/*
 * File: src/components/common/CardMaskedInput.tsx
 * Notes:
 *   > ...
 */

import React, {
  ForwardedRef,
  InputHTMLAttributes,
  useCallback,
  VoidFunctionComponent,
} from "react";
import MaskedInput from "@clynkdotcom/react-text-mask";

export interface CardMaskedInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: ForwardedRef<HTMLInputElement>;
}

const CardMaskedInput: VoidFunctionComponent<CardMaskedInputProps> = ({
  inputRef,
  ...rest
}) => {
  const ref = useCallback(
    (value: MaskedInput | null) => {
      const element = value ? (value.inputElement as HTMLInputElement) : null;

      if (inputRef) {
        if (typeof inputRef === "function") {
          inputRef(element);
        } else {
          inputRef.current = element;
        }
      }
    },
    [inputRef]
  );

  return (
    <MaskedInput
      {...rest}
      ref={ref}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      guide={false}
    />
  );
};

export default CardMaskedInput;
