/*
 * File: src/components/common/PhoneMaskedInput.tsx
 * Notes:
 *  > ...
 */

import React, {
  ForwardedRef,
  InputHTMLAttributes,
  useCallback,
  VoidFunctionComponent,
} from "react";
import MaskedInput from "@clynkdotcom/react-text-mask";

export interface PhoneMaskedInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputRef?: ForwardedRef<HTMLInputElement>;
}

const mask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PhoneMaskedInput: VoidFunctionComponent<PhoneMaskedInputProps> = ({
  inputRef,
  ...rest
}) => {
  const ref = useCallback(
    (value: MaskedInput | null) => {
      const element = value ? (value.inputElement as HTMLInputElement) : null;

      if (inputRef) {
        if (typeof inputRef === "function") {
          inputRef(element);
        } else {
          inputRef.current = element;
        }
      }
    },
    [inputRef]
  );
  return <MaskedInput {...rest} ref={ref} mask={mask} guide={false} />;
};

export default PhoneMaskedInput;
