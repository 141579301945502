/*
 * File: src/routes/create-account/Welcome.tsx
 * Notes:
 *   > ...
 */

import { VoidFunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { Button, Link } from "@material-ui/core";
import { CUSTOMER_SERVICE_NUMBER } from "../../../constants/strings";
import BackArrowButton from "../../../components/button/BackArrowButton";

const useStyles = makeStyles((theme) => ({
  container: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(4),
    },
  },
  title: {
    marginLeft: theme.spacing(4),
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
  },
  text: {
    marginLeft: theme.spacing(4),
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
  myButtonBlue: {
    width: 200,
    height: 50,
    borderRadius: 12,
    fontSize: 16,
    textTransform: "capitalize",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
    backgroundColor: "#133C55 !important",
  },
  centeredRow: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
  },
}));

const WelcomeHasCard: VoidFunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        You Are Almost Ready to CLYNK!
      </Typography>

      <Typography className={classes.text}>
        Thank you for signing up for your CLYNK account at{" "}
        <Link href="https://clynk.com">clynk.com</Link>.
        <br />
        <br />
        You will receive your Welcome Kit in 5 to 7 days. It includes an
        introduction to CLYNK’s program, 10 personalized, barcoded bag tags, and
        a voucher for a free box of bags. If you don’t want to wait for your
        personalize bag tags, please visit your nearest Hannaford location and
        use your CLYNK membership card and 4-digit PIN to access the kiosk and
        print tags. You are now part of a large and growing group of people who
        want to recycle and redeem the clean and convenient way. We are glad you
        are here.
        <br />
        <br />
        If you need help signing up or have any questions, please call our
        amazing Customer Support team at {CUSTOMER_SERVICE_NUMBER} between 9am
        and 5pm, Monday through Friday. We are glad you are here!
      </Typography>

      <div className={classes.centeredRow}>
        <Button
          color="primary"
          variant="contained"
          className={classes.myButtonBlue}
          onClick={() => {
            window.location.href = "/how-to-clynk/get-the-app/";
          }}
        >
          Get the CLYNK App
        </Button>
      </div>
    </div>
  );
};

const WelcomeNoCard: VoidFunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Your Welcome Kit Is on Its Way!
      </Typography>

      <Typography className={classes.text}>
        Thank you for signing up for your CLYNK account at{" "}
        <Link href="https://clynk.com">clynk.com</Link>. Next, you will receive
        a verification email. Check your inbox, click the button, and you are
        finished activating your online account. If you cannot locate the email,
        check your spam folder.
        <br />
        <br />
        To sign in online or in the mobile app, you need your username and
        password.
        <br />
        <br />
        To access the kiosk, you need your CLYNK card (either the physical we
        mail you or the digital card in the mobile app) and your four-digit
        kiosk PIN.
        <br />
        <br />
        In the next 5 to 7 days, you will receive your welcome kit. It includes
        an introduction to CLYNK’s program, your unique membership card + 2 key
        fobs, 10 personalized barcoded bag tags, and a voucher for a free box of
        bags. You are now part of a large and growing group of people who want
        to recycle and redeem the clean and convenient way.
        <br />
        <br />
        If you need help signing up or have any questions, please call our
        amazing customer support team at {CUSTOMER_SERVICE_NUMBER} between 9am
        and 5pm, monday through friday. We are glad you are here!
      </Typography>

      <div className={classes.centeredRow}>
        <Button
          color="primary"
          variant="contained"
          className={classes.myButtonBlue}
          onClick={() => {
            window.location.href = "/how-to-clynk/get-the-app/";
          }}
        >
          Get the CLYNK App
        </Button>
      </div>
    </div>
  );
};

const Welcome: VoidFunctionComponent = () => {
  const history = useHistory();

  const hasCard = history.location.state?.card !== undefined;

  return (
    <>
      <BackArrowButton to="/login" />
      {hasCard ? <WelcomeHasCard /> : <WelcomeNoCard />}
    </>
  );
};

export default Welcome;
