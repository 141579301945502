/*
 * File: src/routes/create-account/EnterCard.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { gql, useApolloClient } from "@apollo/client";
import {
  makeStyles,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CardMaskedInput from "../../../components/inputs/masks/CardMaskedInput";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogAccountAlreadyExists from "../../../components/dialogs/AccountAlreadyExists";
import ProgressButton from "../../../components/button/ProgressButton";
import { useSnackbar } from "notistack";
import FormikTextField from "../../../components/inputs/FormikTextField";
import getApolloErrorMessage from "../../../gql/getApolloErrorMessage";
import { GENERIC_ERROR } from "../../../constants/strings";
import BackArrowButton from "../../../components/button/BackArrowButton";

const useStyles = makeStyles((theme) => ({
  green: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  blue: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  container: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  text: {
    fontFamily: "Raleway-Regular !important",
    fontWeight: 500,
    color: "#133C55 !important",
  },
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
  },
  progressButton: {
    height: 40,
    borderRadius: 12,
    fontSize: 16,
    textTransform: "capitalize",
    color: "#133C55 !important",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
    marginTop: 16,
  },
}));

const Query = gql`
  query ($card: String!) {
    cardRegistrationStatus(card: $card)
  }
`;

const schema = Yup.object({
  hasCard: Yup.string().required(),
  card: Yup.string()
    .notRequired()
    .default("")
    .test("valid", "Please enter a valid card number.", function (value) {
      if (this.parent.hasCard === "no") {
        return true;
      }

      if (value == null) {
        return true;
      }

      return value.match(/\d{9}/) !== null;
    }),
});

const EnterCard: VoidFunctionComponent = () => {
  const classes = useStyles();

  const history = useHistory();

  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    showAccountAlreadyExistsDialog: false,
  });

  const formik = useFormik({
    initialValues: { card: "", hasCard: "no" },
    validationSchema: schema,
    onSubmit: async (values, actions) => {
      if (values.hasCard === "yes") {
        try {
          const { data } = await client.query({
            query: Query,
            variables: {
              card: values.card,
            },
            fetchPolicy: "no-cache",
          });

          if (data) {
            const { cardRegistrationStatus: status } = data;

            if (status === "EXISTING") {
              setState((s) => ({
                ...s,
                showAccountAlreadyExistsDialog: true,
              }));
            } else if (status === "INVALID") {
              actions.setFieldError("card", "Card not recognized.");
            } else if (status === "NEW") {
              history.push(
                { pathname: "/create-account" },
                { card: values.card }
              );
            } else {
              actions.setFieldError(
                "card",
                "This card has already been registered for online access."
              );
            }
          } else {
            enqueueSnackbar(GENERIC_ERROR, {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar(getApolloErrorMessage(error), {
            variant: "error",
          });
        }
      } else {
        // TODO: added card: null so we can redirect
        history.push({ pathname: "/create-account" }, { card: null });
      }
    },
  });

  const handleAccountAlreadyCreatedContinue = () => {
    const { card } = formik.values;

    history.push({ pathname: "/register" }, { card });
  };

  const handleAccountAlreadyCreatedClose = () => {
    setState((s) => ({ ...s, showAccountAlreadyExistsDialog: false }));
  };

  return (
    <>
      <BackArrowButton to="/login" />

      <form
        className={classes.container}
        onSubmit={(event) => {
          event.preventDefault();
          formik.submitForm();
        }}
        style={{ marginLeft: 32 }}
      >
        <Typography variant="h4" className={classes.title}>
          Create an Account
        </Typography>

        <Typography gutterBottom className={classes.text}>
          Welcome! We are so happy you are here. Whether you want to join us to
          support a cleaner environment, save time, give to your favorite
          charity, or simply build the funds in your CLYNK account, we are here
          to help you discover all the great reasons why CLYNK is the most
          convenient way to recycle.
          <br />
          <br />
          By establishing your account here, you will have all the credentials
          required to use the Hannaford-based kiosks to print bag tags, withdraw
          money and check your account balance as well as access your account on
          the go via your smart phone or computer.
        </Typography>

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="has-card"
            defaultValue="yes"
            {...formik.getFieldProps("hasCard")}
          >
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="I need to create an account and have a membership card mailed to me."
              classes={{ label: classes.text }}
            />

            <FormControlLabel
              value="yes"
              control={<Radio color="primary" />}
              label="I have a card and would like to create an account."
              classes={{ label: classes.text }}
            />

            <FormikTextField
              form={formik}
              field={formik.getFieldProps("card")}
              label="Card Number"
              fullWidth={false}
              InputProps={{
                inputComponent: CardMaskedInput,
                inputProps: { inputMode: "numeric" },
              }}
              style={{
                maxWidth: "100%",
                width: "300px",
                marginTop: 3,
                marginBottom: 0,
                marginLeft: 32,
                display: formik.values.hasCard === "yes" ? "" : "none",
              }}
            />
          </RadioGroup>
        </FormControl>

        <ProgressButton
          type="submit"
          variant="contained"
          color="primary"
          LinearProgressProps={{ color: "secondary" }}
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
          style={{ display: "block" }}
          className={classes.progressButton}
        >
          Continue
        </ProgressButton>

        <input type="submit" style={{ display: "none" }} />
      </form>

      <DialogAccountAlreadyExists
        maxWidth="xs"
        onContinue={handleAccountAlreadyCreatedContinue}
        onClose={handleAccountAlreadyCreatedClose}
        open={state.showAccountAlreadyExistsDialog}
      />
    </>
  );
};

export default EnterCard;
