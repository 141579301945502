/*
 * File: src/components/dashboard/sidebar/SidebarProfile.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent, VoidFunctionComponent } from "react";
import { gql, useQuery } from "@apollo/client";
import { CardHolder, Account } from "../../../gql/schema/types";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import formatCurrency from "../../../common/formatCurrency";
import Skeleton from "../../utils/Skeleton";
// import Logo from "../../../media/logo.png";
import Logo from "../../../media/CLYNK_Green_2500x.png";

const Query = gql`
  query {
    cardHolder {
      id
      info {
        name {
          first
          last
        }
      }
    }
    account {
      id
      balance
      cards(
        status: ACTIVE
        sort: { field: CREATED_ON, order: DESC }
        limit: 1
      ) {
        id
        number
        status
        createdOn
      }
    }
  }
`;

interface ProfileData {
  cardHolder: CardHolder;
  account: Account;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "32px 16px 32px 16px",
    padding: "0px 16px 16px 16px",
  },
  name: {
    color: theme.palette.primary.light,
    display: "block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontFamily: "Doughy-Regular",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  grow: {
    flexGrow: 1,
  },
  raleway: {
    fontFamily: "Raleway-Regular",
  },
}));

interface SidebarProfileDataProps {
  label: string;
  loading: boolean;
}

const SidebarProfileData: FunctionComponent<SidebarProfileDataProps> = ({
  label,
  loading,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container} {...rest}>
      {loading ? (
        <>
          <Typography variant="subtitle2" className={classes.raleway}>
            {label}
          </Typography>
          <div className={classes.grow} />
          <Typography variant="subtitle2" className={classes.raleway}>
            {children}
          </Typography>
        </>
      ) : (
        <Typography
          variant="subtitle2"
          style={{ width: "100%", display: "block" }}
          className={classes.raleway}
        >
          <Skeleton />
        </Typography>
      )}
    </div>
  );
};

const SidebarProfile: VoidFunctionComponent = () => {
  const classes = useStyles();

  const { data } = useQuery<ProfileData>(Query);

  const balance = data?.account.balance || 0;

  let name = "";
  let card = "";

  if (data) {
    const { first, last } = data.cardHolder.info.name as {
      first: string;
      last: string;
    };

    name = first + " " + last;

    const activeCard = data.account.cards.find((c) => c.status === "ACTIVE");

    if (activeCard) {
      card = activeCard.number as string;
    } else {
      card = "N/A";
    }
  }

  return (
    <div className={classes.root} id="sidebar-profile">
      <div style={{ width: "100%", margin: "auto", marginBottom: 36 }}>
        <img src={Logo} alt="logo" style={{ width: "100%", height: "auto" }} />
      </div>

      <Typography
        variant="h5"
        gutterBottom
        className={classes.name}
        id="side-profile-name"
      >
        {data ? name : <Skeleton />}
      </Typography>

      <SidebarProfileData label="Card #" loading={data !== undefined}>
        {card}
      </SidebarProfileData>

      <SidebarProfileData label="Balance" loading={data !== undefined}>
        ${formatCurrency(balance)}
      </SidebarProfileData>
    </div>
  );
};

export default SidebarProfile;
