/*
 * File: src/components/dashboard/dialogs/DonateFunds/DonateFundsAmount.tsx
 * Notes:
 *   > ...
 */

import React, { useCallback, FormEvent, VoidFunctionComponent } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { DonateFundsStepProps } from "./DonateFundsStepProps";
import {
  InputAdornment,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
import CurrencyMaskedInput from "../../../inputs/masks/CurrencyMaskedInput";
import formatCurrency from "../../../../common/formatCurrency";
import FormikTextField from "../../../inputs/FormikTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(4),
    },
  },
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
    fontSize: "1.6em",
  },
  text: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
  cancelButton: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  continueButton: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
    fontWeight: 600,
    textTransform: "capitalize",
  },
}));

const DonateFundsAmount: VoidFunctionComponent<DonateFundsStepProps> = ({
  queryData,
  formik,
  setStep,
  close,
}) => {
  const classes = useStyles();

  const member = queryData?.clynkToGiveMember;
  const balance = queryData?.account.balance || 0;

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!formik.isValid) {
        formik.setFieldTouched("amount");
        return;
      }

      setStep(1);
    },
    [setStep, formik]
  );

  const handleNext = useCallback(() => {
    if (!formik.isValid) {
      formik.setFieldTouched("amount");
      return;
    }

    setStep(1);
  }, [setStep, formik]);

  return (
    <>
      <DialogTitle disableTypography className={classes.title}>
        Donate Funds
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <div className={classes.root}>
            <Typography className={classes.text}>
              Your current balance is ${formatCurrency(balance)}.
            </Typography>

            <div>
              <Typography className={classes.text}>
                How much would you like to donate to{" "}
                {member?.organization || "this organization"}?
              </Typography>

              <FormikTextField
                form={formik}
                field={formik.getFieldProps("amount")}
                label="Amount"
                autoFocus={true}
                InputProps={{
                  inputComponent: CurrencyMaskedInput,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputProps: { inputMode: "decimal", autoComplete: "off" },
                }}
                fullWidth={true}
              />
            </div>

            <div>
              <Typography className={classes.text}>
                Can we send your information to the charity so they might thank
                you and give you a receipt for tax purposes or would you like to
                remain anonymous?
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    {...formik.getFieldProps("anonymous")}
                    checked={formik.values.anonymous}
                  />
                }
                label="Remain Anonymous"
                classes={{ label: classes.text }}
              />

              <input type="submit" style={{ display: "none" }} />
            </div>
          </div>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} className={classes.cancelButton}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.continueButton}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export default DonateFundsAmount;
