/*
 * File: src/components/dialogs/ResendActivationLink.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import Dialog, { DialogProps } from "./Dialog";
import useTextHighlightStyles from "../../styles/useTextHighlightStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogContent, Typography, Collapse } from "@material-ui/core";
import ProgressButton from "../button/ProgressButton";
import { CUSTOMER_SERVICE_NUMBER } from "../../constants/strings";

const RequestNewValidationText: VoidFunctionComponent = () => {
  const textClasses = useTextHighlightStyles();

  return (
    <Typography>
      You have not yet activated Online Access. Please check your email for an
      activation link, or click the{" "}
      <span className={textClasses.textHighlightGreen}>
        Resend Activation Link
      </span>{" "}
      button below.
    </Typography>
  );
};

const SentValidationLinkText: VoidFunctionComponent = () => {
  return (
    <div>
      <Typography style={{ marginBottom: 16 }}>
        We just sent an email with your new validation link to your email
        address on record. Once you receive this email, you may use your
        username and password to login. If you do not see this email, check your
        spam folder to see if it may have gone there.
      </Typography>

      <Typography variant="body2">
        * Still can't find it? Contact Customer Service at{" "}
        {CUSTOMER_SERVICE_NUMBER}.
      </Typography>
    </div>
  );
};

export interface ResendActivationLinkProps
  extends Omit<DialogProps, "children"> {
  reactivationStep: number;
  reactivationLoading: boolean;
  handleReactivationResend: () => void;
}

const ResendActivationLink: VoidFunctionComponent<
  ResendActivationLinkProps
> = ({
  open,
  onClose,
  reactivationStep,
  reactivationLoading,
  handleReactivationResend,
}) => {
  return (
    <Dialog
      showCloseButton
      open={open}
      fullScreen={false}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>Account Activation Required</DialogTitle>

      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: 1 }} />
        <Collapse in={reactivationStep === 0} unmountOnExit>
          <RequestNewValidationText />
        </Collapse>
        <Collapse in={reactivationStep === 1} unmountOnExit>
          <SentValidationLinkText />
        </Collapse>
      </DialogContent>

      {reactivationStep === 0 ? (
        <DialogActions>
          <ProgressButton
            color="primary"
            variant="contained"
            loading={reactivationLoading}
            disabled={reactivationLoading}
            LinearProgressProps={{ color: "secondary" }}
            onClick={handleReactivationResend}
          >
            Resend Activation Link
          </ProgressButton>
        </DialogActions>
      ) : (
        <DialogActions></DialogActions>
      )}
    </Dialog>
  );
};

export default ResendActivationLink;
