/*
 * File: src/components/dialogs/ConfirmAddress.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { DialogProps } from "./Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Doughy-Regular !important",
    fontSize: "2em",
    padding: "16px 24px 8px 24px",
    color: "#78BE43",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
  },
  text: {
    color: "#133C55",
  },
  secondaryText: {
    color: theme.palette.primary.main,
  },
  link: {
    color: "#78BE43",
    textDecoration: "none",
  },
  exit: {
    color: "#133C55",
  },
}));

const VerifyCorrectWebsite: VoidFunctionComponent<DialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  const value =
    process.env.REACT_APP_CREATE_ACCOUNT_CT_POPUP === "true" ? open : false;
  const link = process.env.REACT_APP_CREATE_ACCOUNT_CT_LINK
    ? process.env.REACT_APP_CREATE_ACCOUNT_CT_LINK
    : "https://customer.ct.clynk.com/register/welcome";

  return (
    <Dialog open={value} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          Attention!
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon className={classes.exit} />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Typography className={classes.text}>
          Looking to return containers in Connecticut?
        </Typography>

        <Typography className={classes.text}>
          Click{" "}
          <a href={link} className={classes.link}>
            here
          </a>{" "}
          to sign up for a CLYNK Connecticut account.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyCorrectWebsite;
