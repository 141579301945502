export interface ValidatedAddress {
  street: string;
  street2: string | null;
  postalCode: string;
  locality: string;
  administrativeArea: string;
  status: "Y" | "S" | "D" | "N";
}

interface AddressValidationOptions {
  country: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  zipcode: string;
  candidates?: number;
}

// Updated for all address components
class AddressValidator {
  public static async validate(
    options: AddressValidationOptions
  ): Promise<ValidatedAddress[]> {
    const isUS = options.country === "United States";

    let url: string;

    const key = process.env.REACT_APP_SMARTY_STREETS_KEY;

    // Make new string or just call them all individually
    const street = encodeURIComponent(options.street);
    const street2 = encodeURIComponent(options.street2);
    const city = encodeURIComponent(options.city);
    const state = encodeURIComponent(options.state);
    const zipcode = encodeURIComponent(options.zipcode);

    const candidates = options.candidates ?? 5;

    if (isUS) {
      url = `https://us-street.api.smartystreets.com/street-address?candidates=${candidates}&street=${street}&street2=${street2}&city=${city}&state=${state}&zipcode=${zipcode}&key=${key}`;
    } else {
      const country = encodeURIComponent(options.country);

      url = `https://international-street.api.smartystreets.com/verify?country=${country}&candidates=${candidates}&address1=${street}&address2=${street2}&locality=${city}&administrative_area=${state}&postal_code=${zipcode}&key=${key}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response = (await (await fetch(url)).json()) as Array<any>;

    if (isUS) {
      return response
        .map((a): ValidatedAddress => {
          const c = a.components;

          return {
            street: a.delivery_line_1,
            street2: a.delivery_line_2 ?? null,
            postalCode: c.zipcode,
            locality: c.city_name,
            administrativeArea: c.state_abbreviation,
            status: a.analysis.dpv_match_code,
          };
        })
        .filter((address, index, self) => {
          return self.findIndex((a) => a === address) === index;
        });
    } else {
      return response
        .filter((a) => {
          const p = a.analysis.address_precision;

          return p === "Premise" || p === "DeliveryPoint";
        })
        .map(
          (a): ValidatedAddress => ({
            street: a.address1,
            street2: null,
            postalCode: a.components.postal_code,
            locality: a.components.locality,
            administrativeArea: a.components.administrative_area,
            status: "Y",
          })
        );
    }
  }
}

export default AddressValidator;
