/*
 * File: src/components/dashboard/mobile-nav/MobileNav.tsx
 * Note:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import { useLazyQuery } from "@apollo/client";
import { Account } from "../../../gql/schema/types/Account";
import GET_ACCOUNT from "../../../gql/schema/queries/Account";
import { Theme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
// import Logo from "../../../media/logo-green.png";
import Logo from "../../../media/CLYNK_Green_2500x.png";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { useFeatureFlags } from "../../../feature-flags";
import AppFeatureFlags from "../../../AppFeatureFlags";

interface MobileNavProps {
  openSidebar: () => void;
}

interface QueryData {
  account: Account;
}

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: "white",
  },
  mobileNav: {
    backgroundColor: "#2a3f54",
  },
  logoWrap: {
    flexGrow: 1,
    textAlign: "center",
    [theme.breakpoints.between("xs", "md")]: {
      margin: "8px 50px 0px auto",
    },
  },
  logo: {
    maxWidth: "160px",
    height: "100%",
    width: "100%",
  },
}));

const MobileNav: VoidFunctionComponent<MobileNavProps> = ({ openSidebar }) => {
  const classes = useStyles();

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { has: isFeatureFlagEnabled } = useFeatureFlags<AppFeatureFlags>();
  const isMobile = isFeatureFlagEnabled("cardQRCode");

  const [getAccount] = useLazyQuery<QueryData>(GET_ACCOUNT, {
    fetchPolicy: "cache-and-network",
  });

  const handleClick = () => {
    if (isMobile) {
      /* getAccount() is used as authentication check and data refresh
         - on success will update cache with new data if need be.
         - on error will utilize redirects implemented in client apollo file
      */
      getAccount();
    }
    openSidebar();
  };

  if (!matches) {
    return <></>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" className={classes.mobileNav}>
        <Toolbar>
          <IconButton className={classes.menuButton} onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <div className={classes.logoWrap}>
            <img src={Logo} alt="logo" className={classes.logo} />
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MobileNav;
