/*
 * File: src/styles/useTextHighlightStyles.ts
 * Notes:
 *   > ...
 */

import makeStyles from "@material-ui/core/styles/makeStyles";

const useTextHighlightStyles = makeStyles((theme) => ({
  fontBold: {
    fontWeight: "bold",
  },
  green: {
    color: theme.palette.primary.main,
    display: "inline",
  },
  blue: {
    color: theme.palette.secondary.main,
  },
  textHighlightGreen: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  textHighlightBlue: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
}));

export default useTextHighlightStyles;
