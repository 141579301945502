/*
 * File: src/router/page/login/Login.tsx
 * Notes:
 *   > ...
 */

import React, {
  useState,
  useEffect,
  MouseEventHandler,
  VoidFunctionComponent,
} from "react";
import Grid from "@material-ui/core/Grid";
import Button, { ButtonProps } from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "../../../components/page/PageContent";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import ProgressButton from "../../../components/button/ProgressButton";
import MuiLink from "@material-ui/core/Link";
import { useSnackbar } from "notistack";
import ForgotPassword from "../../../components/dialogs/ForgotPassword";
import ForgotUsername from "../../../components/dialogs/ForgotUsername";
import ResendActivationLink from "../../../components/dialogs/ResendActivationLink";
import {
  CUSTOMER_SERVICE_NUMBER,
  GENERIC_ERROR,
} from "../../../constants/strings";
import ActivateOnlineAccess from "../../../components/dialogs/ActivateOnlineAccess";
import RemoveContactDetails from "../../../components/dialogs/RemoveContactDetails";
import { gql, useApolloClient } from "@apollo/client";
import { PageHeaderProps } from "../../../components/page/PageHeader";
import FormikField from "../../../components/inputs/FormikField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import getApolloErrorMessage from "../../../gql/getApolloErrorMessage";
import { LoginResult, SessionManager } from "../../../gql/session";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clynkLogo from "../../../media/CLYNK_Green_2500x.png";
import BackArrowButton from "../../../components/button/BackArrowButton";

const schema = Yup.object().shape({
  username: Yup.string()
    .required("Please enter your username.")
    .max(100, "Too long."),
  password: Yup.string()
    .required("Please enter your password.")
    .max(40, "Too long."),
});

const ResendActivationLinkMutation = gql`
  mutation ($username: String!) {
    resendActivationLink(username: $username) {
      success
      message
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  logoWrap: {
    margin: theme.spacing(4, 2, 0, 2),
    display: "flex",
    justifyContent: "center",
  },
  brandText: {
    color: "#D81E5B", //theme.palette.primary.main,
    fontFamily: "Doughy-Regular !important",
    fontWeight: "bold",
    margin: theme.spacing(8, 2, 4, 2),
    textAlign: "center",
    fontSize: "2.6em !important",
  },
  logo: {
    width: "100%",
    maxWidth: "350px",
    height: "auto",
  },
  formWrap: {
    margin: theme.spacing(8, 2, 8, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    lineHeight: "150%",
  },
  gridItem: {
    marginTop: 16,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 34,
  },
  myButton: {
    width: 200,
    height: 50,
    borderRadius: 12,
    fontSize: 16,
    textTransform: "capitalize",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
    backgroundColor: "#133C55 !important",
  },
  progressButton: {
    marginTop: 16,
    marginBottom: 16,
    width: 200,
    height: 50,
    borderRadius: 12,
    fontSize: 16,
    textTransform: "capitalize",
    color: "#133C55 !important",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
  },
  linkRow: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  or: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  strike: {
    width: "100%",
    borderBottom: "1px solid #133C55",
    marginBottom: 11,
  },
  orText: {
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
    color: "#133C55 !important",
    fontSize: 18,
    padding: "0px 12px",
  },
  form: {
    marginTop: 32,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const LoginFrame: VoidFunctionComponent = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const [state, setState] = useState({
    loggedIn: false,
    showForgotUsernameDialog: false,
    showForgotPasswordDialog: false,
    showReactivationLinkDialog: false,
    reactivationStep: 0,
    reactivationLoading: false,
    userName: "",
  });

  const handleUsernameClick: MouseEventHandler = (event) => {
    event.preventDefault();
    setState((s) => ({ ...s, showForgotUsernameDialog: true }));
  };

  const handleUsernameClose = () => {
    setState((s) => ({ ...s, showForgotUsernameDialog: false }));
  };

  const handlePasswordClose = () => {
    setState((s) => ({ ...s, showForgotPasswordDialog: false }));
  };

  const handlePasswordClick: MouseEventHandler = (event) => {
    event.preventDefault();
    setState((s) => ({ ...s, showForgotPasswordDialog: true }));
  };

  const handleActivationResend = async () => {
    setState((s) => ({ ...s, reactivationLoading: true }));

    const username = state.userName.trim();

    if (state.reactivationStep === 0) {
      try {
        const { data } = await client.mutate({
          mutation: ResendActivationLinkMutation,
          variables: { username },
        });

        if (data) {
          const { success, message } = data.resendActivationLink;

          if (success) {
            setState((s) => ({
              ...s,
              reactivationLoading: false,
              reactivationStep: 1,
            }));
          } else if (
            !success &&
            message ===
              `Invalid or missing email, please call customer service at ${CUSTOMER_SERVICE_NUMBER}`
          ) {
            enqueueSnackbar(message, { variant: "error" });
            setState((s) => ({ ...s, reactivationLoading: false }));
          }
        }
      } catch (error) {
        enqueueSnackbar(getApolloErrorMessage(error), {
          variant: "error",
        });
        setState((s) => ({ ...s, reactivationLoading: false }));
      }
    }
  };

  const closeActivationDialog = () => {
    setState((s) => ({
      ...s,
      showReactivationLinkDialog: false,
      reactivationStep: 0,
    }));
  };

  const openActivationDialog = () => {
    setState((s) => ({ ...s, showReactivationLinkDialog: true }));
  };

  const params = new URLSearchParams(history.location.search);

  const redirect = params.get("redirect");
  const classes = useStyles();

  return (
    <>
      <ForgotPassword
        open={state.showForgotPasswordDialog}
        onClose={handlePasswordClose}
      />
      <ForgotUsername
        open={state.showForgotUsernameDialog}
        onClose={handleUsernameClose}
      />

      <ResendActivationLink
        open={state.showReactivationLinkDialog}
        onClose={closeActivationDialog}
        handleReactivationResend={handleActivationResend}
        reactivationStep={state.reactivationStep}
        reactivationLoading={state.reactivationLoading}
      />

      <Formik
        validationSchema={schema}
        initialValues={{ username: "", password: "" }}
        onSubmit={async (values, actions) => {
          try {
            const result = await SessionManager.instance.login(
              values.username,
              values.password
            );

            switch (result) {
              case LoginResult.SUCCESS:
                setState((s) => ({ ...s, loggedIn: true }));

                const loginInfo = {
                  origin: "/login",
                  loggedIn: true,
                };

                localStorage.setItem("loginInfo", JSON.stringify(loginInfo));

                history.push(redirect ? `/${redirect}` : "/dashboard");

                break;
              case LoginResult.INCORRECT_USERNAME_OR_PASSWORD:
                actions.setFieldError("password", "Incorrect password");
                break;
              case LoginResult.STALE_LOGIN_ATTEMPT:
                return;
              case LoginResult.USER_NOT_ACTIVATED:
                openActivationDialog();
                break;
              case LoginResult.UNKNOWN:
                enqueueSnackbar(GENERIC_ERROR, {
                  variant: "error",
                });
            }
          } catch (error) {
            let message = GENERIC_ERROR;

            if (axios.isAxiosError(error)) {
              const errors = error.response?.data?.errors;

              if (errors && errors.length > 0) {
                message = errors[0].message ?? message;
              }
            }

            enqueueSnackbar(message, {
              variant: "error",
            });
          }
        }}
      >
        {(props) => {
          return (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                // TODO: if props.isSubmitting skip & verify this doesn't allow a double submit
                if (!props.isSubmitting) {
                  props.submitForm();
                }
              }}
              className={classes.form}
            >
              <FormikField
                name="username"
                label="Username"
                component={FormikTextField}
                InputProps={{ name: "username" }}
              />

              <div className={classes.linkRow}>
                <MuiLink
                  style={{
                    display: "block",
                    marginBottom: 32,
                  }}
                  href="#"
                  onClick={handleUsernameClick}
                >
                  Forgot your username?
                </MuiLink>
              </div>

              <FormikField
                name="password"
                label="Password"
                component={FormikTextField}
                InputProps={{
                  type: "password",
                }}
              />

              <div className={classes.linkRow}>
                <MuiLink
                  style={{
                    display: "block",
                    marginBottom: 32,
                  }}
                  href="#"
                  onClick={handlePasswordClick}
                >
                  Forgot your password?
                </MuiLink>
              </div>

              <ProgressButton
                type="submit"
                variant="contained"
                color="primary"
                className={classes.progressButton}
                loading={props.isSubmitting || state.loggedIn}
                disabled={props.isSubmitting || state.loggedIn}
                LinearProgressProps={{ color: "secondary" }}
                onClick={() => {
                  setState((s) => ({ ...s, userName: props.values.username }));
                }}
              >
                Login
              </ProgressButton>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

function MyButton<C>(props: ButtonProps & { to?: string; component?: C }) {
  const { children, style, ...rest } = props;

  return (
    <Button
      color="primary"
      variant="contained"
      style={{ marginTop: 16, ...style }}
      {...rest}
    >
      {children}
    </Button>
  );
}

function OnlineAccessFrame() {
  const classes = useStyles();
  return (
    <>
      <MyButton
        component={Link}
        to="/register/enter-card"
        className={classes.myButton}
      >
        Need Online Access
      </MyButton>
    </>
  );
}

function SignUpFrame() {
  const classes = useStyles();
  return (
    <>
      <MyButton
        component={Link}
        to="/create-account"
        className={classes.myButton}
      >
        Create an Account
      </MyButton>
    </>
  );
}

export interface LoginProps {
  setState: (value: {
    hero: { title: string; color: PageHeaderProps["color"] };
  }) => void;
}

const Login: VoidFunctionComponent<LoginProps> = ({ setState }) => {
  useEffect(() => {
    document.title = "Login - Clynk";

    setState({
      hero: {
        title: "CLYNK Online Access",
        color: "secondary",
      },
    });
  }, [setState]);

  const history = useHistory();
  const match = useRouteMatch<{ path: string }>();
  const classes = useStyles();

  const handleClose = () => {
    history.replace({ pathname: "/login" });
  };

  const activate = match?.params.path === "activate";
  const removeContactDetails = match?.params.path === "remove-contact-details";

  return (
    <>
      <Container maxWidth="lg">
        <BackArrowButton useHref={true} />

        <div className={classes.logoWrap}>
          <img src={clynkLogo} alt="logo" className={classes.logo} />
        </div>

        <Typography className={classes.brandText} variant="h5">
          Hello
        </Typography>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container direction="column" spacing={4} xs={12} sm={8}>
            <Grid item>
              <LoginFrame />
            </Grid>
            <Grid item>
              <div className={classes.or}>
                <div className={classes.strike}></div>
                <div className={classes.orText}>OR</div>
                <div className={classes.strike}></div>
              </div>
            </Grid>
            <Grid item>
              <Grid item className={classes.gridItem} spacing={8}>
                <OnlineAccessFrame />
                <SignUpFrame />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>

      <ActivateOnlineAccess open={activate} onClose={handleClose} />
      <RemoveContactDetails open={removeContactDetails} onClose={handleClose} />
    </>
  );
};

export default Login;
