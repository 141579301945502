/*
 * File: src/components/dashboard/sidebar/SidebarNodeLabel.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme, { selected?: boolean }>(() => ({
  root: {
    opacity: ({ selected }) => (selected ? 1 : 0.5),
    fontWeight: ({ selected }) => (selected ? "bold" : "inherit"),
    fontFamily: "Raleway-Regular",
  },
}));

export interface SidebarNodeLabelProps {
  selected?: boolean;
}

const SidebarNodeLabel: FunctionComponent<SidebarNodeLabelProps> = ({
  children,
  selected,
  ...rest
}) => {
  const classes = useStyles({ selected });

  return (
    <Typography className={classes.root} {...rest}>
      {children}
    </Typography>
  );
};

export default SidebarNodeLabel;
