/*
 * File: src/preferences/PreferencesContext.ts
 * Notes:
 *   > ...
 */

import React from "react";
import { Preferences } from "./Preferences";

interface PreferencesContextState {
  preferences: Preferences;
}

const PreferencesContext = React.createContext<PreferencesContextState>({
  preferences: {},
});

export default PreferencesContext;
