/*
 * File: src/routers/dashboard/Splash.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useApolloClient, gql } from "@apollo/client";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SplashScreenAnimation from "../../media/Splash-Screen-Animation.gif";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    margin: "auto",
    maxWidth: "30%",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
  },
  splash: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
    margin: "auto",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    background: "#133C55",
  },
  gif: {
    maxHeight: "100vh",
    objectFit: "contain",
  },
}));

const QueryPrefetch = gql`
  query prefetch {
    onlineUser {
      id
      username
    }
    cardHolder {
      id
      info {
        name {
          first
          last
        }
        address {
          street
          street2
          city
          state
          zipCode
        }
        email
        phonenumber
      }
    }
    account {
      id
      balance
      containers
      donationTotals {
        to {
          id
          organization
        }
        total
      }
      info {
        usage
        organization
        isMasterAccount
      }
      cards(
        status: ACTIVE
        sort: { field: CREATED_ON, order: DESC }
        limit: 1
      ) {
        id
        number
        status
        createdOn
      }
      impacts {
        type
        units
      }
      subAccounts {
        id
        info {
          organization
        }
        balance
      }
      activeBagDonations {
        id
        event {
          id
          name
          owner {
            id
            organization
          }
          startsOn
          endsOn
        }
      }
    }
    bagDonationEvents {
      id
      owner {
        id
        organization
      }
      name
      startsOn
      endsOn
    }
  }
`;

export interface SplashProps {
  onPrefetchComplete: () => void;
}

const Splash: VoidFunctionComponent<SplashProps> = (props) => {
  const { onPrefetchComplete } = props;

  const classes = useStyles();
  const client = useApolloClient();

  useEffect(() => {
    let mounted = true;

    client
      .query({ query: QueryPrefetch })
      .catch(() => {
        if (!mounted) {
          return;
        }
      })
      .then((res) => {
        if (!mounted) {
          return;
        }

        if (res && res.data) {
          onPrefetchComplete();
        }
      });

    return () => {
      mounted = false;
    };
  }, [client, onPrefetchComplete]);

  return (
    <>
      <div className={classes.root}>
        <LinearProgress color="primary" />
      </div>
      <div className={classes.splash}>
        <img
          src={SplashScreenAnimation}
          alt="SPLASH SCREEN"
          className={classes.gif}
        />
      </div>
    </>
  );
};

export default Splash;
