/*
 * File: src/components/dashboard/dialogs/DonateFunds/DonateFunds.tsx
 * Notes:
 *   > ...
 */

import React, {
  useState,
  useEffect,
  VoidFunctionComponent,
  useCallback,
} from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import { FormikHelpers } from "formik";
import { gql, useLazyQuery } from "@apollo/client";
import {
  Account,
  ClynkToGiveMember,
  ResultFailed,
} from "../../../../gql/schema/types";
import DonateFundsReceipt from "./DonateFundsReceipt";
import ConfirmDonation from "./ConfirmDonation";
import DonateFundsAmount from "./DonateFundsAmount";
import { useDonateFundsMutation } from "../../../../gql/schema/mutations/DonateFunds";
import { ClynkToGiveMemberVariables } from "../../../../gql/schema/queries/ClynkToGiveMember";
import { DonateFundsForm } from "./DonateFundsForm";
import { useSnackbar } from "notistack";
import { GENERIC_DONATE_FUNDS_ERROR } from "../../../../constants/strings";
import useForm from "../../../form/useForm";

const Query = gql`
  query ($id: ClynkToGiveMemberId!) {
    account {
      id
      balance
    }
    clynkToGiveMember(id: $id) {
      id
      organization
    }
  }
`;

export interface DonateFundsProps extends Omit<DialogProps, "open"> {
  close: () => void;
  openId: number;
  clynkToGiveMemberId: string | null;
}

interface QueryData {
  account: Account;
  clynkToGiveMember: ClynkToGiveMember;
}

const StepMap = [DonateFundsAmount, ConfirmDonation, DonateFundsReceipt];

interface DonateFundsInternalProps extends Omit<DialogProps, "open"> {
  close: () => void;
  data: QueryData | undefined;
  openId: number;
  clynkToGiveMemberId: string | null;
}

const DonateFundsInternal: VoidFunctionComponent<DonateFundsInternalProps> = ({
  data,
  close,
  onClose,
  openId,
  clynkToGiveMemberId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [donate] = useDonateFundsMutation();

  const [step, setStep] = useState<number>(0);

  const handleSubmit = async (
    values: DonateFundsForm,
    actions: FormikHelpers<DonateFundsForm>
  ) => {
    try {
      const result = await donate({
        variables: {
          input: {
            to: values.to,
            anonymous: values.anonymous,
            amount: Math.round(Number(values.amount.replace(",", "")) * 100),
          },
        },
      });

      const donateFunds = result.data?.donateFunds;

      if (donateFunds) {
        const balance = donateFunds.balance;

        if (balance !== null) {
          actions.setFieldValue("balance", balance);
        }

        const { success, message } = donateFunds.result;

        if (success) {
          setStep(2);
        } else {
          const { fields } = donateFunds.result as ResultFailed;

          if (fields) {
            const status = Object.fromEntries(
              fields.map((f) => [f.field, f.errors])
            );

            actions.setStatus(status);

            if (status.amount) {
              setStep(0);
            }
          } else {
            enqueueSnackbar(message || GENERIC_DONATE_FUNDS_ERROR, {
              variant: "error",
            });
          }
        }
      } else {
        enqueueSnackbar(GENERIC_DONATE_FUNDS_ERROR, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(GENERIC_DONATE_FUNDS_ERROR, { variant: "error" });
    }
  };

  const validate = React.useCallback(
    (values: DonateFundsForm) => {
      const errors: { amount?: string } = {};

      const amount = Math.round(Number(values.amount.replace(/,/g, "")) * 100);

      if (amount <= 0) {
        errors.amount = "Invalid donation amount.";
      } else if (amount > (data?.account.balance || 0)) {
        errors.amount = "Insufficient funds.";
      }

      return errors;
    },
    [data?.account.balance]
  );

  const formik = useForm<DonateFundsForm>({
    initialValues: {
      amount: "",
      to: "",
      anonymous: false,
    },
    validate,
    onSubmit: handleSubmit,
  });

  const { resetForm } = formik;

  useEffect(() => {
    if (!clynkToGiveMemberId) {
      return;
    }

    setStep(0);

    resetForm({
      values: {
        amount: "",
        to: clynkToGiveMemberId,
        anonymous: false,
      },
      errors: {
        amount: "Invalid donation amount.",
      },
    });
  }, [openId, clynkToGiveMemberId, resetForm]);

  const handleClose: DialogProps["onClose"] = useCallback(
    (event, reason) => {
      if (reason !== "backdropClick") {
        return formik.isSubmitting;
      }

      if (onClose) {
        return onClose(event, reason);
      }
    },
    [onClose, formik.isSubmitting]
  );

  const Step = StepMap[step];

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={clynkToGiveMemberId !== null}
      disableEscapeKeyDown={formik.isSubmitting}
    >
      <Step formik={formik} queryData={data} close={close} setStep={setStep} />
    </Dialog>
  );
};

const DonateFunds: VoidFunctionComponent<DonateFundsProps> = ({
  clynkToGiveMemberId,
  ...rest
}) => {
  const [query, { data }] = useLazyQuery<QueryData, ClynkToGiveMemberVariables>(
    Query
  );

  useEffect(() => {
    if (clynkToGiveMemberId !== null) {
      query({
        variables: {
          id: clynkToGiveMemberId,
        },
      });
    }
  }, [query, clynkToGiveMemberId]);

  return (
    <DonateFundsInternal
      data={data}
      clynkToGiveMemberId={clynkToGiveMemberId}
      {...rest}
    />
  );
};

export default DonateFunds;
