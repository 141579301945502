// eslint-disable-next-line @typescript-eslint/no-explicit-any
const leavesInternal = (object: any, root: string, leaves: string[]) => {
  if (typeof object === "object" && object !== null) {
    if (Array.isArray(object)) {
      for (let i = 0; i < object.length; ++i) {
        leavesInternal(object[i], `${root}.${i}`, leaves);
      }
    } else {
      for (const key of Object.keys(object)) {
        leavesInternal(object[key], `${root ? root + "." : ""}${key}`, leaves);
      }
    }
  } else {
    leaves.push(root);
  }
};

const leaves = (object: unknown): string[] => {
  const paths: string[] = [];

  leavesInternal(object, "", paths);

  return paths;
};

export default leaves;
