/*
 * File: src/App.tsx
 * Notes:
 *   > ...
 */

import React, { useEffect, useState, VoidFunctionComponent } from "react";
import ApolloProvider from "./gql/ApolloProvider";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PageRouter from "./routers/page/Router";
import DashboardRouter from "./routers/dashboard/Router";
import { FeatureFlags, FeatureFlagProvider } from "./feature-flags";
import AppFeatureFlags from "./AppFeatureFlags";
import { SessionManager } from "./gql/session";

import "./App.css";

const defaultFeatureFlags: FeatureFlags<AppFeatureFlags> = {
  cardQRCode: {
    enabled:
      process.env.REACT_APP_ENABLE_FEATURE_QRCODE?.toLowerCase() === "true",
  },
  donateFunds: {
    enabled: process.env.REACT_APP_SHOW_DONATE?.toLowerCase() === "true",
  },
  // Injected mobile version from cordova (via widow.name) set as flag
  mobileVersion: {
    enabled: window.name === process.env.REACT_APP_MOBILE_VERSION,
  },
  mobileOnboarding: {
    enabled: process.env.REACT_APP_MOBILE_ONBOARDING?.toLowerCase() === "true",
  },
};

SessionManager.initialize();

const App: VoidFunctionComponent = () => {
  const [featureFlags, setFeatureFlags] =
    useState<FeatureFlags<AppFeatureFlags>>(defaultFeatureFlags);

  const updateFeatureFlags = (name: string) => {
    const clone = JSON.parse(
      JSON.stringify(defaultFeatureFlags)
    ) as FeatureFlags<AppFeatureFlags>;
    clone.mobileVersion.enabled = name === process.env.REACT_APP_MOBILE_VERSION;
    setFeatureFlags(clone);
  };

  useEffect(() => {
    let oldWindowName = window.name;

    const checkWindowName = () => {
      if (window.name !== oldWindowName) {
        oldWindowName = window.name;
        updateFeatureFlags(window.name);
      }
    };

    const intervalId = setInterval(checkWindowName, 1000);
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApolloProvider>
      <FeatureFlagProvider flags={featureFlags}>
        <BrowserRouter>
          <Switch>
            <Route path="/dashboard">
              <DashboardRouter />
            </Route>

            <Route path="/:path(login|activate|create-account|register|remove-contact-details)">
              <PageRouter />
            </Route>

            <Redirect to={process.env.REACT_APP_404_REDIRECT ?? "/"} />
          </Switch>
        </BrowserRouter>
      </FeatureFlagProvider>
    </ApolloProvider>
  );
};

export default App;
