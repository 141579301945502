/*
 * File: src/components/signup/forms/CreateAccount.tsx
 * Notes:
 *   > ...
 */

import React, { useState, VoidFunctionComponent } from "react";
import FormGroupPersonal from "../groups/FormGroupPersonal";
import FormGroupOnlineAccess from "../groups/FormGroupOnlineAccess";
import FormGroupCreatePIN from "../groups/FormGroupCreatePIN";
import FormGroupAddress from "../groups/FormGroupAddress";
import FormGroupContact from "../groups/FormGroupContact";
import FormGroupSecurityQuestion from "../groups/FormGroupSecurityQuestion";
import { gql, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import { Result } from "../../../gql/schema/types";
import { Asserts, object } from "yup";
import { FormikHelpers } from "formik";
import TermsOfServiceAgreement, {
  WithTermsOfServiceSchema,
} from "../groups/TermsOfServiceAgreement";
import GroupedForm from "../form/GroupedForm";
import getApolloErrorMessage from "../../../gql/getApolloErrorMessage";
import { GENERIC_ERROR } from "../../../constants/strings";
import { makeStyles } from "@material-ui/styles";
import BackArrowButton from "../../button/BackArrowButton";
import VerifyCorrectWebsite from "../../dialogs/VerifyCorrectWebsite";

const groups = [
  {
    key: "personal",
    label: "Personal",
    Component: FormGroupPersonal,
  },
  {
    key: "address",
    label: "Address",
    Component: FormGroupAddress,
    ComponentProps: {
      message: `Please provide your address information below. When you complete this
                    form, we will mail a starter kit to this address.This is also where
                    we will mail replacement or lost cards that have been returned to the
                    company.`,
    },
  },
  {
    key: "contact",
    label: "Email & Phone",
    Component: FormGroupContact,
  },
  {
    key: "online-access",
    label: "Online Access",
    Component: FormGroupOnlineAccess,
  },
  {
    key: "security-question",
    label: "Security Question",
    Component: FormGroupSecurityQuestion,
  },
  {
    key: "kiosk-pin",
    label: "Kiosk PIN",
    Component: WithTermsOfServiceSchema(FormGroupCreatePIN),
  },
];

const Mutation = gql`
  mutation ($input: CreateAccountInput!) {
    createAccount(input: $input) {
      success
      message
      ... on ResultFailed {
        fields {
          field
          errors
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
  },
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
    marginBottom: 12,
  },
}));

interface AddressInput {
  street: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
}

interface NameInput {
  first: string;
  last: string;
}

interface CreateAccountInput {
  pin: string;
  securityResponse: string;
  email: string;
  phonenumber: string;
  username: string;
  password: string;
  organization?: string;
  address: AddressInput;
  usage: string;
  name: NameInput;
  cardNumber?: string;
}

interface CreateAccountInputVariables {
  input: CreateAccountInput;
}

const schema = object({
  ...FormGroupPersonal.schema,
  ...FormGroupAddress.schema,
  ...FormGroupContact.schema,
  ...FormGroupOnlineAccess.schema,
  ...FormGroupSecurityQuestion.schema,
  ...WithTermsOfServiceSchema(FormGroupCreatePIN).schema,
});

type Values = Asserts<typeof schema>;

interface CreateAccountProps {
  card?: string;
  onCompletion: (values: Values) => Promise<void> | void;
}

const CreateAccount: VoidFunctionComponent<CreateAccountProps> = (props) => {
  const { card, onCompletion } = props;

  const [correctWebsiteShow, setCorrectWebsiteShow] = useState(true);

  const [submit] = useMutation<
    Result<"createAccount">,
    CreateAccountInputVariables
  >(Mutation);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleSubmit = async (
    values: Values,
    formikActions: FormikHelpers<Values>,
    formActions: { setStatus(value: unknown): boolean }
  ) => {
    const input: CreateAccountInput = {
      address: {
        street: values.street,
        street2: values.street2,
        city: values.city,
        state: values.state,
        zipCode: values.zipcode,
      },
      email: values.email.trim(),
      name: {
        first: values.firstName,
        last: values.lastName,
      },
      username: values.username.trim(),
      password: values.password.trim(),
      phonenumber: values.phonenumber.replace(/\D/g, ""),
      pin: values.pin,
      securityResponse: values.securityResponse,
      usage: values.usage,
      organization: values.organization,
      cardNumber: card,
    };

    try {
      const { data } = await submit({ variables: { input } });

      if (data) {
        const { success, message } = data.createAccount;

        if (success) {
          onCompletion(values);
          return;
        }

        if (message) {
          enqueueSnackbar(message, { variant: "error" });
        }

        const { fields } = data.createAccount;

        if (fields) {
          const status = Object.fromEntries(
            fields.map((f) => [f.field, f.errors])
          );

          if (!formActions.setStatus(status)) {
            enqueueSnackbar(GENERIC_ERROR, {
              variant: "error",
            });
          }
        }
      } else {
        enqueueSnackbar(GENERIC_ERROR, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(getApolloErrorMessage(error), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <BackArrowButton to="/login" />

      <div style={{ marginLeft: 32 }}>
        <Typography variant="h4" className={classes.title}>
          Create an Account
        </Typography>

        <Typography
          variant="subtitle1"
          style={{ fontWeight: "bold", marginBottom: 20 }}
          className={classes.raleway}
        >
          Please remember your credential entries to gain access to your account
          later.
        </Typography>

        <GroupedForm
          groups={groups}
          onSubmit={handleSubmit}
          LastStepComponent={TermsOfServiceAgreement}
        />
      </div>

      <VerifyCorrectWebsite
        open={correctWebsiteShow}
        onClose={() => setCorrectWebsiteShow(false)}
      />
    </>
  );
};

export default CreateAccount;
