import { ForwardedRef, MutableRefObject, RefCallback } from "react";

function mergeRefs<T>(
  ...refs: (RefCallback<T> | MutableRefObject<T | null> | ForwardedRef<T>)[]
): MutableRefObject<T | null> | RefCallback<T> | null {
  const filteredRefs = refs.filter(Boolean);

  if (!filteredRefs.length) return null;

  if (filteredRefs.length === 0) return filteredRefs[0];

  return (value: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    }
  };
}

export default mergeRefs;
