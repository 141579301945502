/*
 * File: src/components/signup/groups/FormGroupContact.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PhoneMaskedInput from "../../inputs/masks/PhoneMaskedInput";
import { createFormGroup, FormGroupProps } from "../group/FormGroup";
import {
  EmailSchema,
  ConfirmEmailSchema,
  PhoneNumberSchema,
} from "../../../validation/schema";
import FormGroupContent from "../group/FormGroupContent";
import FormikField from "../../inputs/FormikField";
import FormikTextField from "../../inputs/FormikTextField";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  raleway: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
  },
}));

const FormGroupContact: VoidFunctionComponent<FormGroupProps> = ({
  actions,
  isFirstStep,
  isLastStep,
}) => {
  const classes = useStyles();

  return (
    <FormGroupContent
      actions={actions}
      isFirstStep={isFirstStep}
      isLastStep={isLastStep}
    >
      <Grid container direction="column" spacing={1} wrap="nowrap">
        <Grid item>
          <Typography gutterBottom className={classes.raleway}>
            Please provide contact information below.
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <FormikField
                name="email"
                component={FormikTextField}
                label="Email"
                InputProps={{
                  autoFocus: true,
                  inputProps: { inputMode: "email" },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikField
                name="confirmEmail"
                component={FormikTextField}
                label="Confirm Email"
                InputProps={{
                  inputProps: { inputMode: "email" },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <FormikField
                name="phonenumber"
                component={FormikTextField}
                label="Phone Number"
                InputProps={{
                  type: "text",
                  inputProps: { inputMode: "tel" },
                  inputComponent: PhoneMaskedInput,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormGroupContent>
  );
};

export default createFormGroup(FormGroupContact, {
  email: EmailSchema,
  confirmEmail: ConfirmEmailSchema,
  phonenumber: PhoneNumberSchema,
});
