/*
 * File: src/gql/client.ts
 * Notes:
 *   > ...
 */

import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
  FieldPolicy,
} from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { onError } from "@apollo/link-error";
import { useRef } from "react";
import { Session, SessionManager } from "./session";

interface LoginInfo {
  origin: string;
  loggedIn: boolean;
}

const getLoginInfo = (): LoginInfo | undefined => {
  const serialized = localStorage.getItem("loginInfo");

  let loginInfo: LoginInfo | undefined;

  if (serialized !== null) {
    try {
      loginInfo = JSON.parse(serialized);
    } catch (error) {
      console.error(error);
    }
  }

  return loginInfo; // ?? { origin: "/login", loggedIn: false };
};

const fixedRelayStylePagination = (
  ...args: Parameters<typeof relayStylePagination>
): FieldPolicy => ({
  ...relayStylePagination(...args),
  read: (...readArgs) => {
    const existing = readArgs[0];

    const originalRead = relayStylePagination(...args).read;

    if (!existing || !originalRead) {
      return;
    }

    return originalRead(...readArgs);
  },
});

const createApolloClient = () => {
  const errorLink = onError((error) => {
    const operation = error.operation.operationName;

    const loginInfo = getLoginInfo();

    // On error get loginInfo.origin from localStorage
    if (loginInfo !== undefined) {
      const { origin } = loginInfo;

      // Set localStorage.loggedIn to false to unbind navguard on root router
      /**FOR DEMO */
      const setLoginToFalse = { origin, loggedIn: false };

      // Resend updated loginInfo
      localStorage.setItem("loginInfo", JSON.stringify(setLoginToFalse));
    }

    if (error.graphQLErrors) {
      if (
        error.graphQLErrors.find(
          (e) => e.extensions?.code === "AUTH_NOT_AUTHORIZED"
        )
      ) {
        const { origin } = getLoginInfo() ?? {
          origin: "/login",
          loggedIn: false,
        };

        const { pathname, search } = window.location;

        if (operation !== "prefetch" && origin === "/login") {
          window.alert("Your session has expired.");
        }

        // On session expiration if on /dashboard and login origin is /login redirect back to full site login
        if (pathname.match(/^\/dashboard\/?$/) && origin === "/login") {
          window.location.replace("/login");
          // if on /dashboard and login origin is dashboard/login remove card number and redirect back to mobile login
        } else if (
          pathname.match(/^\/dashboard\/?$/) &&
          origin === "/dashboard/login"
        ) {
          localStorage.removeItem("CARD_NUMBER");
          localStorage.removeItem("INSTANT_BALANCE_TOKEN");
          window.location.replace("/dashboard/login");
        } else {
          // if /dashboard has additional path (i.e. /donations) redirect back to login origin
          // and on following login redirect back to the full path (i.e /dashboard/donations)

          if (localStorage.getItem("CARD_NUMBER")) {
            // first remove user card number if true
            localStorage.removeItem("CARD_NUMBER");
            localStorage.removeItem("INSTANT_BALANCE_TOKEN");
          }

          window.location.replace(
            `${origin}?redirect=${pathname.substring(1)}${search}`
          );
        }

        return;
      }
    }

    if (operation === "prefetch") {
      window.alert("An error occured fetching your data.");

      SessionManager.instance.logout();

      const { origin } = getLoginInfo() ?? {
        origin: "/login",
        loggedIn: false,
      };

      const { pathname, search } = window.location;

      // On fetch error if on /dashboard and login origin is /login redirect back to full site login
      if (pathname.match(/^\/dashboard\/?$/) && origin === "/login") {
        window.location.replace("/login");
        // if on /dashboard and login origin is dashboard/login remove card number redirect back to mobile login
      } else if (
        pathname.match(/^\/dashboard\/?$/) &&
        origin === "/dashboard/login"
      ) {
        localStorage.removeItem("CARD_NUMBER");
        window.location.replace("/dashboard/login");
      } else {
        // if /dashboard has additional path (i.e. /donations) redirect back to login origin
        // and on following login redirect back to the full path (i.e /dashboard/donations)

        if (localStorage.getItem("CARD_NUMBER")) {
          // first remove user card number if true
          localStorage.removeItem("CARD_NUMBER");
        }

        window.location.replace(
          `${origin}?redirect=${pathname.substring(1)}${search}`
        );
      }
    }
  });

  const hasAuthorizationHeader = (init: RequestInit | undefined) => {
    const headers = init?.headers;

    if (headers !== undefined) {
      if (headers instanceof Headers) {
        return headers.get("Authorization") !== null;
      } else if (Array.isArray(headers)) {
        return headers.find(([key]) => key === "Authorization") !== undefined;
      } else {
        return headers.Authorization !== undefined;
      }
    }

    return false;
  };

  const appendAuthorizationHeader = (
    session: Session,
    init: RequestInit | undefined
  ): RequestInit => {
    let headers: HeadersInit;

    if (init === undefined) {
      headers = {};
      init = { headers };
    } else {
      if (init.headers !== undefined) {
        headers = init.headers;
      } else {
        headers = {};
        init.headers = headers;
      }
    }

    const auth = `Bearer ${session.accessToken}`;

    if (headers instanceof Headers) {
      headers.set("Authorization", auth);
    } else if (Array.isArray(headers)) {
      const header = headers.find(([key]) => key === "Authorization");

      if (header === undefined) {
        headers.push(["Authorization", auth]);
      } else {
        header[1] = auth;
      }
    } else {
      headers.Authorization = auth;
    }

    return init;
  };

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GQL_URL || "/api/gql.php",
    credentials: "include",
    fetch: async (input, init) => {
      const hasExistingAuthorizationHeader = hasAuthorizationHeader(init);

      const session = SessionManager.instance.active;

      // If we have an active session append headers
      if (session !== null && !hasExistingAuthorizationHeader) {
        init = appendAuthorizationHeader(session, init);
      }

      let response = await fetch(input, init);

      if (response.status < 400) {
        return response;
      } else if (response.status === 401) {
        if (session !== null && !hasExistingAuthorizationHeader) {
          // If the session hasn't changed during the previous fetch,
          // try to refresh the access token
          if (SessionManager.instance.active === session) {
            const refreshed = await session.refresh();

            if (refreshed) {
              // If we have an active session append headers
              if (session !== null) {
                init = appendAuthorizationHeader(session, init);
              }

              response = await fetch(input, init);

              if (response.status === 401) {
                // Still 401ing, avoid the infinite loop
                SessionManager.instance.logout();
              }
            } else {
              SessionManager.instance.logout();
            }
          }
        }
      } else {
        SessionManager.instance.logout();
      }

      return response;
    },
  });

  const link = ApolloLink.from([errorLink, httpLink]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function mergeLongerOf(existing: any, incoming: any) {
    return existing && existing.length > incoming.length ? existing : incoming;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function mergeSpread(existing: any, incoming: any) {
    return { ...existing, ...incoming };
  }

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          clynkToGiveMembers: fixedRelayStylePagination(["search"]),
          clynkToGiveMember(_, { args, toReference }) {
            /* TODO: Confirm what happens on cache-miss */
            return toReference({
              __typename: "ClynkToGiveMember",
              id: args?.id,
            });
          },
          bagDonation(_, { args, toReference }) {
            return toReference({
              __typename: "BagDonation",
              id: args?.id,
            });
          },
          bagDonationEvent(_, { args, toReference }) {
            return toReference({
              __typename: "BagDonationEvent",
              id: args?.id,
            });
          },
        },
      },
      ClynkToGiveMember: {
        fields: {
          totals: {
            merge(existing, incoming) {
              return {
                ...existing,
                ...incoming,
                raised: Math.max(existing?.raised || 0, incoming.raised || 0),
              };
            },
          },
        },
      },
      CardHolder: {
        fields: {
          info: {
            merge: mergeSpread,
          },
        },
      },
      Account: {
        fields: {
          balance: {
            merge(existing, incoming) {
              /*
              // TODO: If we want consistency, we should eventually return transactionId as well
              if (existing.transacionId > incoming.transactionId) {
                  return existing
              }
              */
              return incoming;
            },
          },
          info: {
            merge: mergeSpread,
          },
          cards: {
            merge: mergeLongerOf,
          },
          cardHolders: {
            merge: mergeLongerOf,
          },
          subAccounts: {
            merge: mergeLongerOf,
          },
          activeBagDonations: {
            merge(existing, incoming) {
              if (!existing) {
                return incoming;
              }
              return incoming;
            },
          },
          donationTotals: {
            merge(exising, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });

  const client = new ApolloClient({
    cache,
    link,
  });

  return client;
};

const useApolloClientRoot = (): ApolloClient<NormalizedCacheObject> => {
  const ref = useRef<ApolloClient<NormalizedCacheObject>>();

  if (!ref.current) {
    ref.current = createApolloClient();
  }

  return ref.current;
};

export default useApolloClientRoot;
