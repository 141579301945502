/*
 * File: src/router/dashboard/Frame.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent, useEffect } from "react";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Container, { ContainerProps } from "@material-ui/core/Container";
import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core";

const Frame: FunctionComponent<ContainerProps> = ({ children, ...rest }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <Container maxWidth="lg" {...rest}>
      <Fade in timeout={500}>
        <Grid
          container
          spacing={matches ? 5 : 2}
          style={{ overflowX: "visible" }}
        >
          {children}
        </Grid>
      </Fade>
    </Container>
  );
};

export default Frame;
