/*
 * File: src/router/page/register/Welcome.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontFamily: "Doughy-Regular !important",
    color: "#78BE43",
    marginBottom: theme.spacing(4),
  },
  text: {
    fontFamily: "Raleway-Regular !important",
    color: "#133C55 !important",
    marginBottom: theme.spacing(4),
  },
  highlight: {
    color: "#78BE43",
    fontWeight: 700,
  },
  myButton: {
    width: 200,
    height: 50,
    borderRadius: 12,
    fontSize: 16,
    textTransform: "capitalize",
    color: "#133C55 !important",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
  },
  myButtonBlue: {
    width: 200,
    height: 50,
    borderRadius: 12,
    fontSize: 16,
    textTransform: "capitalize",
    fontFamily: "Raleway-Regular !important",
    fontWeight: "bold",
    backgroundColor: "#133C55 !important",
  },
}));

const ThankYouOnlineAccess: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        Welcome to CLYNK Online Access!
      </Typography>
      <Typography className={classes.text}>
        Congrats! You now have access to your account online and through our
        mobile app.
      </Typography>

      <div style={{ display: "flex", gap: 24 }}>
        <Button
          color="primary"
          variant="contained"
          className={classes.myButton}
          onClick={() => {
            history.push("/login");
          }}
        >
          Log in
        </Button>

        <Button
          color="primary"
          variant="contained"
          className={classes.myButtonBlue}
          onClick={() => {
            window.location.href = "/how-to-clynk/get-the-app/";
          }}
        >
          Get the CLYNK App
        </Button>
      </div>
    </>
  );
};

export default ThankYouOnlineAccess;
