import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
} from "react";

export type FeatureFlags<TFlag extends string = string> = Record<
  TFlag,
  { enabled: boolean }
>;

export const FeatureFlagContext = createContext<FeatureFlags>({});

export interface FeatureFlagBag<TFlag extends string = string> {
  flags: FeatureFlags<TFlag>;
  has(name: TFlag): boolean;
  any(...name: TFlag[]): boolean;
  all(...name: TFlag[]): boolean;
}

export const useFeatureFlags = <
  TFlag extends string = string
>(): FeatureFlagBag<TFlag> => {
  const flags = useContext(FeatureFlagContext);

  const has = useCallback((name: TFlag) => flags[name]?.enabled, [flags]);

  const any = useCallback(
    (...names: TFlag[]) => {
      return names.some((name) => flags[name]?.enabled);
    },
    [flags]
  );

  const all = useCallback(
    (...names: TFlag[]) => {
      return names.every((name) => flags[name]?.enabled);
    },
    [flags]
  );

  return { flags, has, any, all };
};

export interface FeatureFlagProviderProps<TFlag extends string = string> {
  flags: FeatureFlags<TFlag>;
}

export const FeatureFlagProvider = <TFlag extends string = string>({
  flags,
  children,
}: PropsWithChildren<
  FeatureFlagProviderProps<TFlag>
>): ReturnType<FunctionComponent> => {
  return (
    <FeatureFlagContext.Provider value={flags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};
