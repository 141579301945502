/*
 * File: src/components/dialogs/Dialog.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import Grow from "@material-ui/core/Grow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiDialog, {
  DialogProps as MuiDialogProps,
} from "@material-ui/core/Dialog";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Theme } from "@material-ui/core";

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
  ) => {
    return <Grow ref={ref} {...props} />;
  }
);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface DialogProps extends MuiDialogProps {
  onClose: (
    event?: Parameters<NonNullable<MuiDialogProps["onClose"]>>[0],
    reason?: Parameters<NonNullable<MuiDialogProps["onClose"]>>[1]
  ) => void;
  showCloseButton?: boolean;
  closeButtonProps?: Omit<IconButtonProps, "children" | "onClick">;
}

const Dialog: FunctionComponent<DialogProps> = ({
  children,
  open,
  showCloseButton,
  closeButtonProps,
  onClose,
  fullScreen,
  ...rest
}) => {
  const classes = useStyles();

  const responsiveFullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <MuiDialog
      open={open}
      fullScreen={fullScreen ?? responsiveFullScreen}
      disableScrollLock
      TransitionComponent={Transition}
      onClose={onClose}
      {...rest}
    >
      {children}

      {onClose && showCloseButton && (
        <IconButton
          aria-label="close"
          size="small"
          className={classes.closeButton}
          onClick={onClose}
          {...closeButtonProps}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialog>
  );
};

export default Dialog;
