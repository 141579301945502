/*
 * File: src/index.tsx
 * Notes:
 *   > ...
 */

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ReactGA from "react-ga4";

ReactDOM.render(<App />, document.getElementById("root"));

if (process.env.REACT_APP_GA_TAG) {
  ReactGA.initialize(process.env.REACT_APP_GA_TAG);
  ReactGA.send("pageview");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
