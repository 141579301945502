/*
 * File: src/state/dashboard/DashboardContext.ts
 * Notes:
 *   > ...
 */

import { createContext } from "react";
import { DashboardAction, DashboardState } from "./DashboardState";

export type DashboardContextBag = [
  DashboardState,
  React.Dispatch<DashboardAction>
];

export const DashboardContext = createContext<DashboardContextBag>([
  {
    c2gDrawerTarget: null,
    cancelBagDonationId: null,
    joinBagDonationEventId: null,
    donate: { clynkToGiveMemberId: null, openId: 0 },
  },
  () => undefined,
]);
