/*
 * File: src/components/utils/Skeleton.tsx
 * Notes:
 *   > ...
 */

import React, { FunctionComponent } from "react";
import MuiSkeleton, { SkeletonProps } from "@material-ui/lab/Skeleton";

const Skeleton: FunctionComponent<SkeletonProps> = (props) => {
  return <MuiSkeleton animation="wave" {...props} />;
};

export default Skeleton;
