import { useCallback, useMemo, useRef, useState } from "react";
import { object, reach } from "yup";
import getPropertyAt from "../../../common/getPropertyAt";
import useForm from "../../form/useForm";
import FormGroupSpec from "../group/FormGroupSpec";
import FormActions from "./FormActions";
import paths from "../../../common/paths";
import { FormikHelpers, FormikProps } from "formik";
import leaves from "../../../common/leaves";

interface FormState {
  step: number;
  maxStep: number;
}

export interface UseGroupedFormOptions<
  TForm = unknown,
  TGroups extends FormGroupSpec[] = FormGroupSpec[]
> {
  groups: TGroups;
  onSubmit: (
    values: TForm,
    formikActions: FormikHelpers<TForm>,
    formActions: FormActions
  ) => void;
}

export interface UseGroupedForm<TForm = unknown> {
  formik: FormikProps<TForm>;
  meta: {
    step: number;
  };
  actions: FormActions;
}

function useGroupedForm<
  TForm = unknown,
  TGroups extends FormGroupSpec[] = FormGroupSpec[]
>({
  groups,
  onSubmit,
}: UseGroupedFormOptions<TForm, TGroups>): UseGroupedForm<TForm> {
  const [state, setState] = useState<FormState>({
    step: 0,
    maxStep: 0,
  });

  const schema = useMemo(() => {
    return object({
      ...Object.assign({}, ...groups.map((g) => g.Component.schema)),
    });
  }, [groups]);

  const schemas = useMemo(() => {
    return groups.map((g) => object(g.Component.schema));
  }, [groups]);

  const actions = useRef<FormActions>();

  const onFormikSubmit = useCallback(
    (values: TForm, helpers: FormikHelpers<TForm>): Promise<unknown> | void => {
      // Hacky workyaround for circular dependency
      if (actions.current) {
        return onSubmit(values, helpers, actions.current);
      }
    },
    [onSubmit]
  );

  const formik = useForm<TForm>({
    onSubmit: onFormikSubmit,
    validationSchema: schema,
    initialValues: schema.getDefault(),
  });

  const {
    values,
    setStatus: setFormikStatus,
    submitForm,
    validateForm,
    setFieldTouched,
  } = formik;

  const goto = useCallback(
    (step: number) => {
      setState((s) => {
        if (step > s.maxStep) {
          return s;
        }
        return { ...s, step: step };
      });
    },
    [setState]
  );

  const back = useCallback(() => {
    setState((s) => {
      return s.step === 0 ? s : { ...s, step: s.step - 1 };
    });
  }, [setState]);

  const { step } = state;

  const next = useCallback(async () => {
    if (step >= schemas.length) {
      return;
    }

    const valuesLeaves = leaves(values);

    const relevantPaths: string[] = [];

    for (const path of valuesLeaves) {
      try {
        reach(schemas[step], path);
      } catch {
        continue;
      }

      setFieldTouched(path, true, false);

      relevantPaths.push(path);
    }

    const errors = await validateForm();

    // If there are errors in the current FormGroup, bail
    for (const path of relevantPaths) {
      if (getPropertyAt(errors, path)) {
        return;
      }
    }

    // Otherwise, proceed to the next step
    setState((s) => ({
      ...s,
      step: s.step + 1,
      maxStep: Math.max(s.step + 1, s.maxStep),
    }));
  }, [step, setState, schemas, validateForm, values, setFieldTouched]);

  const setStatus = useCallback(
    (status: unknown, jumpToFirstError = true): boolean => {
      setFormikStatus(status);

      let index = Number.MAX_SAFE_INTEGER;

      for (const path of paths(status)) {
        for (let i = 0; i < schemas.length; ++i) {
          try {
            reach(schemas[i], path);
          } catch {
            continue;
          }

          if (i === 0) {
            break;
          }

          index = Math.min(index, i);
        }
      }

      // If no errors, return false
      if (index === Number.MAX_SAFE_INTEGER) {
        return false;
      }

      if (jumpToFirstError) {
        setState((s) => {
          if (index >= s.maxStep) {
            return s;
          }
          return { ...s, step: index };
        });
      }

      return true;
    },
    [setState, schemas, setFormikStatus]
  );

  const submit = useCallback((): Promise<unknown> | void => {
    if (step < groups.length - 1) {
      next();
    } else {
      return submitForm();
    }
  }, [submitForm, next, step, groups]);

  actions.current = { goto, back, next, submit, setStatus };

  return {
    formik,
    meta: { step },
    actions: actions.current,
  };
}

export default useGroupedForm;
