/*
 * File: src/components/dialogs/AccountAlreadyExists.tsx
 * Notes:
 *   > ...
 */

import React, { VoidFunctionComponent } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog, { DialogProps } from "./Dialog";
import useTextHighlightStyles from "../../styles/useTextHighlightStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CUSTOMER_SERVICE_NUMBER } from "../../constants/strings";

const useStyles = makeStyles(() => ({
  content: {
    paddingTop: 16,
    paddingBottom: 16,
  },
}));

interface AccountAlreadyExistsProps extends Omit<DialogProps, "children"> {
  onContinue: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AccountAlreadyExists: VoidFunctionComponent<
  AccountAlreadyExistsProps
> = ({ onContinue, onClose, ...rest }) => {
  const classes = useStyles();
  const textClasses = useTextHighlightStyles();

  return (
    <Dialog onClose={onClose} {...rest}>
      <DialogTitle>CLYNK Account already exists</DialogTitle>

      <DialogContent dividers>
        <div className={classes.content}>
          <Typography>
            The card you entered is already associated with a{" "}
            <span className={textClasses.textHighlightGreen}>
              CLYNK Account
            </span>
            . If are you looking to set up{" "}
            <span className={textClasses.textHighlightBlue}>
              CLYNK Online Access
            </span>
            , click continue below to get started.
            <br />
            <br />
            Need help with your account? Call CLYNK Customer Service at{" "}
            {CUSTOMER_SERVICE_NUMBER}.
          </Typography>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>No Thanks</Button>
        <Button variant="contained" color="primary" onClick={onContinue}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountAlreadyExists;
